import React from 'react'
import PropTypes from 'prop-types'
import {
    Typography,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material'

import useStyles from './Dialog.style'

const propTypes = {
    titleMessage: PropTypes.string.isRequired,
    buttons: PropTypes.node,
    children: PropTypes.node.isRequired,
}

const defaultProps = { buttons: undefined }

const Dialog = ({
    children,
    titleMessage,
    buttons,
    ...rest
}) => {
    const classes = useStyles(rest)

    return (
        <>
            <DialogTitle
                classes={{ root: classes.dialogTitle }}
            >
                <Typography
                    variant="h3"
                    className={classes.titleHeader}
                    component="div"
                >
                    {titleMessage}
                </Typography>
            </DialogTitle>
            <DialogContent classes={{ root: classes.dialogContentRoot }}>
                {children}
            </DialogContent>
            <DialogActions
                classes={{ root: classes.dialogActionsRoot }}
            >
                {buttons}
            </DialogActions>
        </>
    )
}

Dialog.propTypes = propTypes
Dialog.defaultProps = defaultProps

export default Dialog
