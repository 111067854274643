import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'

import PictureLayout from 'shared/PictureLayout/PictureLayout'
import useStyles from './AcceptPicture.style'

const propTypes = {
    image: PropTypes.string.isRequired,
    onAccept: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired,
    isRequired: PropTypes.bool.isRequired,
    isNested: PropTypes.bool,
}
const defaultProps = { isNested: false }

const AcceptPicture = ({
    image,
    onAccept,
    onReject,
    isRequired,
    isNested,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()

    return (
        <PictureLayout
            title={t('PICTURE_OK')}
            image={image}
            isRequired={isRequired}
            isAnswered={false}
            isNested={isNested}
        >
            <>
                <Button
                    data-testid="no-button"
                    className={classes.button}
                    onClick={onReject}
                >
                    {t('NO')}
                </Button>
                <Button
                    data-testid="yes-button"
                    className={classes.button}
                    onClick={onAccept}
                >
                    {t('YES')}
                </Button>
            </>
        </PictureLayout>
    )
}

AcceptPicture.propTypes = propTypes
AcceptPicture.defaultProps = defaultProps

export default AcceptPicture
