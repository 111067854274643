import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import common from 'shared/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        form: {
            ...theme.custom.stepElementPadding,
            ...common.flexJustityAlignCenter,
        },
        actions: common.flexJustityAlignCenter,
        button: common.stepsPictureButton(theme),
    }
})

export default useStyles
