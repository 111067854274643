import React, {
    useCallback, useState,
} from 'react'
import PropTypes from 'prop-types'

import ScanOrManualButtons from 'shared/Scans'
import useScannerModeSwitch from 'shared/hooks/useScannerModeSwitch'
import QRScanner from 'shared/QRScanner'
import { createInputObject } from 'shared/utils/stepsUtils'
import checkStatus from 'shared/utils/checkStatus'
import ManualInput from 'shared/ManualInput'

import IATAScanResultMessage from '../IATAScanResultMessage'

const propTypes = {
    manualInput: PropTypes.bool.isRequired,
    scanResult: PropTypes.string.isRequired,
    setManualInput: PropTypes.func.isRequired,
    setScanResult: PropTypes.func.isRequired,
    containerTempRange: PropTypes.string.isRequired,
    inputCallback: PropTypes.func.isRequired,
    stepName: PropTypes.string.isRequired,
}

const IATAScanInput = ({
    manualInput,
    scanResult,
    setManualInput,
    setScanResult,
    containerTempRange,
    inputCallback,
    stepName,
}) => {
    const {
        scanMode,
        startScan,
        cancelScan,
    } = useScannerModeSwitch()

    const startManual = useCallback(() => {
        setManualInput(true)
    }, [setManualInput])

    const onManualCancel = useCallback(() => {
        setManualInput(false)
    }, [setManualInput])

    const [
        resultStatus,
        setResultStatus,
    ] = useState('')

    const handleCheckTempRange = useCallback((tempRange) => {
        if (tempRange?.toUpperCase() === containerTempRange) {
            const input = createInputObject({ tempRange })

            inputCallback(input, stepName)

            setResultStatus(checkStatus.SUCCESS)
        } else {
            setResultStatus(checkStatus.FAILURE)
        }
    }, [
        containerTempRange,
        inputCallback,
        stepName,
    ])

    const handleChange = useCallback((value) => {
        setScanResult(value)
    }, [setScanResult])

    const onScan = useCallback((value) => {
        const formatedValue = value?.toUpperCase()

        handleCheckTempRange(formatedValue)
        handleChange(formatedValue)
        cancelScan(false)
    }, [
        handleCheckTempRange,
        handleChange,
        cancelScan,
    ])

    const handleSubmitManualEntering = useCallback((result) => {
        const value = result?.toUpperCase()

        handleCheckTempRange(value)
        handleChange(value)
        onManualCancel()
    }, [
        handleCheckTempRange,
        handleChange,
        onManualCancel,
    ])

    const onRetry = useCallback(() => {
        handleChange('')
        setResultStatus('')
    }, [
        handleChange,
        setResultStatus,
    ])

    return (
        <>

            {
                manualInput && (
                    <ManualInput
                        onSubmit={handleSubmitManualEntering}
                        onCancel={onManualCancel}
                        showCancel
                        showSubmit
                    />
                )
            }
            {
                !scanResult && !manualInput && (
                    <ScanOrManualButtons
                        onScan={startScan}
                        onManual={startManual}
                    />
                )
            }
            {
                scanMode && (
                    <QRScanner
                        scanMode
                        onScan={onScan}
                        onCancel={cancelScan}
                    />
                )
            }
            {
                resultStatus && (
                    <IATAScanResultMessage
                        resultStatus={resultStatus}
                        scanResult={scanResult}
                        containerTempRange={containerTempRange}
                        onRetry={onRetry}
                    />
                )
            }
        </>
    )
}

IATAScanInput.propTypes = propTypes

export default IATAScanInput
