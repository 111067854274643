import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'

import useStyles from './IATAScanSummary.style'

const propTypes = {
    value: PropTypes.string,
    isAnswered: PropTypes.bool,
    onRetry: PropTypes.func,
}

const defaultProps = {
    value: '',
    isAnswered: false,
    onRetry: undefined,
}

const IATAScanSummary = ({
    isAnswered,
    value,
    onRetry,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()

    return (
        <div className={classes.root}>
            { !isAnswered && t('NO_SCAN_PERFORMED') }
            { isAnswered && t('CORRECT_STICKER', { tempRange: value }) }
            { onRetry && (
                <div className={classes.actions}>
                    <Button
                        data-testid="retry-button"
                        className={classes.button}
                        onClick={onRetry}
                    >
                        {t('RETRY')}
                    </Button>
                </div>
            )}
        </div>
    )
}

IATAScanSummary.propTypes = propTypes
IATAScanSummary.defaultProps = defaultProps

export default IATAScanSummary
