import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import useStyles from './DashboardCardItem.style'

const propTypes = {
    titleKey: PropTypes.string.isRequired,
    infoKey: PropTypes.string,
    iconSrc: PropTypes.string.isRequired,
    linkTo: PropTypes.string,
    disabled: PropTypes.bool,
    children: PropTypes.element,
}

const defaultProps = {
    children: undefined,
    linkTo: undefined,
    infoKey: undefined,
    disabled: false,
}

const DashboardCardItem = ({
    titleKey,
    infoKey,
    iconSrc,
    linkTo,
    disabled,
    children,
    ...rest
}) => {
    const classes = useStyles({
        ...rest,
        active: !!linkTo,
        disabled,
    })
    const history = useHistory()
    const { t } = useTranslation()

    const navigateToLink = useCallback(() => {
        if (linkTo) {
            history.push(linkTo)
        }
    }, [
        linkTo,
        history,
    ])

    return (
        <div
            data-testid="dashboard-card"
            className={classes.container}
            onClick={navigateToLink}
            aria-hidden="true"
        >
            <div className={classes.topWrapper}>
                <img
                    className={classes.icon}
                    src={iconSrc}
                    alt={titleKey}
                />
            </div>
            <div className={classes.bottomWrapper}>
                <Typography
                    variant="h3"
                >
                    {t(titleKey)}
                </Typography>
                {(infoKey && (
                    <div className={classes.info}>
                        <Typography
                            variant="h5"
                        >
                            {t(infoKey)}
                        </Typography>
                        {children}
                    </div>
                ))}
            </div>
        </div>
    )
}

DashboardCardItem.propTypes = propTypes
DashboardCardItem.defaultProps = defaultProps

export default DashboardCardItem
