/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'

import MultipleChoice from '../MultipleChoice/MultipleChoice'
import NoDamage from './NoDamage'

const propTypes = { step: PropTypes.shape({ choices: PropTypes.arrayOf(PropTypes.shape({
    choiceIdentifier: PropTypes.string.isRequired,
    choiceLabel: PropTypes.string.isRequired,
    goToStep: PropTypes.string,
    jumpToSummary: PropTypes.bool,
})).isRequired }).isRequired }

const MultipleChoiceDamage = (props) => {
    const { step } = props

    return (
        <>
            {(step.choices.length > 0) && (<MultipleChoice {...props} />)}
            {(step.choices.length === 0) && (<NoDamage {...props} />)}
        </>
    )
}

MultipleChoiceDamage.propTypes = propTypes

export default MultipleChoiceDamage
