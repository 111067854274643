import {
    useEffect,
    useState,
    useRef,
    useCallback,
} from 'react'

import useBeforeUnload from 'shared/hooks/useBeforeUnload'

import useEnumerateDevices from './useEnumerateDevices'
import isMediaDevicesAvailable from './isMediaDevicesAvailable'
import makeStreamTracksStoppable from './makeStreamTracksStoppable'

const useMediaDevices = () => {
    const [
        state,
        setState,
    ] = useState({
        error: null,
        cameraStreamReceived: false,
    })
    const device = useEnumerateDevices()
    const promiseRef = useRef()
    const streamStatusRef = useRef({
        requested: false,
        stream: null,
    })

    const cleanUpHandler = useCallback((event) => {
        if (streamStatusRef.current.requested) {
            if (streamStatusRef.current.stream) {
                streamStatusRef.current.stream.getTracks().forEach((track) => {
                    track.stop()
                })
            } else {
                event.preventDefault()
                event.returnValue = '' // eslint-disable-line no-param-reassign
            }
        }
    }, [])

    useBeforeUnload(cleanUpHandler)

    useEffect(() => {
        return () => {
            // it will stop the stream when promise would be resolved
            if (promiseRef.current) {
                promiseRef.current.stopTracks()
                promiseRef.current = null
            }
            if (streamStatusRef.current.stream) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                streamStatusRef.current.stream.getTracks().forEach((track) => {
                    track.stop()
                })
            }
        }
    }, [])

    /* we need to stop started Video Stream even if component is un-mounted,
    or we would not be able to open more video Streams at some point */
    const streamPromiseStoppableHandler = useCallback((requestStreamPromise) => {
        streamStatusRef.current.requested = true
        const cPromise = makeStreamTracksStoppable(requestStreamPromise)

        promiseRef.current = cPromise
        return cPromise.promise.then((value) => {
            streamStatusRef.current.stream = value
            return value
        })
    }, [])

    useEffect(() => {
        if (isMediaDevicesAvailable()) {
            const constraints = { video: device }

            if (device) {
                streamPromiseStoppableHandler(navigator.mediaDevices.getUserMedia(constraints))
                    .then(() => {
                        setState({
                            error: null,
                            cameraStreamReceived: true,
                        })
                    })
                    .catch((err) => {
                        setState((prevState) => {
                            return {
                                ...prevState,
                                error: err,
                            }
                        })
                    })
            } else if (device === null) {
                setState((prevState) => {
                    return {
                        ...prevState,
                        error: new Error('No video device found'),
                    }
                })
            }
        }
    }, [
        device,
        streamPromiseStoppableHandler,
    ])

    return state
}

export default useMediaDevices
