export const actions = {
    SCAN_SECCESSFUL: 'SCAN_SECCESSFUL',
    GET_AVAL_PROCESS: 'GET_AVAL_PROCESS',
    SHOW_PROCESS_SELECTION: 'SHOW_PROCESS_SELECTION',
}
export const initState = {
    scanDialogOpen: true,
    assetNumber: null,
    needGetAvalProcess: false,
    showProcessSelection: false,
}

const reducer = (state, action) => {
    switch (action.type) {
    case actions.SCAN_SECCESSFUL:
        return {
            ...state,
            scanDialogOpen: false,
            assetNumber: action.assetNumber,
        }
    case actions.GET_AVAL_PROCESS:
        return {
            ...state,
            needGetAvalProcess: true,
        }
    case actions.SHOW_PROCESS_SELECTION:
        return {
            ...state,
            showProcessSelection: true,
        }
    default:
        throw new Error(`Not handled action type for DirectScan component = ${action.type}`)
    }
}

export default reducer
