import React from 'react'
import PropTypes from 'prop-types'
import InputContext from './InputContext'

const propTypes = {
    children: PropTypes.element.isRequired,
    callback: PropTypes.func,
}
const defaultProps = { callback: undefined }

const InputContextProvider = ({
    callback,
    children,
}) => {
    return (
        <InputContext.Provider
            value={{ callback }}
        >
            {children}
        </InputContext.Provider>
    )
}

InputContextProvider.propTypes = propTypes
InputContextProvider.defaultProps = defaultProps

export default InputContextProvider
