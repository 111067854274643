import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import common from 'shared/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        main: {
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            padding: 50,
            color: theme.palette.secondary[600],

        },
        info: {
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',

        },
        context: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            paddingTop: 20,
            paddingBottom: 20,
        },
        text: {
            fontSize: theme.typography.h3.fontSize,
            lineHeight: theme.typography.h3.lineHeight,
            color: theme.palette.secondary[600],
        },
        tempValue: {
            fontWeight: 900,
            color: theme.palette.secondary[600],
        },
        actions: { ...common.flexJustityAlignCenter },
        button: {
            ...theme.custom.button,
            margin: '60px 25px 15px 25px',
        },
    }
})

export default useStyles
