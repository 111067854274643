import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import common from 'shared/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        text: {
            paddingLeft: 30,
            height: '100%',
            width: '100%',
        },
        buttons: {
            ...common.flexJustityAlignCenter,
            paddingTop: 30,
        },
        button: common.stepsPictureButton(theme),
    }
})

export default useStyles
