import React from 'react'
import PropTypes from 'prop-types'

import Header from './Header'

import useStyles from './Layout.style'

const propTypes = {
    children: PropTypes.node.isRequired,
    onClickMainMenu: PropTypes.func,
}

const defaultProps = { onClickMainMenu: undefined }

const Layout = ({
    children,
    onClickMainMenu,
}) => {
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <Header onClickMainMenu={onClickMainMenu} />
            <div className={classes.content}>
                {children}
            </div>
        </div>
    )
}

Layout.propTypes = propTypes
Layout.defaultProps = defaultProps

export default Layout
