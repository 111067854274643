/* eslint-disable react/self-closing-comp */
import React from 'react'
import Auth from 'App/Auth'
import { initVariables } from '@skycell-ag/shared-components'
import {
    QueryClient,
    QueryClientProvider,
} from 'react-query'

import I18nLocaleProvider from '../shared/I18nLocaleProvider'

import {
    StyledErrorBoundary,
    ErrorBoundary,
} from './ErrorBoundaries'
import Root from './Root'
import authRoles from './authRoles'
import StylesProvider from './StylesProvider'
import AccessProvider from './AccessProvider'

import './App.css'

const allowedRoles = [
    authRoles.ADMIN,
    authRoles.USER,
]

const queryClient = new QueryClient({ defaultOptions: { queries: {
    staleTime: 10000,
    cacheTime: 10000,
} } })

initVariables({ REACT_APP_SKYMIND_API: process.env.REACT_APP_SKYMIND_API })

function App() {
    return (
        <ErrorBoundary>
            <Auth>
                <StylesProvider>
                    <I18nLocaleProvider>
                        <StyledErrorBoundary>
                            <QueryClientProvider client={queryClient}>
                                <AccessProvider allowedRoles={allowedRoles}>
                                    <Root />
                                </AccessProvider>
                            </QueryClientProvider>
                        </StyledErrorBoundary>
                    </I18nLocaleProvider>
                </StylesProvider>
            </Auth>
        </ErrorBoundary>
    )
}

export default App
