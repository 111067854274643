const skyCenterPrimaryPalette = {
    white: '#FFFFFF',
    h5: '#747474',
    h3: '#191919',
    unAnsweredMandatory: '#F0DEBF',
}

const boxShadow = '0px 1px 3px #00000033'
const stepTextPadding = { padding: 20 }
const stepElementPadding = { padding: 10 }
const messageText = {
    color: '#00000099',
    letterSpacing: 1.11,
}
const stepBorderBackgroud = '#61C6E92E'

const theme = (skyTheme) => {
    return {
        typography: {
            h1: {
                fontSize: 36,
                fontWeight: 200,
                lineHeight: '43px',
                color: skyCenterPrimaryPalette.white,
            },
            h3: {
                fontSize: 24,
                fontWeight: 400,
                letterSpacing: 0.3,
                lineHeight: '29px',
                color: skyCenterPrimaryPalette.h3,
            },
            h5: {
                fontSize: 20,
                fontWeight: 400,
                lineHeight: '24px',
                letterSpacing: 0.62,
                color: skyCenterPrimaryPalette.h5,
            },
        },
        custom: {
            border: { border: `1px solid ${skyTheme.palette.secondary[50]}` },
            boxShadow: { boxShadow },
            boxShadowRadius: {
                borderRadius: skyTheme.shape.borderRadius,
                boxShadow,
            },
            messageText,
            dialogTitle: {
                background: stepBorderBackgroud,
                borderRadius: 10,
                color: skyTheme.palette.secondary[600],
            },
            stepBorder: {
                borderStyle: 'solid',
                borderColor: stepBorderBackgroud,
                borderWidth: 10,
            },
            button: {
                height: 59,
                width: 153,
                whiteSpace: 'nowrap',
                backgroundColor: skyTheme.palette.primary[200],
                color: skyTheme.palette.common.white,
                letterSpacing: 2.14,
                font: `normal normal 400 ${skyTheme.typography.h3.fontSize}px/${skyTheme.typography.h3.lineHeight} Roboto`,
                borderRadius: skyTheme.shape.borderRadius,
                boxShadow,
                '&:disabled': {
                    backgroundColor: skyTheme.palette.primary[200],
                    color: 'rgba(0, 0, 0, 0.26)',
                },
                '&:disabled:hover': {
                    backgroundColor: skyTheme.palette.primary[200],
                    color: 'rgba(0, 0, 0, 0.26)',
                },
                '&:hover': {
                    backgroundColor: skyTheme.palette.primary[200],
                    color: skyTheme.palette.common.white,
                },
            },
            stepTextPadding,
            stepElementPadding,
            stepText: {
                ...messageText,
                ...stepTextPadding,
            },
            dashBoardRoot: { padding: '30px 90px' },
            mandatoryStepText: { background: skyCenterPrimaryPalette.unAnsweredMandatory },
            mandatoryStepBorder: { borderColor: skyCenterPrimaryPalette.unAnsweredMandatory },
            optionalStepText: { background: skyTheme.palette.secondary[200] },
            optionalStepBorder: { borderColor: skyTheme.palette.secondary[200] },
        },
    }
}

export default theme
