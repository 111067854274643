import React from 'react'
import PropTypes from 'prop-types'
import {
    Typography,
    Button,
    DialogContent,
    DialogActions,
    Dialog,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import getGlobalAssetUrl from 'shared/utils/getGlobalAssetUrl'

import useStyles from './ModalDialog.style'

const propTypes = {
    onOk: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
}

const ModalDialog = ({
    open,
    onOk,
    message,
    icon,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <Dialog
            id="modal-message-dialog"
            keepMounted
            fullWidth
            maxWidth="sm"
            aria-labelledby="modal-message-dialog"
            open={open}
            classes={{ root: classes.dialogRoot }}
        >
            <DialogContent classes={{ root: classes.dialogContentRoot }}>
                <img
                    alt="logo"
                    src={getGlobalAssetUrl(icon)}
                    className={classes.icon}
                />
                <Typography
                    variant="h3"
                >
                    {message}
                </Typography>
            </DialogContent>
            <DialogActions
                classes={{ root: classes.dialogActionsRoot }}
            >
                <Button
                    data-testid="ok-button"
                    className={classes.button}
                    onClick={onOk}
                >
                    {t('OK')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ModalDialog.propTypes = propTypes

export default ModalDialog
