import { requestBusinessObjects } from '@skycell-ag/shared-components'
import { useJWTToken } from 'App/Auth'
import { useQuery } from 'react-query'

const getUserLocations = ({ queryKey: [
    _key, // eslint-disable-line no-unused-vars
    { token },
] }) => {
    return requestBusinessObjects({
        method: 'GET',
        url: 'contact/self',
        token,
    })
        .then((data) => {
            return data.data.location
        })
}

function useUserLocations() {
    const token = useJWTToken()

    return useQuery({
        queryKey: [
            'getUserLocation',
            { token },
        ],
        queryFn: getUserLocations,
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 1000,
    })
}

export default useUserLocations
