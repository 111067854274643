import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'

import getGlobalAssetUrl from 'shared/utils/getGlobalAssetUrl'
import useStyles from './TempContainerIcon.style'

const propTypes = { tempRange: PropTypes.string }
const defaultProps = { tempRange: undefined }

const TempContainerIcon = ({ tempRange }) => {
    const classes = useStyles()
    const tempIcon = `temperature_${tempRange}`

    return (
        <div className={classes.tempContainer}>
            <div
                className={classes.icon}
            >
                {tempRange ? (
                    <img
                        alt="logo"
                        className={classes.tempImg}
                        src={getGlobalAssetUrl(`icons/${tempIcon}.svg`)}
                    />
                ) : null}
            </div>
            <Typography
                variant="h3"
                className={classes.numberLineLetters}
            >
                {tempRange}
            </Typography>
        </div>
    )
}

TempContainerIcon.propTypes = propTypes
TempContainerIcon.defaultProps = defaultProps

export default TempContainerIcon
