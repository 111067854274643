import { useMutation } from 'react-query'
import { useJWTToken } from 'App/Auth'
import { requestBusinessObjects } from '@skycell-ag/shared-components'

export const confirmShipment = (token, orderNumber) => {
    return requestBusinessObjects({
        method: 'PATCH',
        url: `order/${orderNumber}/shipment-confirmation`,
        data: { shipmentConfirmationStatus: 'CONFIRMED' },
        token,
    })
        .then((result) => {
            return result.data
        })
}

function useConfirmShipment() {
    const token = useJWTToken()

    return useMutation(({ orderNumber }) => {
        return confirmShipment(token, orderNumber)
    })
}

export default useConfirmShipment
