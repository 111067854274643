import React, {
    useRef,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { PropTypes } from 'prop-types'
import {
    IconButton, Button,
} from '@mui/material'
import { useEnumerateDevices } from '@skycell-ag/shared-components'
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid'

import { BarcodeScanner } from 'dynamsoft-javascript-barcode'

import getGlobalAssetUrl from 'shared/utils/getGlobalAssetUrl'

import useDynamicSoftScanner from '../PreLoadScanner/useDynamicSoftScanner'
import useStyles from './DynamicSoftScanner.style'

export const SCAN_AREA_PADDING = 200

const propTypes = {
    onScan: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    options: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
        aspect: PropTypes.number,
    }),
}

const defaultProps = {
    onCancel: undefined,
    options: undefined,
}

const DynamicSoftScanner = ({
    onScan,
    onCancel,
    options,
}) => {
    const classes = useStyles()
    const { pScanner } = useDynamicSoftScanner()
    const elRef = useRef()
    const device = useEnumerateDevices()
    const hasMultipleCamera = useMemo(() => {
        return !!device.facingMode
    }, [device])

    const [
        envError,
        setEnvError,
    ] = useState(null)

    const [
        loaded,
        setLoaded,
    ] = useState(false)

    const runTimeStyles = useMemo(() => {
        const windowWidth = options ? options.width : window.innerWidth - (SCAN_AREA_PADDING + 10)
        const windowHeight = options ? options.width / options.aspect : 300

        return {
            camera: {
                width: `${windowWidth}px`,
                height: '100%',
                minWidth: `${windowWidth}px`,
                minHeight: `${windowHeight}px`,
                position: 'relative',
            },
            scanlight: {
                display: 'block',
                width: '100%',
                height: '1%',
                position: 'absolute',
                animation: '3s infinite dce-scanlight',
                borderRadius: '90%',
                boxShadow: '0px 0px 2vw 1px #00e5ff',
                background: '#fff',
                userSelect: 'none',
            },
            scanarea: {
                width: '100%',
                height: '100%',
                position: 'absolute',
                left: '0 !important',
                top: 0,
            },
        }
    }, [options])

    useEffect(() => {
        const scanRefForCleanUp = pScanner.current
        const prepareScanner = async () => {
            const env = await BarcodeScanner.detectEnvironment()

            if (!env.camera) {
                setEnvError(new Error('Camera Device not found'))
            } else {
                await pScanner.current.setUIElement(elRef.current)
                pScanner.current.onUniqueRead = (txt) => {
                    onScan(txt)
                }
                await pScanner.current.open()
                setLoaded(true)
            }
        }

        prepareScanner().catch((error) => {
            console.log('Error during DynamicSoftScanner.detectEnvironment:', error) // eslint-disable-line no-console
        })

        return () => {
            if (scanRefForCleanUp) {
                const destroScanner = async () => {
                    await scanRefForCleanUp.close()
                }

                destroScanner().catch((error) => {
                    console.log('Error during DynamicSoftScanner unmount', error) // eslint-disable-line no-console
                })
            }
        }
    }, [
        onScan,
        pScanner,
    ])

    const onCancelLocal = useCallback(() => {
        onCancel()
    }, [onCancel])

    const onFlipCamera = useCallback(async () => {
        const cameras = await pScanner.current.getAllCameras()
        const currentCamera = await pScanner.current.getCurrentCamera()

        if (cameras.length > 1) {
            const newCamera = cameras.find((c) => { return c.label !== currentCamera.label })

            await pScanner.current.setCurrentCamera(newCamera)
        }
    }, [pScanner])

    if (envError) {
        throw envError
    }

    return (
        <>
            <div
                id="div-ui-container"
                ref={elRef}
                className={classes.reader}
                style={runTimeStyles.camera}
            >
                <div
                    id="dce-video-container"
                    className="dce-video-container"
                    style={runTimeStyles.scanarea}
                />
                <div
                    className="dce-scanarea"
                    style={runTimeStyles.scanarea}
                >
                    <div
                        className="dce-scanlight"
                        hidden
                        style={runTimeStyles.scanlight}
                    />
                </div>
            </div>
            <div className={classes.actions}>
                { hasMultipleCamera && (
                    <IconButton
                        disabled={!loaded}
                        data-testid="capture-flip-button"
                        color="primary"
                        size="medium"
                        aria-label="upload picture"
                        onClick={onFlipCamera}
                        className={classes.button}
                    >
                        <FlipCameraAndroidIcon fontSize="large" />
                    </IconButton>
                )}
                { onCancel && (
                    <Button
                        data-testid="cancel-button"
                        onClick={onCancelLocal}
                        className={classes.button}
                    >
                        <img
                            className={classes.icon}
                            alt="logo"
                            src={getGlobalAssetUrl('skycenter/arrow_back_black_24dp.svg')}
                        />
                    </Button>
                )}
            </div>
        </>
    )
}

DynamicSoftScanner.propTypes = propTypes
DynamicSoftScanner.defaultProps = defaultProps

export default DynamicSoftScanner
