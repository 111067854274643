import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import { DashboardCardItem } from 'shared/Dashboard'
import useStyles from './DashboardCardCountedItem.style'

const propTypes = {
    count: PropTypes.number,
    titleKey: PropTypes.string.isRequired,
    infoKey: PropTypes.string,
    iconSrc: PropTypes.string.isRequired,
    linkTo: PropTypes.string.isRequired,
}

const defaultProps = {
    count: 0,
    infoKey: 'OPEN_PROCESSES',
}

const DashboardCardCountedItem = ({
    titleKey,
    infoKey,
    linkTo,
    count,
    iconSrc,
}) => {
    const classes = useStyles()

    return (
        <DashboardCardItem
            titleKey={titleKey}
            infoKey={count > 0 ? infoKey : 'NO_OPEN_PROCESSES'}
            linkTo={count > 0 ? linkTo : undefined}
            iconSrc={iconSrc}
            classes={{ info: classes.countableInfo }}
        >
            { count > 0 ? (
                <Typography
                    variant="h3"
                    className={classes.countIndicator}
                >
                    {count}
                </Typography>
            ) : undefined}
        </DashboardCardItem>
    )
}

DashboardCardCountedItem.propTypes = propTypes
DashboardCardCountedItem.defaultProps = defaultProps

export default DashboardCardCountedItem
