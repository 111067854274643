import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import TwoColumnErrorPage from 'shared/ErrorPages/TwoColumnErrorPage'

const propTypes = {
    qrCode: PropTypes.string,
    onRetry: PropTypes.func.isRequired,
}

const defaultProps = { qrCode: null }

const SavyInitializationInputError = ({
    qrCode,
    onRetry,
}) => {
    const { t } = useTranslation()

    const texts = useMemo(() => {
        return [{
            label: `${t('QR_CODE_SCANNED')}: `,
            value: qrCode,
        }]
    }, [
        qrCode,
        t,
    ])

    return (
        <TwoColumnErrorPage
            iconPath="skycenter/not_found.svg"
            iconAlt="error"
            buttonLabel={t('RETRY')}
            onButtonClick={onRetry}
            buttonDataTestId="retry-button"
            messageTop={t('WRONG_LOGGER_QR_CODE_SCANNED')}
            messageMiddle={`${t('QR_CODE_SCANNED')}: `}
            value={qrCode}
            middleTexts={texts}
        />
    )
}

SavyInitializationInputError.propTypes = propTypes
SavyInitializationInputError.defaultProps = defaultProps

export default SavyInitializationInputError
