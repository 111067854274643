import { useEffect } from 'react'

function useSupportNestedSteps({
    step,
    isSummaryView,
    isNested,
    nestedInputFromUserInput,
    inputConverter,
}) {
    useEffect(() => {
        if (step.isAnswered && isNested && !isSummaryView && nestedInputFromUserInput) {
            const loadedInput = inputConverter(step.userInput)

            if (loadedInput) {
                nestedInputFromUserInput(loadedInput, step.stepName)
            }
        }
    }, [
        nestedInputFromUserInput,
        step.stepName,
        step.isAnswered,
        isNested,
        isSummaryView,
        step.userInput,
        inputConverter,
    ])
}

export default useSupportNestedSteps
