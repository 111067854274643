import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import {
    Typography,
    Button,
} from '@mui/material'

import useStyles from './QrCodeRetry.style'

const propTypes = {
    qrCode: PropTypes.string,
    loggerNumber: PropTypes.string.isRequired,
    onRetry: PropTypes.func,
    validationMessageRender: PropTypes.element,
}

const defaultProps = {
    qrCode: undefined,
    onRetry: undefined,
    validationMessageRender: undefined,
}

const QrCodeRetry = ({
    qrCode,
    loggerNumber,
    onRetry,
    validationMessageRender,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()

    return (
        <div>
            <Typography
                variant="h3"
                className={classes.text}
            >
                {`${t('QR_CODE')}:`}
                <span className={classes.code}>{qrCode}</span>
            </Typography>
            <Typography
                variant="h3"
                className={classes.text}
            >
                {`${t('LOGGER_NUMBER')}:`}
                <span className={classes.code}>{loggerNumber}</span>
            </Typography>
            {validationMessageRender !== undefined && validationMessageRender}
            { onRetry && (
                <div className={classes.actions}>
                    <Button
                        data-testid="retry-button"
                        className={classes.button}
                        onClick={onRetry}
                    >
                        {t('RETRY')}
                    </Button>
                </div>
            )}
        </div>
    )
}

QrCodeRetry.propTypes = propTypes
QrCodeRetry.defaultProps = defaultProps

export default QrCodeRetry
