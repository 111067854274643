import React, { useState } from 'react'
import PropTypes from 'prop-types'
import LocationContext from './LocationContext'

const propTypes = { children: PropTypes.element.isRequired }

const LocationProvider = ({ children }) => {
    const [
        currentLocation,
        setCurrentLocation,
    ] = useState(
        {
            locationId: null,
            locationName: null,
        },
    )

    return (
        <LocationContext.Provider
            value={{
                location: currentLocation,
                setLocation: setCurrentLocation,
            }}
        >
            {children}
        </LocationContext.Provider>
    )
}

LocationProvider.propTypes = propTypes

export default LocationProvider
