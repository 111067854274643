import React, { useEffect } from 'react'
import {
    Route,
    Switch,
    Redirect,
    BrowserRouter,
} from 'react-router-dom'

import { MainMenuErrorBoundary } from 'App/ErrorBoundaries'
import Layout from './Layout'
import MainDashboard from './MainDashboard'
import LocationProvider, { LocationView } from './Location'
import ProcessesDashboard from './ProcessesDashboard'
import ProcessDashboard from './ProcessDashboard'
import ProcessPage from './ProcessPage'
import DirectScan from './DirectScan'
import Orders from './Orders'
import DirectScanProcessPage from './DirectScan/DirectScanProcessPage'

const getEnviroment = () => {
    const url = window.location

    if (url.host.includes('test')) {
        return ' (TEST)'
    }
    if (url.host.includes('dev')) {
        return ' (DEV)'
    }
    return null
}

const Root = () => {
    useEffect(() => {
        const env = getEnviroment()

        if (env) {
            document.title = `SkyCenter ${env}`
        }
    }, [])

    return (
        <BrowserRouter>
            <LocationProvider>
                <MainMenuErrorBoundary>
                    <Layout>
                        <LocationView>
                            <Switch>
                                <Route
                                    exact
                                    path="/mainDashboard"
                                >
                                    <MainDashboard />
                                </Route>
                                <Route
                                    exact
                                    path="/scan"
                                >
                                    <DirectScan />
                                </Route>
                                <Route
                                    exact
                                    path="/processes/:type/:number"
                                >
                                    <ProcessPage />
                                </Route>
                                <Route
                                    exact
                                    path="/processes_directscan/:type/:number"
                                >
                                    <DirectScanProcessPage />
                                </Route>
                                <Route
                                    path="/processes/:type"
                                >
                                    <ProcessDashboard />
                                </Route>
                                <Route
                                    exact
                                    path="/processes"
                                >
                                    <ProcessesDashboard />
                                </Route>
                                <Route
                                    exact
                                    path="/orders"
                                >
                                    <Orders />
                                </Route>
                                <Redirect
                                    path="/"
                                    to="/mainDashboard"
                                />
                            </Switch>
                        </LocationView>
                    </Layout>
                </MainMenuErrorBoundary>
            </LocationProvider>
        </BrowserRouter>
    )
}

export default Root
