/* eslint-disable complexity */
import {
    DEFAULT_VIEWTYPES,
    ACTIONS,
    initState,
} from 'shared/ScanLoggerQrCodeStepTemplate/utils'

export const VIEWTYPES = {
    ...DEFAULT_VIEWTYPES,
    LINKED_ANOTHER: 'LINKED_ANOTHER',
    LINKED_TO_THIS_ALREADY: 'LINKED_TO_THIS_ALREADY',
}

const getMode = ({
    loggerData,
    evidenceData,
    stepLoggerType,
}) => {
    const linkedToAnother = loggerData.containerId
        && loggerData.containerId !== evidenceData?.container?.id

    if (stepLoggerType && (stepLoggerType !== loggerData.loggerType)) {
        return VIEWTYPES.WRONG_LOGGER
    }

    if (linkedToAnother) {
        return VIEWTYPES.LINKED_ANOTHER
    }

    const linkedToThis = loggerData.containerId
        && loggerData.containerId === evidenceData?.container?.id

    if (linkedToThis) {
        return VIEWTYPES.LINKED_TO_THIS_ALREADY
    }

    return VIEWTYPES.CONFIRMED
}

const reducer = (state, action) => {
    switch (action.type) {
    case ACTIONS.START_SCAN:
        return {
            ...state,
            mode: VIEWTYPES.SCAN,
        }
    case ACTIONS.START_MANUAL:
        return {
            ...state,
            mode: VIEWTYPES.MANUAL,
        }
    case ACTIONS.SCANED:
        return {
            ...state,
            qrCode: action.qrCode,
        }
    case ACTIONS.SET_LOGGER_NUMBER:
        return {
            ...state,
            mode: getMode({
                loggerData: action.loggerData,
                evidenceData: action.evidenceData,
                stepLoggerType: action.stepLoggerType,
            }),
            loggerNumber: action.loggerNumber,
            loggerData: action.loggerData,
        }
    case ACTIONS.RETRY:
        return {
            ...state,
            ...initState,
        }
    case ACTIONS.WRONG_QR_CODE:
        return {
            ...state,
            mode: VIEWTYPES.WRONG_QR,
        }
    default:
        throw new Error(`Not handled action type for DirectScan component = ${action.type}`)
    }
}

export default reducer
