import React from 'react'
import useStyles from './SummaryChoicesList.style'

const EmptyIcon = () => {
    const classes = useStyles()

    return (
        <span className={classes.icon} />
    )
}

export default EmptyIcon
