import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { useAuth } from 'App/Auth'

const propTypes = { children: PropTypes.element.isRequired }

const I18nLocaleProvider = ({ children }) => {
    const userInfo = useAuth()
    const { i18n } = useTranslation()

    useEffect(() => {
        if (i18n.isInitialized) {
            i18n.changeLanguage(userInfo.user.locale?.[0])
        }
    }, [
        i18n,
        userInfo.user.locale,
    ])

    return (
        <>
            {children}
        </>
    )
}

I18nLocaleProvider.propTypes = propTypes

export default I18nLocaleProvider
