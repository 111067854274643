import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        container: {
            ...theme.custom.boxShadowRadius,
            ...theme.custom.border,
            overflow: 'hidden',
            cursor: 'pointer',
            padding: '10px 8px 4px 12px',
            minWidth: 320,
        },
        numberLine: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingBottom: 10,
        },
        active: {
            color: theme.palette.primary.main,
            height: theme.typography.h3.lineHeight,
        },
        tempContainer: { display: 'inline-flex' },
        numberLineLetters: { lineHeight: theme.typography.h2.lineHeight },
        tempImg: {
            width: 20,
            height: 36,
        },
        numberTypeContainer: { display: 'inline-flex' },
        bottomWrapper: {
            boxSizing: 'border-box',
            minHeight: 72,
        },
        bottomText: { minHeight: theme.typography.h3.lineHeight },
    }
})

export default useStyles
