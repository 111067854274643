import getGlobalAssetUrl from 'shared/utils/getGlobalAssetUrl'

const mainDashboardWidgetMap = [
    {
        id: '1',
        titleKey: 'SCAN',
        infoKey: 'SCAN_BARCODE_QRCODE',
        linkTo: '/scan',
        iconSrc: getGlobalAssetUrl('skycenter/scan_board.svg'),
    },
    {
        id: '2',
        titleKey: 'PROCESSES',
        infoKey: 'CONTINUE_OPEN_PROCESS',
        linkTo: '/processes',
        iconSrc: getGlobalAssetUrl('skycenter/processes_board.svg'),
    },
    {
        id: '3',
        titleKey: 'PRECON_UNITS',
        infoKey: 'CONTROL_PRECON_UNIT',
        iconSrc: getGlobalAssetUrl('skycenter/preconunits_board.svg'),
        disabled: true,
    },
    {
        id: '4',
        titleKey: 'ORDERS',
        infoKey: 'CONFIRM_ORDER_SHIPMENT',
        linkTo: '/orders',
        iconSrc: getGlobalAssetUrl('icons/orders.svg'),
    },
]

export default mainDashboardWidgetMap
