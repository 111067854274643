import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import common from 'shared/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        dialogContentRoot: {
            ...common.flexJustityAlignCenter,
            padding: 0,
            height: 680,
        },
        text: {
            paddingRight: 15,
            paddingLeft: 15,
            paddingBottom: 40,
        },
        icon: {
            width: '100%',
            minWidth: 600,
            minHeight: 650,
        },
        actions: {},
        button: {
            ...theme.custom.button,
            margin: '0 10px',
        },
        message: {
            ...theme.custom.messageText,
            fontWeight: theme.typography.fontWeightRegular,
        },
        content: {
            ...common.flexJustityAlignCenter,
            padding: 0,
            flexDirection: 'column',
        },
    }
})

export default useStyles
