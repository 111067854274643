const makeStreamTracksStoppable = (streamPromise) => {
    let needStopTracks = false
    const wrappedPromise = new Promise((resolve, reject) => {
        streamPromise
            .then((stream) => {
                if (needStopTracks) {
                    stream.getTracks().forEach((track) => {
                        track.stop()
                    })
                    return null
                }

                return resolve(stream)
            })
            .catch((error) => {
                // eslint-disable-next-line prefer-promise-reject-errors
                return (needStopTracks ? reject({ needStopTracks }) : reject(error))
            })
    })

    return {
        promise: wrappedPromise,
        stopTracks() {
            needStopTracks = true
        },
    }
}

export default makeStreamTracksStoppable
