import React, {
    useCallback,
    useEffect,
} from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Loading } from '@skycell-ag/shared-components'
import { Typography } from '@mui/material'
import PreLoadScannerProvider from 'shared/QRScanner/PreLoadScanner'

import ROUTE_KEYS from 'shared/utils/routeKeys'
import useLocation from '../hook/useLocation'
import { useLocationContext } from '../LocationProvider'

import useStyles from './CentersDashboard.style'

const propTypes = { children: PropTypes.element.isRequired }

const CentersDashboard = ({ children }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const history = useHistory()
    const {
        location,
        setLocation,
    } = useLocationContext()
    const {
        data: centers,
        isLoading,
        error,
    } = useLocation(Boolean(!location.locationId))

    const onCenterSelect = useCallback((center) => {
        const contextValue = {
            locationName: center.name,
            locationId: center.id,
        }

        setLocation(contextValue)
        history.push(ROUTE_KEYS.MAIN)
    }, [
        setLocation,
        history,
    ])

    useEffect(() => {
        if (!location.locationId) {
            history.push(ROUTE_KEYS.MAIN)
        }
    }, [
        history,
        location.locationId,
    ])

    if (isLoading) {
        return (<Loading />)
    }
    if (error) {
        throw error
    }

    if (location.locationId) {
        return (
            <PreLoadScannerProvider enhancedSkyCenterScanningAllowed={false}>
                {children}
            </PreLoadScannerProvider>
        )
    }

    return (
        <div className={classes.root}>
            <div className={classes.main}>
                <div className={classes.userLocationTitle}>
                    <Typography
                        variant="h1"
                        className={classes.userLocationText}
                    >
                        {t('SELECT_SERVICE_CENTER')}
                    </Typography>
                </div>
                <div className={classes.container}>
                    {
                        centers.map((center) => {
                            return (
                                <div
                                    key={center.id}
                                    data-testid="service-center-item"
                                    className={classes.center}
                                    onClick={() => { onCenterSelect(center) }}
                                    aria-hidden="true"
                                >
                                    <Typography
                                        variant="h3"
                                        className={classes.centerText}
                                    >
                                        {center.name}
                                    </Typography>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

CentersDashboard.propTypes = propTypes

export default CentersDashboard
