import { makeStyles } from '@mui/styles'
import common from 'shared/styles'

const useStyles = makeStyles(() => {
    return {
        reader: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        actions: {
            ...common.flexJustityAlignCenter,
            flexDirection: 'column',
            padding: 0,
        },
        button: {
            width: 'auto',
            padding: '40px 0',
        },
        icon: {
            width: 50,
            height: 50,
        },
    }
})

export default useStyles
