export const createPicturesInputObject = (values, propName) => {
    return {
        value: {},
        attachments: values,
        inputPropName: propName,
        isPicture: true,
    }
}

export const createAttachmentFileName = (processId, stepName, number = null) => {
    const numberPart = number !== null ? `-${number}` : ''

    return `SkyCenter-EvID_${processId}-${stepName}${numberPart}.png`
}
