import {
    useState,
    useEffect,
    useCallback,
} from 'react'

import getCameraOrientation from 'shared/utils/getCameraOrientation'
import VIDEO_CONSTRAINS from 'shared/utils/videoConstrains'

const getAspectRatioConstrains = () => {
    return VIDEO_CONSTRAINS[getCameraOrientation()]
}

const useCameraResolutionContrains = () => {
    const [
        videoResolutionConstrains,
        setVideoResolutionConstrains,
    ] = useState(getAspectRatioConstrains())

    const onOrientaonChange = useCallback(() => {
        setVideoResolutionConstrains(getAspectRatioConstrains())
    }, [])

    useEffect(() => {
        // eslint-disable-next-line no-restricted-globals
        screen.orientation.addEventListener('change', onOrientaonChange)
        return () => {
            // eslint-disable-next-line no-restricted-globals
            screen.orientation.removeEventListener('change', onOrientaonChange)
        }
    }, [onOrientaonChange])

    return { videoResolutionConstrains }
}

export default useCameraResolutionContrains
