const getUserInfo = (extendedProfile) => {
    const {
        assignedRoles = [],
        attributes,
        email,
        firstName,
        lastName,
        username,
        roles,
    } = extendedProfile

    return {
        assignedRoles,
        email,
        firstName,
        attributes,
        roles,
        lastName,
        username,
    }
}

export default getUserInfo
