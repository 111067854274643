import React, { useMemo } from 'react'
import {
    useLocation,
    Link,
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
    Typography,
    Breadcrumbs as MatBreadcrumbs,
} from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import resolvePath from './resolvePath'

import useStyles from './Breadcrumbs.style'

const Breadcrumbs = () => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { pathname } = useLocation()
    const pathnames = useMemo(() => {
        return pathname.split('/').filter((x) => { return x !== 'mainDashboard' && x })
    }, [pathname])
    const breadCrumbsMap = useMemo(() => {
        return pathnames.map((value, index) => {
            const to = `/${pathnames.slice(0, index + 1).join('/')}`

            return {
                to,
                name: resolvePath(value, index, to),
            }
        })
    }, [pathnames])

    return (
        <MatBreadcrumbs
            aria-label="breadcrumb"
            separator={(
                <NavigateNextIcon
                    fontSize="small"
                    className={classes.separator}
                />
            )}
        >
            {breadCrumbsMap.map((el, index) => {
                if (index === breadCrumbsMap.length - 1) {
                    return (
                        <Typography
                            variant="h1"
                            key={el.to}
                        >
                            {t(el.name)}
                        </Typography>
                    )
                }
                return (
                    <Link
                        to={el.to}
                        key={el.to}
                        className={classes.link}
                    >
                        <Typography
                            variant="h1"
                        >
                            {t(el.name)}
                        </Typography>
                    </Link>
                )
            })}
        </MatBreadcrumbs>
    )
}

export default Breadcrumbs
