import React, {
    useRef,
    useEffect,
    useCallback,
} from 'react'
import { PropTypes } from 'prop-types'
import { Button } from '@mui/material'
import { Loading } from '@skycell-ag/shared-components'

import getGlobalAssetUrl from 'shared/utils/getGlobalAssetUrl'

import useHtml5QrcodeScanner, { SCAN_AREA_PADDING } from '../hooks/useHtml5QrcodeScanner'
import useStyles from './Html5QrcodeScanner.style'

const READER_ELEMENT_ID = 'readerId'

const propTypes = {
    onScan: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    isBarCodeScan: PropTypes.bool.isRequired,
    options: PropTypes.shape({
        width: PropTypes.number,
        aspect: PropTypes.number,
    }),
}

const defaultProps = {
    onCancel: undefined,
    options: undefined,
}

const Html5QrcodeScanner = ({
    onScan,
    onCancel,
    isBarCodeScan,
    options,
}) => {
    const classes = useStyles()
    const readerRef = useRef()

    const {
        initScan,
        scanStarted,
    } = useHtml5QrcodeScanner({
        readerId: READER_ELEMENT_ID,
        onScan,
        isBarCodeScan,
        options,
    })

    useEffect(() => {
        if (readerRef.current) {
            initScan()
        }
    }, [initScan])

    const onCancelLocal = useCallback(() => {
        onCancel()
    }, [onCancel])

    const windowWidth = options ? options.width : window.innerWidth - (SCAN_AREA_PADDING + 10)

    return (
        <>
            { !scanStarted && <Loading />}
            <div
                id={READER_ELEMENT_ID}
                ref={readerRef}
                className={classes.reader}
                style={{ width: `${windowWidth}px` }}
            />
            { onCancel && (
                <div className={classes.actions}>
                    <Button
                        data-testid="cancel-button"
                        onClick={onCancelLocal}
                        className={classes.button}
                    >
                        <img
                            className={classes.icon}
                            alt="logo"
                            src={getGlobalAssetUrl('skycenter/arrow_back_black_24dp.svg')}
                        />
                    </Button>
                </div>
            )}
        </>
    )
}

Html5QrcodeScanner.propTypes = propTypes
Html5QrcodeScanner.defaultProps = defaultProps

export default Html5QrcodeScanner
