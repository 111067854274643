import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { Link } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import TwoRowErrorPage from 'shared/ErrorPages/TwoRowErrorPage'
import useStyles from './NoOrders.style'

const NoOrders = () => {
    const { t } = useTranslation()
    const classes = useStyles()

    return (
        <TwoRowErrorPage
            message={t('NO_ORDERS_MESSAGE')}
            iconPath="skycenter/sleeping_robot.svg"
            iconAlt="logo"
        >
            <Button
                data-testid="back-button"
                component={Link}
                to="/mainDashboard"
                className={classes.button}
                startIcon={<ArrowBackIcon />}
            >
                {t('BACK')}
            </Button>
        </TwoRowErrorPage>
    )
}

export default NoOrders
