import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Loading } from '@skycell-ag/shared-components'
import { Backdrop } from '@mui/material'

import ROUTE_KEYS from 'shared/utils/routeKeys'

import InputContextProvider from './InputContextProvider'

import useProcess from './hooks'
import ProcessActions from './ProcessActions'
import ProcessBar from './ProcessBar'
import ProcessGroup from './ProcessGroup'
import ProcessSummary from './ProcessSummary'
import useStyles from './ProcessPage.style'

const propTypes = {
    processKey: PropTypes.string.isRequired,
    assetNumber: PropTypes.string.isRequired,
    directScan: PropTypes.bool,
}

const defaultProps = { directScan: false }

const ProcessPage = ({
    assetNumber,
    processKey,
    directScan,
}) => {
    const classes = useStyles()
    const history = useHistory()
    const cancelCallback = useCallback(() => {
        history.push(ROUTE_KEYS.MAIN)
    }, [history])
    const {
        isLoading = false,
        error,
        showSummary,
        groups,
        currentGroup,
        userInput,
        onUserInputChange,
        onUserInputClear,
        onAction,
        changedBy,
    } = useProcess({
        processKey,
        assetNumber,
        directScan,
        cancelCallback,
    })

    if (error) {
        throw error
    }

    return (
        <div className={classes.root}>
            <Backdrop
                className={classes.backdrop}
                open={isLoading}
            >
                <Loading />
            </Backdrop>
            {!showSummary
            && (
                <InputContextProvider callback={onUserInputClear}>
                    <>
                        <ProcessBar
                            groups={groups}
                            current={currentGroup}
                        />
                        <div className={classes.main}>
                            {currentGroup && (
                                <div className={classes.content}>
                                    <ProcessGroup
                                        className={classes.page}
                                        steps={currentGroup.steps}
                                        assetNumber={assetNumber}
                                        inputCallback={onUserInputChange}
                                        changedBy={changedBy}
                                    />
                                    <ProcessActions
                                        userInput={userInput}
                                        currentGroup={currentGroup}
                                        actionCallback={onAction}
                                    />
                                </div>
                            )}
                        </div>
                    </>
                </InputContextProvider>
            )}
            {showSummary && (
                <ProcessSummary
                    assetNumber={assetNumber}
                    actionCallback={onAction}
                    changedBy={changedBy}
                />
            )}
        </div>
    )
}

ProcessPage.propTypes = propTypes
ProcessPage.defaultProps = defaultProps

export default ProcessPage
