import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => {
    return {
        text: { paddingLeft: 30 },
        context: {
            display: 'flex',
            width: '100%',
        },
        deleteButton: {
            padding: 0,
            paddingLeft: 10,
            alignSelf: 'start',
        },
        emptyCommentAria: {
            width: '100%',
            height: 310,
        },
    }
})

export default useStyles
