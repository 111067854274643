import { makeStyles } from '@mui/styles'
import common from 'shared/styles'

const useStyles = makeStyles(() => {
    return {
        reader: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        actions: {
            ...common.flexJustityAlignCenter,
            padding: 0,
        },
        button: {
            width: 'auto',
            margin: '50px 25px 50px 25px',
        },
        icon: {
            width: 50,
            height: 50,
        },
    }
})

export default useStyles
