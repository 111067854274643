import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useInputContext } from 'App/Root/ProcessPage/InputContextProvider'

import stepsMapper from './steps'

const propTypes = {
    step: PropTypes.shape({
        stepType: PropTypes.string.isRequired,
        stepName: PropTypes.string.isRequired,
        isNestedStep: PropTypes.bool.isRequired,
    }).isRequired,
    assetNumber: PropTypes.string.isRequired,
    inputCallback: PropTypes.func,
    curentGroupSteps: PropTypes.arrayOf(
        PropTypes.shape({ stepName: PropTypes.string.isRequired }),
    ).isRequired,
    isNested: PropTypes.bool,
    nestedInputFromUserInput: PropTypes.func,
    isSummaryView: PropTypes.bool,
    changedBy: PropTypes.string,
}
const defaultProps = {
    isNested: false,
    isSummaryView: false,
    inputCallback: undefined,
    nestedInputFromUserInput: undefined,
    changedBy: undefined,
}

const ProcessStepPage = ({
    step,
    assetNumber,
    inputCallback,
    curentGroupSteps,
    isNested,
    isSummaryView,
    nestedInputFromUserInput,
    changedBy,
}) => {
    const { callback } = useInputContext()

    useEffect(() => {
        return () => {
            if (!isSummaryView && step.isNestedStep && callback) {
                callback(step.stepName)
            }
        }
    }, [
        step.isNestedStep,
        step.stepName,
        isSummaryView,
        callback,
    ])

    if (!stepsMapper[step.stepType]) {
        return (
            <div>
                {`This step type '${step.stepType}' is not implemented yet`}
            </div>
        )
    }

    const Component = stepsMapper[step.stepType]

    return (
        <Component
            step={step}
            assetNumber={assetNumber}
            inputCallback={inputCallback}
            curentGroupSteps={curentGroupSteps}
            isNested={isNested}
            isSummaryView={isSummaryView}
            nestedInputFromUserInput={nestedInputFromUserInput}
            changedBy={changedBy}
        />
    )
}

ProcessStepPage.propTypes = propTypes
ProcessStepPage.defaultProps = defaultProps

export default ProcessStepPage
