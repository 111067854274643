import {
    useReducer,
    useEffect,
    useMemo,
    useCallback,
} from 'react'
import { useHistory } from 'react-router-dom'

import getGlobalAssetUrl from 'shared/utils/getGlobalAssetUrl'
import ROUTE_KEYS from 'shared/utils/routeKeys'
import {
    useAsset,
    useAssetProcesses,
} from 'App/Root/hooks'
import { useLocationContext } from 'App/Root/Location'

import reducer, {
    actions,
    initState,
} from './DirectScan.reducer'

const buildProcessWidget = (process, assetNumber) => {
    return {
        id: `${process.process}`,
        titleKey: `processDefinition:${process.processLabel}`,
        iconSrc: getGlobalAssetUrl(`skycenter/processes/${process.process}.svg`),
        linkTo: ROUTE_KEYS.ASSET_PROCESS(process.process, assetNumber),
    }
}

function useDirectScan() {
    const history = useHistory()
    const { location } = useLocationContext()
    const [
        state,
        dispatch,
    ] = useReducer(reducer, initState)
    const {
        data: assetData,
        isLoading: isAssetLoading,
        error: assetError,
    } = useAsset(state.assetNumber)

    const {
        data: processesData,
        isLoading: isProcessesLoading,
        error: processesError,
    } = useAssetProcesses({
        location: location.locationId,
        assetNumber: state.assetNumber,
    }, state.needGetAvalProcess)

    useEffect(() => {
        if (state.assetNumber && assetData) {
            if (assetData.id) {
                history.push(
                    ROUTE_KEYS.ASSET_PROCESS(assetData.process,
                        state.assetNumber),
                )
            } else if (!state.needGetAvalProcess) {
                dispatch({ type: actions.GET_AVAL_PROCESS })
            }
        }
    }, [
        assetData,
        state.assetNumber,
        history,
        state.needGetAvalProcess,
    ])

    useEffect(() => {
        if (state.assetNumber && processesData && !state.showProcessSelection) {
            if (processesData.length > 1 || processesData.length === 0) {
                dispatch({ type: actions.SHOW_PROCESS_SELECTION })
            } else {
                history.push(ROUTE_KEYS.ASSET_PROCESS_DIRECT_SCAN(processesData[0].process,
                    state.assetNumber))
            }
        }
    }, [
        processesData,
        history,
        state.assetNumber,
        state.showProcessSelection,
    ])

    const processesMap = useMemo(() => {
        return processesData?.map((process) => {
            return buildProcessWidget(process, state.assetNumber)
        })
    }, [
        processesData,
        state.assetNumber,
    ])

    const onScan = useCallback(({
        success,
        value,
    }) => {
        if (success) {
            dispatch({
                type: actions.SCAN_SECCESSFUL,
                assetNumber: value,
            })
        } else {
            history.push(ROUTE_KEYS.MAIN)
        }
    }, [history])

    const error = useMemo(() => {
        return assetError || processesError
    }, [
        assetError,
        processesError,
    ])
    const isLoading = useMemo(() => {
        return isAssetLoading || isProcessesLoading
    }, [
        isAssetLoading,
        isProcessesLoading,
    ])

    return {
        isLoading,
        error,
        openScan: state.scanDialogOpen,
        openProcesses: state.showProcessSelection,
        processesMap,
        onScan,
    }
}

export default useDirectScan
