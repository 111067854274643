/* eslint-disable complexity */
import {
    DEFAULT_VIEWTYPES,
    ACTIONS,
    initState,
} from 'shared/ScanLoggerQrCodeStepTemplate/utils'

export const VIEWTYPES = {
    ...DEFAULT_VIEWTYPES,
    NOT_LINKED: 'NOT_LINKED',
}

const getMode = ({
    containerPairedData,
    stepLoggerType,
    loggerData,
}) => {
    if (stepLoggerType && (stepLoggerType !== loggerData?.loggerType)) {
        return VIEWTYPES.WRONG_LOGGER
    }

    if (!containerPairedData?.isPaired) {
        return VIEWTYPES.NOT_LINKED
    }

    return VIEWTYPES.CONFIRMED
}

const reducer = (state, action) => {
    switch (action.type) {
    case ACTIONS.START_SCAN:
        return {
            ...state,
            mode: VIEWTYPES.SCAN,
        }
    case ACTIONS.START_MANUAL:
        return {
            ...state,
            mode: VIEWTYPES.MANUAL,
        }
    case ACTIONS.SCANED:
        return {
            ...state,
            qrCode: action.qrCode,
        }
    case ACTIONS.SET_LOGGER_NUMBER:
        return {
            ...state,
            mode: getMode({
                containerPairedData: action.containerPairedData,
                stepLoggerType: action.stepLoggerType,
                loggerData: action.loggerData,
            }),
            loggerNumber: action.loggerNumber,
            loggerData: action.loggerData,
        }
    case ACTIONS.RETRY:
        return {
            ...state,
            ...initState,
        }
    case ACTIONS.WRONG_QR_CODE:
        return {
            ...state,
            mode: VIEWTYPES.WRONG_QR,
        }
    default:
        throw new Error(`Not handled action type for DirectScan component = ${action.type}`)
    }
}

export default reducer
