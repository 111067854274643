import React from 'react'
import { Loading } from '@skycell-ag/shared-components'

import ScanDialog from 'shared/ScanDialog'
import Dashboard, { DashboardCardItem } from 'shared/Dashboard'

import useDirectScan from './useDirectScan'

import useStyles from './DirectScan.style'

const DirectScan = () => {
    const classes = useStyles()

    const {
        isLoading,
        error,
        openScan,
        openProcesses,
        processesMap,
        onScan,
    } = useDirectScan()

    if (error) {
        throw error
    }

    if (openProcesses && (!processesMap || processesMap.length === 0)) {
        return (
            <span>No available processes</span>
        )
    }

    return (
        <div className={classes.root}>
            <div>
                <ScanDialog
                    open={openScan}
                    onClose={onScan}
                    item={null}
                />
            </div>
            <div className={classes.main}>
                {(openProcesses && (
                    <Dashboard
                        widgets={processesMap}
                        widgetComponent={DashboardCardItem}
                    />
                ))}
            </div>
            { isLoading && (<Loading />)}
        </div>
    )
}

export default DirectScan
