import React, {
    useCallback,
    useMemo,
    useState,
} from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import StepPageLayout from 'shared/StepPageLayout'
import { createInputObject } from 'shared/utils/stepsUtils'

import CartaScanSummary from './CartaScanSummary'
import CartaScanInput from './CartaScanInput'

const propTypes = {
    step: PropTypes.shape({
        stepName: PropTypes.string.isRequired,
        stepTitle: PropTypes.string.isRequired,
        isRequired: PropTypes.bool.isRequired,
        isAnswered: PropTypes.bool,
        userInput: PropTypes.shape({ loggerNumber: PropTypes.string }),
    }).isRequired,
    inputCallback: PropTypes.func,
    isSummaryView: PropTypes.bool,
    isNested: PropTypes.bool,
}

const defaultProps = {
    isSummaryView: false,
    isNested: false,
    inputCallback: undefined,
}

const CartaScan = ({
    step,
    inputCallback,
    isSummaryView,
    isNested,
}) => {
    const { t } = useTranslation()

    const [
        scanResult,
        setScanResult,
    ] = useState('')

    const [
        manualInput,
        setManualInput,
    ] = useState(false)

    const [
        canRetry,
        setCanRetry,
    ] = useState(step.isAnswered && !isSummaryView)

    const onConfirm = useCallback(() => {
        const input = createInputObject({ loggerNumber: scanResult })

        inputCallback(input, step.stepName)
    }, [
        inputCallback,
        step,
        scanResult,
    ])

    const title = useMemo(() => {
        if (scanResult) {
            return 'BARCODE_SCANNED'
        }

        return manualInput ? 'PLEASE_ENTER_CARTA_BARCODE_CODE' : 'SCAN_CARTA_BARCODE'
    }, [
        manualInput,
        scanResult,
    ])

    const onRetry = useCallback(() => {
        inputCallback(createInputObject(null, !step.isRequired), step.stepName)

        setCanRetry(false)
    }, [
        inputCallback,
        step.stepName,
        step.isRequired,
    ])

    const showSummary = useMemo(() => {
        return isSummaryView || canRetry
    }, [
        isSummaryView,
        canRetry,
    ])

    return (
        <StepPageLayout
            title={t(title)}
            isRequired={step.isRequired}
            isAnswered={step?.isAnswered || Boolean(scanResult)}
            isNested={isNested}
            isSummaryView={isSummaryView}
        >
            {!isSummaryView && !canRetry && (
                <CartaScanInput
                    scanResult={scanResult}
                    manualInput={manualInput}
                    setManualInput={setManualInput}
                    onScanResult={setScanResult}
                    onConfirm={onConfirm}
                />
            )}
            {showSummary && (
                <CartaScanSummary
                    isAnswered={step?.isAnswered}
                    value={step?.userInput?.loggerNumber}
                    onRetry={canRetry ? onRetry : undefined}
                />
            )}
        </StepPageLayout>
    )
}

CartaScan.propTypes = propTypes
CartaScan.defaultProps = defaultProps

export default CartaScan
