import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'

import getGlobalAssetUrl from 'shared/utils/getGlobalAssetUrl'
import useStyles from './TwoRowErrorPage.style'

const propTypes = {
    message: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    iconPath: PropTypes.string.isRequired,
    iconAlt: PropTypes.string.isRequired,
}

const TwoRowErrorPage = ({
    message,
    children,
    iconPath,
    iconAlt,
}) => {
    const classes = useStyles()

    return (
        <div className={classes.main}>
            <div className={classes.icon}>
                <img
                    alt={iconAlt}
                    src={getGlobalAssetUrl(iconPath)}
                    className={classes.image}
                />
            </div>
            <div className={classes.text}>
                <Typography
                    variant="h1"
                    className={classes.message}
                >
                    {message}
                </Typography>
            </div>
            <div className={classes.bottom}>
                {children}
            </div>
        </div>
    )
}

TwoRowErrorPage.propTypes = propTypes

export default TwoRowErrorPage
