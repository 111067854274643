import React from 'react'
import PropTypes from 'prop-types'
import {
    Typography,
    Button,
    DialogContent,
    DialogActions,
    Dialog,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import getGlobalAssetUrl from 'shared/utils/getGlobalAssetUrl'
import useStyles from './ConfirmDialog.style'

const propTypes = {
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
}

const ConfirmDialog = ({
    open,
    onConfirm,
    onCancel,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <Dialog
            id="cancel-process-confirm-dialog"
            keepMounted
            fullWidth
            maxWidth="sm"
            aria-labelledby="cancel-process-confirm-dialog"
            open={open}
            classes={{ root: classes.dialogRoot }}
        >
            <DialogContent classes={{ root: classes.dialogContentRoot }}>
                <img
                    alt="logo"
                    src={getGlobalAssetUrl('icons/warning.svg')}
                    className={classes.icon}
                />
                <Typography
                    variant="h3"
                >
                    {t('CANCEL_PROCESS')}
                </Typography>
            </DialogContent>
            <DialogActions
                classes={{ root: classes.dialogActionsRoot }}
            >
                <Button
                    data-testid="confirm-button"
                    className={classes.button}
                    onClick={onConfirm}
                >
                    {t('YES')}
                </Button>
                <Button
                    data-testid="cancel-button"
                    className={classes.button}
                    onClick={onCancel}
                >
                    {t('NO')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ConfirmDialog.propTypes = propTypes

export default ConfirmDialog
