import React, {
    useCallback,
    useMemo,
    useEffect,
} from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Loading } from '@skycell-ag/shared-components'

import StepPageLayout from 'shared/StepPageLayout'
import { createInputObject } from 'shared/utils/stepsUtils'
import QRScanner from 'shared/QRScanner'
import useGetLoggerByNumber from 'shared/hooks/useGetLogger/useGetLoggerByNumber'
import useGetLoggerByQRCode from 'shared/hooks/useGetLogger/useGetLoggerByQRCode'
import QRScanSummary from 'shared/QRScanSummary'
import getErrorCode from 'shared/utils/getErrorCode'

import useCheckPairedLogger from './hooks/useCheckPairedLogger'

const propTypes = {
    step: PropTypes.shape({
        stepName: PropTypes.string.isRequired,
        stepTitle: PropTypes.string.isRequired,
        isAnswered: PropTypes.bool,
        isRequired: PropTypes.bool.isRequired,
        loggerType: PropTypes.string,
        userInput: PropTypes.shape({ loggerNumber: PropTypes.string }),
    }).isRequired,
    isSummaryView: PropTypes.bool,
    isNested: PropTypes.bool,
    inputCallback: PropTypes.func,
    stepContextRender: PropTypes.element.isRequired,
    ViewTypes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    Actions: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    state: PropTypes.shape({
        loggerNumber: PropTypes.string,
        qrCode: PropTypes.string,
        mode: PropTypes.string.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    evidenceData: PropTypes.shape({ container: PropTypes.shape({ id: PropTypes.number }) }),
    needPairRequest: PropTypes.bool,
}

const defaultProps = {
    isSummaryView: false,
    isNested: false,
    inputCallback: undefined,
    evidenceData: undefined,
    needPairRequest: false,
}

const ScanLoggerQrCodeStepTemplate = ({
    step,
    isSummaryView,
    isNested,
    inputCallback,
    stepContextRender,
    ViewTypes,
    Actions,
    state,
    dispatch,
    evidenceData,
    needPairRequest,
}) => {
    const { t } = useTranslation()

    const needDoRequestForQRCode = useMemo(() => {
        if (isSummaryView) {
            return false
        }
        if (!step.isAnswered) {
            return true
        }
        return (state.loggerNumber === null && state.qrCode !== null)
    }, [
        state.loggerNumber,
        step.isAnswered,
        isSummaryView,
        state.qrCode,
    ])

    const {
        data,
        isLoading,
        error,
    } = useGetLoggerByQRCode(state.qrCode, needDoRequestForQRCode)

    const { data: qrCodeData } = useGetLoggerByNumber(state.loggerNumber, step.isAnswered)
    const { data: containerPairedData } = useCheckPairedLogger(evidenceData?.container?.id,
        state.qrCode,
        Boolean(state.qrCode) && needPairRequest)

    useEffect(() => {
        if (state.qrCode && data && evidenceData) {
            dispatch({
                type: Actions.SET_LOGGER_NUMBER,
                loggerNumber: data.loggerNumber,
                loggerData: data,
                stepLoggerType: step.loggerType,
                containerPairedData,
                evidenceData,
            })
        }
    }, [
        step.loggerType,
        state.qrCode,
        data,
        Actions,
        dispatch,
        evidenceData,
        containerPairedData,
    ])

    useEffect(() => {
        if (error) {
            if (getErrorCode(error) === 404) {
                dispatch({ type: Actions.WRONG_QR_CODE })
            } else {
                throw error
            }
        }
    }, [
        error,
        Actions,
        dispatch,
    ])

    const onScan = useCallback((value) => {
        inputCallback(createInputObject(null, false), step.stepName)
        dispatch({
            type: Actions.SCANED,
            qrCode: value,
        })
    }, [
        inputCallback,
        step.stepName,
        Actions,
        dispatch,
    ])

    const onRetry = useCallback(() => {
        inputCallback(createInputObject(null, true), step.stepName)

        dispatch({ type: Actions.RETRY })
    }, [
        inputCallback,
        step.stepName,
        Actions,
        dispatch,
    ])

    const isAnswered = useMemo(() => {
        return step.isAnswered || Boolean(state.loggerNumber)
    }, [
        state.loggerNumber,
        step.isAnswered,
    ])

    if (state.mode === ViewTypes.SCAN) {
        return (
            <QRScanner
                scanMode
                onScan={onScan}
                onCancel={onRetry}
                isBarCodeScan
            />
        )
    }

    return (
        <StepPageLayout
            title={t(`processDefinition:${step.stepTitle}`)}
            isRequired={step.isRequired}
            isAnswered={isAnswered}
            isNested={isNested}
            isSummaryView={isSummaryView}
        >
            <>
                {isLoading && (<Loading />)}
                {!isSummaryView && stepContextRender}
                {isSummaryView && (
                    <QRScanSummary
                        qrCode={String(qrCodeData?.loggerQrCode)}
                        loggerNumber={state.loggerNumber}
                        isAnswered={step.isAnswered}
                    />
                )}
            </>
        </StepPageLayout>
    )
}

ScanLoggerQrCodeStepTemplate.propTypes = propTypes
ScanLoggerQrCodeStepTemplate.defaultProps = defaultProps

export default ScanLoggerQrCodeStepTemplate
