import React, {
    useCallback,
    useState,
} from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
    Button,
    TextField,
} from '@mui/material'

import { useTextFieldStyles } from 'shared/TextStepField/TextStepField.style'
import PictureLayout from 'shared/PictureLayout/PictureLayout'
import useStyles from './SkippableCommentPicture.style'

const propTypes = {
    image: PropTypes.string.isRequired,
    onAdd: PropTypes.func.isRequired,
    onSkip: PropTypes.func.isRequired,
    isRequired: PropTypes.bool.isRequired,
    isNested: PropTypes.bool,
}
const defaultProps = { isNested: false }

const SkippableCommentPicture = ({
    image,
    onAdd,
    onSkip,
    isRequired,
    isNested,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const textFieldStyles = useTextFieldStyles()

    const [
        comment,
        setComment,
    ] = useState('')

    const onTextChange = useCallback((event) => {
        setComment(event.target.value)
    }, [])

    const onAddClick = useCallback(() => {
        onAdd(comment)
    }, [
        onAdd,
        comment,
    ])

    return (
        <PictureLayout
            title={t('ADD_COMMENT')}
            image={image}
            isRequired={isRequired}
            isAnswered
            isNested={isNested}
        >
            <div className={classes.text}>
                <TextField
                    id="outlined-helperText"
                    value={comment}
                    fullWidth
                    multiline
                    rows={7}
                    size="small"
                    InputProps={{ classes: textFieldStyles }}
                    variant="outlined"
                    onChange={onTextChange}
                />
                <div className={classes.buttons}>
                    <Button
                        data-testid="add-button"
                        className={classes.button}
                        onClick={onAddClick}
                    >
                        {t('ADD')}
                    </Button>
                    <Button
                        data-testid="skip-button"
                        className={classes.button}
                        onClick={onSkip}
                    >
                        {t('SKIP')}
                    </Button>
                </div>
            </div>
        </PictureLayout>
    )
}

SkippableCommentPicture.propTypes = propTypes
SkippableCommentPicture.defaultProps = defaultProps

export default SkippableCommentPicture
