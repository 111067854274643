import React from 'react'
import PropTypes from 'prop-types'
import {
    Typography,
    Button,
} from '@mui/material'

import getGlobalAssetUrl from 'shared/utils/getGlobalAssetUrl'
import useStyles from './TwoColumnErrorPage.style'

const propTypes = {
    iconPath: PropTypes.string.isRequired,
    iconAlt: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    onButtonClick: PropTypes.func.isRequired,
    buttonDataTestId: PropTypes.string.isRequired,
    messageTop: PropTypes.string.isRequired,
    messageBottom: PropTypes.string,
    middleTexts: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string,
    })),
}

const defaultProps = {
    middleTexts: [],
    messageBottom: undefined,
}

const TwoColumnErrorPage = ({
    iconPath,
    iconAlt,
    buttonLabel,
    onButtonClick,
    buttonDataTestId,
    messageTop,
    messageBottom,
    middleTexts,
}) => {
    const classes = useStyles()

    return (
        <div className={classes.main}>
            <div>
                <img
                    className={classes.icon}
                    src={getGlobalAssetUrl(iconPath)}
                    alt={iconAlt}
                />
            </div>
            <div className={classes.info}>
                <Typography
                    variant="h3"
                    className={classes.text}
                >
                    {messageTop}
                </Typography>
                { middleTexts.length > 0 && (
                    <div className={classes.context}>
                        {middleTexts.map(({
                            label, value,
                        }) => {
                            return (
                                <Typography
                                    variant="h3"
                                    className={classes.text}
                                    key={label}
                                >
                                    {label}
                                    <span className={classes.tempValue}>{value}</span>
                                </Typography>
                            )
                        })}
                    </div>
                )}
                {messageBottom && (
                    <Typography
                        variant="h3"
                        className={classes.text}
                    >
                        {messageBottom}
                    </Typography>
                )}
                <div className={classes.actions}>
                    <Button
                        data-testid={buttonDataTestId}
                        className={classes.button}
                        onClick={onButtonClick}
                    >
                        {buttonLabel}
                    </Button>
                </div>
            </div>
        </div>
    )
}

TwoColumnErrorPage.propTypes = propTypes
TwoColumnErrorPage.defaultProps = defaultProps

export default TwoColumnErrorPage
