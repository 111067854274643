import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        separator: {
            color: theme.palette.common.white,
            fontWeight: 100,
            height: 30,
            width: 30,
        },
        link: {
            color: theme.palette.common.white,
            textDecoration: 'none',
            fontWeight: theme.typography.fontWeightRegular,
            display: 'block',
            whiteSpace: 'nowrap',
            cursor: 'pointer',
        },
    }
})

export default useStyles
