import React from 'react'
import PropTypes from 'prop-types'

import {
    ThemeProvider as MuiThemeProvider,
    StyledEngineProvider,
    createTheme,
} from '@mui/material/styles'
import skyTheme from '@skycell-ag/theme'

import {
    StylesProvider as Provider,
    jssPreset,
    createGenerateClassName,
} from '@mui/styles'
import { create } from 'jss'
import jssExtend from 'jss-extend'
import jssNested from 'jss-nested'
import skyCenterTheme from './theme'

const propTypes = { children: PropTypes.element.isRequired }
const jss = create({
    ...jssPreset(),
    plugins: [
        ...jssPreset().plugins,
        jssExtend(),
        jssNested(),
    ],
    insertionPoint: document.getElementById('jss-insertion-point'),
})

const generateClassName = createGenerateClassName({
    productionPrefix: 'skycenter',
    disableGlobal: true,
})

const theme = createTheme(skyTheme, skyCenterTheme(skyTheme))

const StylesProvider = ({ children }) => {
    return (
        <Provider
            jss={jss}
            generateClassName={generateClassName}
        >
            <StyledEngineProvider injectFirst>
                <MuiThemeProvider theme={theme}>
                    {children}
                </MuiThemeProvider>
            </StyledEngineProvider>
        </Provider>
    )
}

StylesProvider.propTypes = propTypes

export default StylesProvider
