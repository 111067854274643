import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'

import parseErrorMessage from 'shared/utils/parseErrorMessage'

const propTypes = {
    error: PropTypes.shape({ message: PropTypes.string }).isRequired,
    resetErrorBoundary: PropTypes.func.isRequired,
}

const styles = {
    button: {
        color: '#FFFFFF',
        font: 'normal normal 400 20px/24px Roboto',
        backgroundColor: '#8BD1E8',
        width: '153px',
        height: '59px',
        padding: 0,
        boxShadow: '0px 1px 3px #00000033',
        whiteSpace: 'nowrap',
        letterSpacing: '2.14px',
    },
    text: {
        color: '#00000099',
        fontWeight: 400,
        letterSpacing: '1.11px',
    },
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
}

const ErrorFallback = ({
    error,
    resetErrorBoundary,
}) => {
    return (
        <div style={styles.container}>
            <h1 style={styles.text}>{parseErrorMessage(error)}</h1>
            <Button
                data-testid="ok-button"
                onClick={resetErrorBoundary}
                style={styles.button}
            >
                REFRESH
            </Button>
        </div>
    )
}

ErrorFallback.propTypes = propTypes

export default ErrorFallback
