import React, {
    useCallback,
    useMemo,
    useReducer,
} from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { createInputObject } from 'shared/utils/stepsUtils'
import TwoColumnErrorPage from 'shared/ErrorPages/TwoColumnErrorPage'
import useRenderStepContext from 'shared/ScanLoggerQrCodeStepTemplate/useRenderStepContext'
import ScanLoggerQrCodeStepTemplate from 'shared/ScanLoggerQrCodeStepTemplate'
import {
    initializeState,
    ACTIONS,
} from 'shared/ScanLoggerQrCodeStepTemplate/utils'

import { useEvidenceContext } from 'App/Root/ProcessPage/EvidenceContextProvider'
import useEvidence from 'App/Root/ProcessPage/hooks/useEvidence'

import reducer, { VIEWTYPES } from './LoggerRemovalStep.reducer'

const propTypes = {
    step: PropTypes.shape({
        stepName: PropTypes.string.isRequired,
        stepTitle: PropTypes.string.isRequired,
        loggerType: PropTypes.string.isRequired,
        isAnswered: PropTypes.bool,
        isRequired: PropTypes.bool.isRequired,
        userInput: PropTypes.shape({ loggerNumber: PropTypes.string }),
    }).isRequired,
    isSummaryView: PropTypes.bool,
    isNested: PropTypes.bool,
    inputCallback: PropTypes.func,
}

const defaultProps = {
    isSummaryView: false,
    isNested: false,
    inputCallback: undefined,
}

const LoggerRemoval = ({
    step,
    isSummaryView,
    isNested,
    inputCallback,
}) => {
    const { t } = useTranslation()

    const [
        state,
        dispatch,
    ] = useReducer(reducer, { step }, initializeState)

    const { evidence } = useEvidenceContext()
    const { data: evidenceData } = useEvidence(evidence?.evidenceId)

    const onRetry = useCallback(() => {
        inputCallback(createInputObject(null, true), step.stepName)

        dispatch({ type: ACTIONS.RETRY })
    }, [
        inputCallback,
        step.stepName,
    ])

    const defaultContextRender = useRenderStepContext({
        step,
        state,
        ViewTypes: VIEWTYPES,
        Actions: ACTIONS,
        inputCallback,
        dispatch,
    })

    const stepContextRender = useMemo(() => {
        if (state.mode === VIEWTYPES.NOT_LINKED) {
            const texts = [{
                label: '',
                value: '',
            }]

            return (
                <TwoColumnErrorPage
                    iconPath="skycenter/error_sorry.svg"
                    iconAlt="error"
                    buttonLabel={t('RETRY')}
                    onButtonClick={onRetry}
                    buttonDataTestId="retry-button"
                    messageTop={t('TIVE_LOGGER_NOT_LINKED_TO_CONTAINER')}
                    messageBottom={t('CONTACT_SKYCELL')}
                    middleTexts={texts}
                />
            )
        }
        return defaultContextRender
    }, [
        defaultContextRender,
        onRetry,
        t,
        state.mode,
    ])

    return (
        <ScanLoggerQrCodeStepTemplate
            step={step}
            isSummaryView={isSummaryView}
            isNested={isNested}
            inputCallback={inputCallback}
            stepContextRender={stepContextRender}
            ViewTypes={VIEWTYPES}
            Actions={ACTIONS}
            state={state}
            dispatch={dispatch}
            evidenceData={evidenceData}
            needPairRequest
        />
    )
}

LoggerRemoval.propTypes = propTypes
LoggerRemoval.defaultProps = defaultProps

export default LoggerRemoval
