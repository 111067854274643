import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        root: theme.custom.dashBoardRoot,
        main: { width: '100%' },
    }
})

export default useStyles
