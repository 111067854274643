import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        countableInfo: { display: 'inline-flex' },
        countIndicator: {
            marginLeft: 8,
            background: theme.palette.secondary.dark,
            color: theme.palette.common.white,
            borderRadius: 5,
            padding: '0 5px',
        },

    }
})

export default useStyles
