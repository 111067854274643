/* eslint-disable complexity */
import React, {
    useReducer,
    useCallback,
    useMemo,
} from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { createInputObject } from 'shared/utils/stepsUtils'
import useRenderStepContext from 'shared/ScanLoggerQrCodeStepTemplate/useRenderStepContext'
import ScanLoggerQrCodeStepTemplate from 'shared/ScanLoggerQrCodeStepTemplate'
import TwoColumnErrorPage from 'shared/ErrorPages/TwoColumnErrorPage'
import { useEvidenceContext } from 'App/Root/ProcessPage/EvidenceContextProvider'
import useEvidence from 'App/Root/ProcessPage/hooks/useEvidence'
import {
    initializeState,
    ACTIONS,
} from 'shared/ScanLoggerQrCodeStepTemplate/utils'

import reducer, { VIEWTYPES } from './TiveInstallationStep.reducer'

const propTypes = {
    step: PropTypes.shape({
        stepName: PropTypes.string.isRequired,
        stepTitle: PropTypes.string.isRequired,
        isAnswered: PropTypes.bool,
        isRequired: PropTypes.bool.isRequired,
        userInput: PropTypes.shape({ loggerNumber: PropTypes.string }),
    }).isRequired,
    isSummaryView: PropTypes.bool,
    isNested: PropTypes.bool,
    inputCallback: PropTypes.func,
}

const defaultProps = {
    isSummaryView: false,
    isNested: false,
    inputCallback: undefined,
}

const TiveInstallation = ({
    step,
    isSummaryView,
    isNested,
    inputCallback,
}) => {
    const { t } = useTranslation()
    const [
        state,
        dispatch,
    ] = useReducer(reducer, { step }, initializeState)

    const { evidence } = useEvidenceContext()
    const { data: evidenceData } = useEvidence(evidence?.evidenceId)

    const onRetry = useCallback(() => {
        inputCallback(createInputObject(null, true), step.stepName)

        dispatch({ type: ACTIONS.RETRY })
    }, [
        inputCallback,
        step.stepName,
    ])

    const defaultContextRender = useRenderStepContext({
        step,
        state,
        ViewTypes: VIEWTYPES,
        Actions: ACTIONS,
        inputCallback,
        dispatch,
    })

    const stepContextRender = useMemo(() => {
        if (state.mode === VIEWTYPES.LINKED_ANOTHER
            || state.mode === VIEWTYPES.LINKED_TO_THIS_ALREADY) {
            const middleTexts = state.mode === VIEWTYPES.LINKED_ANOTHER ? [{
                label: `${t('CONTAINER')}: `,
                value: state.loggerData?.containerSerialNumber,
            }] : [{
                label: '',
                value: '',
            }]

            const messageTopKey = state.mode === VIEWTYPES.LINKED_ANOTHER
                ? 'TIVE_LOGGER_LINKED_ANOTHER_CONTAINER' : 'TIVE_LOGGER_ALREADY_LINKED_CONTAINER'

            const messageBottomKey = state.mode === VIEWTYPES.LINKED_ANOTHER ? 'CONTACT_SKYCELL' : 'CONTACT_SKYCELL_LOGGER'

            return (
                <TwoColumnErrorPage
                    iconPath="skycenter/error_sorry.svg"
                    iconAlt="error"
                    buttonLabel={t('RETRY')}
                    onButtonClick={onRetry}
                    buttonDataTestId="retry-button"
                    messageTop={t(messageTopKey)}
                    messageBottom={t(messageBottomKey)}
                    middleTexts={middleTexts}
                />
            )
        }
        return defaultContextRender
    }, [
        defaultContextRender,
        onRetry,
        t,
        state.mode,
        state.loggerData,
    ])

    return (
        <ScanLoggerQrCodeStepTemplate
            step={step}
            isSummaryView={isSummaryView}
            isNested={isNested}
            inputCallback={inputCallback}
            stepContextRender={stepContextRender}
            ViewTypes={VIEWTYPES}
            Actions={ACTIONS}
            state={state}
            dispatch={dispatch}
            evidenceData={evidenceData}
        />
    )
}

TiveInstallation.propTypes = propTypes
TiveInstallation.defaultProps = defaultProps

export default TiveInstallation
