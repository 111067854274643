import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import common from 'shared/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        main: {
            ...common.fullContainer,
            display: 'flex',
            justifyContent: 'space-evenly',
            boxSizing: 'border-box',
            flexDirection: 'column',
            alignItems: 'center',
            flexWrap: 'nowrap',
            padding: '30px 60px',
        },
        image: {
            width: 390,
            height: 230,
        },
        message: {
            ...theme.custom.messageText,
            fontWeight: theme.typography.fontWeightRegular,
        },
        button: {
            ...theme.custom.button,
            padding: 0,
        },
    }
})

export default useStyles
