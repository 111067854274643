import React, { useMemo } from 'react'
import { Loading } from '@skycell-ag/shared-components'
import { Link } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useLocationContext } from '../Location'
import useReadyToShip from './hooks/useReadyToShip'

import NoOrders from './NoOrders'
import Shipment from './Shipment'
import useStyles from './Orders.style'

const Orders = () => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { location } = useLocationContext()
    const {
        data,
        isLoading,
        error,
        isStale,
        isFetching,
    } = useReadyToShip(location.locationId)

    if (error) {
        throw error
    }

    const items = useMemo(() => {
        return data
    }, [data])

    const dataReady = useMemo(() => {
        return !isLoading && (isStale && !isFetching)
    }, [
        isLoading,
        isStale,
        isFetching,
    ])

    if (items && items.length === 0) {
        return (<NoOrders />)
    }

    return (
        <div className={classes.root}>
            <div className={classes.main}>
                { items && (
                    items.map((item) => {
                        return (
                            <Shipment
                                key={item.id}
                                orderNumber={item.orderNumber}
                                orderStatus={item.orderStatus}
                                plannedPickup={item.plannedPickup}
                                containers={item.containers}
                            />
                        )
                    })
                )}
                { !dataReady && (<Loading />)}
                <div className={classes.bottom}>
                    <Button
                        data-testid="back-button"
                        component={Link}
                        to="/mainDashboard"
                        className={classes.button}
                        startIcon={<ArrowBackIcon />}
                    >
                        {t('BACK')}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Orders
