import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => {
    return {
        icon: {
            width: 24,
            height: 24,
            paddingRight: 10,
        },
        validMessageContainer: { padding: '0 20px' },
        messageContainer: {
            display: 'flex',
            padding: '10px 10px',
        },
        notValidBackground: { backgroundColor: '#FFE5E5' },
        validBackground: { backgroundColor: '#EAF9EF' },
        messageText: {
            fontSize: '16px',
            lineHeight: '20px',
            paddingTop: '2px',
        },
        validMessageText: { color: '#17921F' },
        notValidMessageText: { color: '#B92C2C' },
    }
})

export default useStyles
