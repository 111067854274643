import React, {
    useCallback,
    useMemo,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Button } from '@mui/material'

import useStyles from './CartaScanResult.style'

const propTypes = {
    scanResult: PropTypes.string,
    onConfirm: PropTypes.func.isRequired,
    onRetry: PropTypes.func.isRequired,
}

const defaultProps = { scanResult: '' }

const CartaScanResult = ({
    scanResult,
    onConfirm,
    onRetry,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const [
        viewActions,
        setViewActions,
    ] = useState(true)

    const handleConfirm = useCallback(() => {
        onConfirm()
        setViewActions(false)
    }, [
        onConfirm,
        setViewActions,
    ])

    const validate = useCallback((v) => {
        const inputRegexp = new RegExp(/\b\d{6}\b|\b\d{10}\b/)

        if (v.match(inputRegexp)) {
            return true
        }
        return false
    }, [])

    const errorText = useMemo(() => {
        if (scanResult && !validate(scanResult)) {
            return t('CARTASENSE_BARCODE_VALIDATION_MESSAGE', { barCode: scanResult })
        }
        return null
    }, [
        scanResult,
        validate,
        t,
    ])

    return (
        <div className={classes.result}>
            {scanResult && !errorText && (
                <div>
                    {`${t('SELECTED_CARTA_LOGGER')} ${scanResult}` }
                </div>
            )}
            { errorText && (
                <>
                    <div>
                        {errorText}
                    </div>
                    <div className={classes.error}>
                        {t('CARTASENSE_BARCODE_MANUAL_VALIDATION_MESSAGE')}
                    </div>
                </>
            )}

            {viewActions && (
                <div className={classes.actions}>
                    {!errorText && (
                        <Button
                            data-testid="confirm-button"
                            className={classes.button}
                            onClick={handleConfirm}
                        >
                            {t('CONFIRM')}
                        </Button>
                    )}
                    <Button
                        data-testid="retry-button"
                        className={classes.button}
                        onClick={onRetry}
                    >
                        {t('RETRY')}
                    </Button>
                </div>
            )}
        </div>
    )
}

CartaScanResult.propTypes = propTypes
CartaScanResult.defaultProps = defaultProps

export default CartaScanResult
