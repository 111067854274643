import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import common from 'shared/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        root: {
            ...common.fullContainer,
            display: 'flex',
            flexDirection: 'column',
        },
        main: {
            ...theme.custom.dashBoardRoot,
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
        },
        userLocationTitle: {
            paddingBottom: 30,
            alignSelf: 'baseline',
            width: '100%',
        },
        userLocationText: {
            borderBottom: `1px solid ${theme.palette.primary.main}`,
            color: theme.typography.h3.color,
        },
        container: {
            ...common.fullContainer,
            display: 'flex',
            flexGrow: 1,
            alignItems: 'stretch',
            flexDirection: 'column',
            borderTop: `1px solid ${theme.palette.secondary[50]}`,
        },
        center: {
            ...theme.custom.border,
            display: 'flex',
            cursor: 'pointer',
            padding: '20px 40px',
            borderBottom: `1px solid ${theme.palette.secondary[50]}`,
            boxShadow: '1px 1px 1px #00000033',

        },
        centerText: {
            ...theme.custom.messageText,
            font: 'normal normal normal 26px/32px Roboto',
        },
    }
})

export default useStyles
