import React, {
    useState,
    useCallback,
} from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

import TextStepField from 'shared/TextStepField'
import { createInputObject } from 'shared/utils/stepsUtils'
import useSupportNestedSteps from 'shared/NestedStep/useSupportNestedSteps'

const propTypes = {
    step: PropTypes.shape({
        stepName: PropTypes.string.isRequired,
        userInput: PropTypes.shape({ answerText: PropTypes.string }),
        isRequired: PropTypes.bool.isRequired,
    }).isRequired,
    inputCallback: PropTypes.func,
    isNested: PropTypes.bool,
    isSummaryView: PropTypes.bool,
    nestedInputFromUserInput: PropTypes.func,
}
const defaultProps = {
    isNested: false,
    isSummaryView: false,
    inputCallback: undefined,
    nestedInputFromUserInput: undefined,
}

const createUserInput = (value) => {
    return createInputObject({ answerText: value }, true)
}
const inputConverter = (userInput) => {
    if (userInput?.answerText) {
        return createUserInput(userInput.answerText)
    }
    return undefined
}

const TextStep = ({
    step,
    inputCallback,
    isNested,
    isSummaryView,
    nestedInputFromUserInput,
}) => {
    const initValue = step.userInput ? step.userInput.answerText : ''
    const [
        value,
        setValue,
    ] = useState(initValue)
    const onChange = useCallback((event) => {
        if (inputCallback) {
            const newInputValue = event.target.value

            let input = createUserInput(event.target.value)

            if (isEmpty(newInputValue)) {
                input = createInputObject(null, !step.isRequired)
            }

            inputCallback(input, step.stepName)
        }
        setValue(event.target.value)
    }, [
        inputCallback,
        step.stepName,
        step.isRequired,
    ])

    useSupportNestedSteps({
        step,
        isSummaryView,
        isNested,
        nestedInputFromUserInput,
        inputConverter,
    })

    return (
        <TextStepField
            step={step}
            value={value}
            onChange={onChange}
            isNested={isNested}
            isSummaryView={isSummaryView}
        />
    )
}

TextStep.propTypes = propTypes
TextStep.defaultProps = defaultProps

export default TextStep
