const addHostURL = (host) => {
    return (cb) => {
        return ({
            url,
            version = 'v1',
            ...rest
        }) => {
            const hostVersion = `${host}/${version}`

            if (!url) {
                return cb({
                    ...rest,
                    url: hostVersion,
                })
            }

            return cb({
                ...rest,
                url: `${hostVersion}/${url}`,
            })
        }
    }
}

export default addHostURL
