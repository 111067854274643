import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import common from 'shared/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        loaderDiv: {
            display: 'flex',
            flexDirection: 'column',
        },
        button: {
            ...theme.custom.button,
            width: 'auto',
            margin: '20px 25px',
            padding: '10px 20px',
        },
        actions: {
            ...common.flexJustityAlignCenter,
            padding: 0,
        },
        timeOutLoader: {
            position: 'relative',
            padding: '20px 0',
        },
        stateText: { padding: 20 },
        centeredText: {
            padding: 20,
            alignSelf: 'center',
        },
    }
})

export default useStyles
