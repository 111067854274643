import React, {
    useCallback,
    useState,
    useMemo,
} from 'react'
import PropTypes from 'prop-types'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import ErrorFallbackWithHistory from './ErrorFallbackWithHistory'

import useStyles from './MainMenuErrorBoundary.style'

const propTypes = { children: PropTypes.element.isRequired }

const initErrorInfo = {
    error: undefined,
    info: undefined,
}

const MainMenuErrorBoundary = ({ children }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [
        errorInfo,
        setErrorInfo,
    ] = useState(initErrorInfo)

    const onErrorHandler = useCallback((error, info) => {
        setErrorInfo({
            error,
            info,
        })
    }, [])

    const errorLogs = useMemo(() => {
        return errorInfo.error ? errorInfo.error.stack || errorInfo.info.componentStack : undefined
    }, [errorInfo])

    const onResethandler = useCallback(() => {
        setErrorInfo(initErrorInfo)
    }, [])

    return (
        <div className={classes.root}>
            <ErrorBoundary
                FallbackComponent={ErrorFallbackWithHistory}
                onError={onErrorHandler}
                onReset={onResethandler}
            >
                {children}
            </ErrorBoundary>
            {errorInfo.error && (
                <Accordion className={classes.accordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{t('ERROR_DETAILS')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography
                            variant="body2"
                            className={classes.stack}
                        >
                            {errorLogs}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            )}
        </div>
    )
}

MainMenuErrorBoundary.propTypes = propTypes

export default MainMenuErrorBoundary
