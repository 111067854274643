import keycloakService from './keycloakService'

const getRoles = (token) => {
    return keycloakService({
        method: 'GET',
        url: 'user/roles',
        token,
    }).then((data) => {
        return data.map(({ name }) => {
            return name
        })
    })
}

export default getRoles
