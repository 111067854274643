import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        debug: process.env.NODE_ENV === 'development',
        returnEmptyString: false,
        fallbackLng: 'en',
        lng: 'en',
        supportedLngs: [
            'en',
            'de',
            'it',
            'ja',
            'zh-CN',
            'ko',
            'es',
            'pl',
            'ru',
            'fr',
        ],
        interpolation: { escapeValue: false },
        ns: [
            'translation',
            'processDefinition',
        ],
    })

export default i18n
