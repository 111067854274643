import { useMemo } from 'react'
import useAuthContext from './useAuthContext'

const useUserRoles = () => {
    const { userInfo } = useAuthContext()

    const roles = useMemo(() => {
        return (userInfo && userInfo.roles) || []
    }, [userInfo])

    return roles
}

export default useUserRoles
