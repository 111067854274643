import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import {
    Typography,
    Button,
    DialogContent,
    DialogActions,
    Dialog,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import getGlobalAssetUrl from 'shared/utils/getGlobalAssetUrl'
import useStyles from './ScanErrorDialog.style'

const propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    item: PropTypes.shape({ number: PropTypes.string.isRequired }),
}

const defaultProps = { item: null }

const ScanErrorDialog = ({
    onClose,
    open,
    item,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const handleCancel = () => {
        onClose()
    }

    const errorMessage = useMemo(() => {
        if (!item) return ''

        return t('WRONG_CONTAINER', { serialNumber: item.number })
    }, [
        item,
        t,
    ])

    return (
        <Dialog
            id="barcode-scan-error-dialog"
            keepMounted
            fullWidth
            maxWidth="sm"
            aria-labelledby="barcode-scan-error-dialog"
            open={open}
            classes={{ root: classes.dialogRoot }}
        >
            <DialogContent classes={{ root: classes.dialogContentRoot }}>
                <img
                    alt="logo"
                    src={getGlobalAssetUrl('icons/warning.svg')}
                    className={classes.icon}
                />
                <Typography
                    variant="h3"
                >
                    {errorMessage}
                </Typography>
            </DialogContent>
            <DialogActions
                classes={{ root: classes.dialogActionsRoot }}
            >
                <Button
                    data-testid="ok-button"
                    className={classes.button}
                    onClick={handleCancel}
                >
                    {t('OK')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ScanErrorDialog.propTypes = propTypes
ScanErrorDialog.defaultProps = defaultProps

export default ScanErrorDialog
