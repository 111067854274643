import React from 'react'
import { PropTypes } from 'prop-types'
import { Dialog } from '@mui/material'

import QRScannerView from './QRScannerView'
import useStyles from './QRCodeScanner.style'

const propTypes = {
    onScan: PropTypes.func,
    onCancel: PropTypes.func,
    isBarCodeScan: PropTypes.bool,
    scanMode: PropTypes.bool,
}

const defaultProps = {
    onScan: undefined,
    onCancel: undefined,
    scanMode: false,
    isBarCodeScan: false,
}

const QRCodeScanner = ({
    onScan,
    onCancel,
    isBarCodeScan,
    scanMode,
}) => {
    const classes = useStyles()

    return (
        <Dialog
            fullScreen
            open={scanMode}
        >
            { scanMode && (
                <div className={classes.root}>
                    <QRScannerView
                        onScan={onScan}
                        onCancel={onCancel}
                        isBarCodeScan={isBarCodeScan}
                    />
                </div>
            )}
        </Dialog>
    )
}

QRCodeScanner.propTypes = propTypes
QRCodeScanner.defaultProps = defaultProps

export default QRCodeScanner
