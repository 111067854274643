import React from 'react'
import PropTypes from 'prop-types'
import SummaryChoicesItem from './SummaryChoicesItem'

import useStyles from './SummaryChoicesList.style'

const propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        choiceLabel: PropTypes.string.isRequired,
        choiceIdentifier: PropTypes.string.isRequired,
    })).isRequired,
    choice: PropTypes.string.isRequired,
    isAnswered: PropTypes.bool,
    children: PropTypes.node,
}
const defaultProps = {
    children: undefined,
    isAnswered: false,
}

const SummaryChoicesList = ({
    items,
    choice,
    isAnswered,
    children,
}) => {
    const classes = useStyles()

    return (
        <div className={classes.summaryView}>
            {items.map((item) => {
                return (
                    <div key={`list-${item.choiceIdentifier}`}>
                        <SummaryChoicesItem
                            choice={choice}
                            choiceLabel={item.choiceLabel}
                            choiceIdentifier={item.choiceIdentifier}
                            isAnswered={isAnswered}
                        />
                        {choice === item.choiceIdentifier && children}
                    </div>
                )
            })}
        </div>
    )
}

SummaryChoicesList.propTypes = propTypes
SummaryChoicesList.defaultProps = defaultProps

export default SummaryChoicesList
