export const actions = {
    OPEN_CAMERA: 'OPEN_CAMERA',
    CAPTURE_PICTURE: 'CAPTURE_PICTURE',
    ACCEPT_PICTURE: 'ACCEPT_PICTURE',
}

export const initState = {
    init: true,
    openCamera: false,
    acceptPicture: false,
    src: null,
}

const reducer = (state, action) => {
    switch (action.type) {
    case actions.OPEN_CAMERA:
        return {
            ...state,
            init: false,
            openCamera: true,
            acceptPicture: false,
            src: null,
        }
    case actions.CAPTURE_PICTURE:
        return {
            ...state,
            openCamera: false,
            acceptPicture: true,
            src: action.src,
        }

    case actions.ACCEPT_PICTURE:
    {
        return {
            ...state,
            acceptPicture: false,
        }
    }
    default:
        throw new Error(`Not handled action type for TakeAcceptPicture component = ${action.type}`)
    }
}

export default reducer
