import {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import groupBy from 'lodash/groupBy'
import isUndefined from 'lodash/isUndefined'

import { needJumpToSummaryByInput } from 'shared/utils/stepsUtils'
import { StepsUserInputPropNames } from 'App/Root/ProcessPage/evidenceConstants'
import { useEvidenceContext } from '../EvidenceContextProvider'
import useSaveProcess from './useSaveProcess'
import useProcessData from './useProcessData'
import useCancelProcess from './useCancelProcess'
import useSaveAttachments from './useSaveAttachments'
import useCompleteProcess from './useCompleteProcess'

const prepareImagesToSave = (attachmentSteps, stepsInput) => {
    const picturesForSave = []

    attachmentSteps.forEach((step) => {
        const newAttachments = stepsInput[step.stepName].attachments.filter((attachment) => {
            return isUndefined(attachment.id)
        })

        newAttachments.forEach((image) => {
            picturesForSave.push({
                stepName: step.stepName,
                file: image.src,
                fileName: image.fileName,
                description: image.description,
                inputPropName: stepsInput[step.stepName].inputPropName,
            })
        })
    })
    return picturesForSave
}

const updateStepsInputWithPicturesIDs = (attachmentsData, stepsInput, picturesSteps) => {
    const groupedAttachmentData = groupBy(attachmentsData, 'stepName')

    return picturesSteps.reduce((input, step) => {
        const savedAttachments = groupedAttachmentData[step.stepName]

        let newInputValue = {}

        if (step.stepType === 'PICTURE_MULTIPLE') {
            const allAttachments = stepsInput[step.stepName].attachments.map((attach) => {
                if (isUndefined(attach.id)) {
                    const savedFile = savedAttachments.find((at) => {
                        return at.file === attach.src
                    })

                    return { id: savedFile.id }
                }
                return { id: attach.id }
            })

            newInputValue = { [StepsUserInputPropNames.PICTURE_MULTIPLE]: allAttachments }
        } else {
            let pictureId

            if (!isUndefined(stepsInput[step.stepName].attachments[0].id)) {
                pictureId = stepsInput[step.stepName].attachments[0].id
            } else {
                pictureId = savedAttachments[0].id
            }
            newInputValue = { [StepsUserInputPropNames.PICTURE]: { id: pictureId } }
        }
        return {
            ...input,
            [step.stepName]: { value: newInputValue },
        }
    }, stepsInput)
}

const prepareUserInputForPicturesSteps = async (currentGroup, stepsInput, saveAttachment) => {
    const picturesSteps = currentGroup.steps.filter((step) => {
        return stepsInput[step.stepName] && stepsInput[step.stepName].isPicture
    })

    let stepsInputForSave = { ...stepsInput }

    if (picturesSteps.length > 0) {
        const picturesForSave = prepareImagesToSave(picturesSteps, stepsInput)

        const attachmentsData = await saveAttachment.mutateAsync(picturesForSave)

        stepsInputForSave = updateStepsInputWithPicturesIDs(
            attachmentsData,
            stepsInput,
            picturesSteps,
        )
    }
    return stepsInputForSave
}

const prepareProcessGroups = (groups, currentGroup, stepsInputForSave) => {
    const hasJumpToSummary = needJumpToSummaryByInput(currentGroup, stepsInputForSave)

    return groups.map((group, index) => {
        if (hasJumpToSummary && index > currentGroup.progressGroup - 1) {
            return {
                ...group,
                steps: group.steps.map((step) => {
                    if (step.isAnswered) {
                        return {
                            ...step,
                            userInput: null,
                        }
                    }
                    return step
                }),
            }
        }
        if (group.progressGroup !== currentGroup.progressGroup) {
            return group
        }
        const newSteps = group.steps.map((step) => {
            if (stepsInputForSave[step.stepName]) {
                return {
                    ...step,
                    userInput: stepsInputForSave[step.stepName].value,
                }
            }
            return step
        })

        return {
            ...group,
            steps: newSteps,
        }
    })
}

const flattenNestedInput = (oldInput, inputWithNested) => {
    return Object.keys(inputWithNested.nested).reduce((input, stepName) => {
        const nestedInputValue = inputWithNested.nested[stepName]

        let newInput = {
            ...input,
            [stepName]: nestedInputValue,
        }

        if (nestedInputValue.nested) {
            newInput = flattenNestedInput(newInput, nestedInputValue)
        }

        return newInput
    }, oldInput)
}

const prepareUserInputForNestedsSteps = (currentGroup, stepsInput) => {
    let newInputsForMainSteps = { ...stepsInput }

    const stepsToClearUserInput = currentGroup.steps.filter((step) => {
        return stepsInput[step.stepName] && stepsInput[step.stepName].needRemove
    })

    if (stepsToClearUserInput.length > 0) {
        newInputsForMainSteps = stepsToClearUserInput.reduce((input, step) => {
            return {
                ...input,
                [step.stepName]: { value: null },
            }
        }, newInputsForMainSteps)
    }

    const stepsWithNested = currentGroup.steps.filter((step) => {
        return stepsInput[step.stepName] && stepsInput[step.stepName].nested
    })

    if (stepsWithNested.length > 0) {
        newInputsForMainSteps = stepsWithNested.reduce((input, step) => {
            return flattenNestedInput(input, stepsInput[step.stepName])
        }, newInputsForMainSteps)
    }
    return newInputsForMainSteps
}

function useProcessEvidence({
    processKey,
    assetNumber,
    directScan,
    cancelCallback,
}) {
    const {
        evidence,
        saveEvidence,
    } = useEvidenceContext()
    const {
        data: {
            processTemplate,
            progressGroups,
            processStatus,
            evidenceId,
            changedBy,
        },
        isLoading: processDataLoading,
        error: processDataError,
    } = useProcessData({
        processKey,
        assetNumber,
        directScan,
    })
    const saveProcess = useSaveProcess()
    const cancelProcess = useCancelProcess()
    const completeProcess = useCompleteProcess()
    const saveAttachment = useSaveAttachments()
    const [
        saving,
        setSaving,
    ] = useState(false)

    const error = useMemo(() => {
        return processDataError || saveProcess.error || cancelProcess.error
    }, [
        processDataError,
        saveProcess.error,
        cancelProcess.error,
    ])
    const isLoading = useMemo(() => {
        return processDataLoading || saving
    }, [
        processDataLoading,
        saving,
    ])

    useEffect(() => {
        if (evidenceId) {
            saveEvidence({ evidenceId })
        }
    }, [
        evidenceId,
        saveEvidence,
    ])

    const saveGroup = useCallback(async (groups, currentGroup, stepsInput) => {
        setSaving(true)

        const newInputsForMainSteps = prepareUserInputForNestedsSteps(currentGroup, stepsInput)
        const stepsInputForSave = await prepareUserInputForPicturesSteps(currentGroup,
            newInputsForMainSteps,
            saveAttachment)

        let newProcessGroupsState = prepareProcessGroups(groups, currentGroup, stepsInputForSave)

        const saveResposeData = await saveProcess.mutateAsync({
            processTemplate,
            groups: newProcessGroupsState,
            processId: evidence.evidenceId,
            processGroupId: currentGroup.id,
            currentGroup,
        }, { onSuccess: (data) => {
            saveEvidence({ evidenceId: data.id })
        } })

        if (saveResposeData.progressGroups) {
            newProcessGroupsState = saveResposeData.progressGroups
        }

        setSaving(false)
        return {
            processStatus: saveResposeData.processStatus,
            progressGroups: newProcessGroupsState,
        }
    }, [
        saveAttachment,
        processTemplate,
        saveProcess,
        evidence.evidenceId,
        saveEvidence,
    ])

    const cancel = useCallback(() => {
        cancelProcess.mutate(
            { processId: evidence.evidenceId },
            { onSuccess: () => {
                saveEvidence({ evidenceId: null })
                cancelCallback()
            } },
        )
    }, [
        cancelProcess,
        evidence.evidenceId,
        cancelCallback,
        saveEvidence,
    ])

    const complete = useCallback(() => {
        setSaving(true)
        completeProcess.mutate(
            { processId: evidence.evidenceId },
            { onSuccess: () => {
                setSaving(false)
                saveEvidence({ evidenceId: null })
                cancelCallback()
            } },
        )
    }, [
        completeProcess,
        evidence.evidenceId,
        cancelCallback,
        saveEvidence,
    ])

    return {
        progressGroups,
        processStatus,
        saveGroup,
        cancel,
        complete,
        isLoading,
        error,
        changedBy,
    }
}

export default useProcessEvidence
