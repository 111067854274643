import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
    TextField,
    IconButton,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

import { useTextFieldStyles } from 'shared/TextStepField/TextStepField.style'
import PictureLayout from 'shared/PictureLayout/PictureLayout'
import useStyles from './CommentPicture.style'

const propTypes = {
    image: PropTypes.string.isRequired,
    id: PropTypes.number,
    step: PropTypes.shape({
        isCommentable: PropTypes.bool.isRequired,
        isRequired: PropTypes.bool.isRequired,
        isAnswered: PropTypes.bool.isRequired,
    }).isRequired,
    comment: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    isNested: PropTypes.bool,
    isSummaryView: PropTypes.bool,
}
const defaultProps = {
    isNested: false,
    isSummaryView: false,
    id: undefined,
    comment: undefined,
}

const CommentPicture = ({
    image,
    id,
    step,
    comment,
    onChange,
    onDelete,
    isNested,
    isSummaryView,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const textFieldStyles = useTextFieldStyles()

    const onTextChange = useCallback((event) => {
        onChange(event.target.value)
    }, [onChange])

    return (

        <PictureLayout
            title={step.isCommentable ? t('ADD_COMMENT') : t('PICTURE')}
            image={image}
            isRequired={step.isRequired}
            isAnswered
            isNested={isNested}
            isSummaryView={isSummaryView}
        >
            <div
                className={classes.context}
            >
                { step.isCommentable ? (
                    <TextField
                        className={classes.text}
                        id="outlined-helperText"
                        value={comment}
                        fullWidth
                        multiline
                        rows={10}
                        size="small"
                        InputProps={{ classes: textFieldStyles }}
                        variant="outlined"
                        onChange={onTextChange}
                        disabled={Boolean(id)}
                    />
                ) : <div className={classes.emptyCommentAria} />}
                <IconButton
                    aria-label="delete"
                    data-testid="delete-picture-button"
                    size="medium"
                    className={classes.deleteButton}
                    onClick={onDelete}
                >
                    <DeleteIcon fontSize="large" />
                </IconButton>
            </div>
        </PictureLayout>

    )
}

CommentPicture.propTypes = propTypes
CommentPicture.defaultProps = defaultProps

export default CommentPicture
