import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        root: {
            padding: '20px 0px',
            width: '100%',
        },
        main: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'space-arround',
            flexDirection: 'column',
        },
        orderDiv: {
            marginBottom: 30,
            borderBottom: `1px solid ${theme.palette.primary.main}`,
            color: theme.typography.h3.color,
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        orderNumberDateDiv: {
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
        orderNumber: { paddingRight: 30 },
        containers: {
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        containerDiv: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridGap: 28,
            [theme.breakpoints.down('md')]: {
                gridTemplateColumns: '1fr',
                padding: `${theme.spacing(4)}px ${theme.spacing(0)}px`,
            },
            [theme.breakpoints.down('sm')]: { gridTemplateColumns: '1fr' },
            flexGrow: 2,
        },
        container: {
            ...theme.custom.boxShadowRadius,
            ...theme.custom.border,
            overflow: 'hidden',
            cursor: 'pointer',
            padding: '10px 8px 4px 12px',
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: 10,
            flexDirection: 'column',
        },
        containerFirtsRow: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        containerSecondRow: { paddingTop: 10 },
        confirmDiv: {
            marginLeft: 40,
            minWidth: 265,
        },
        button: {
            ...theme.custom.button,
            padding: '10px 20px',
            width: 'auto',
        },
        numberTypeContainer: { display: 'inline-flex' },
    }
})

export default useStyles
