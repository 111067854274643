import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { Button } from '@mui/material'
import Icon from '@mui/icons-material/Check'

import useStyles from './YesNoStep.style'

const propTypes = {
    choice: PropTypes.shape({
        choiceIdentifier: PropTypes.string.isRequired,
        choiceLabel: PropTypes.string.isRequired,
    }).isRequired,
    selected: PropTypes.bool,
    onChoice: PropTypes.func.isRequired,
    testId: PropTypes.string.isRequired,
}

const defaultProps = { selected: null }

const ChoiceButton = ({
    choice,
    selected,
    onChoice,
    testId,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()

    return (
        <Button
            name={choice.choiceIdentifier}
            data-testid={testId}
            className={clsx({
                [classes.button]: true,
                [classes.inactive]: selected !== null && !selected,
            })}
            onClick={onChoice}
            startIcon={selected ? <Icon /> : <></>}
        >
            {t(`processDefinition:${choice.choiceLabel}`)}
        </Button>
    )
}

ChoiceButton.propTypes = propTypes
ChoiceButton.defaultProps = defaultProps

export default ChoiceButton
