import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => {
    return {
        dialogContentRoot: { alignItems: 'stretch' },
        contextContainer: { height: 475 },
        contextButton: { margin: '10px 25px 10px 25px' },
    }
})

export default useStyles
