import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return { button: {
        ...theme.custom.button,
        padding: 0,
    } }
})

export default useStyles
