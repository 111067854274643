import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'

import { useAttachmentContext } from 'App/Root/ProcessPage/AttachmentContextProvider'

import PictureLayout from 'shared/PictureLayout/PictureLayout'

import useStyles from './PictureSummary.style'

const propTypes = {
    step: PropTypes.shape({
        stepTitle: PropTypes.string.isRequired,
        isAnswered: PropTypes.bool.isRequired,
        isRequired: PropTypes.bool.isRequired,
        userInput: PropTypes.shape({ picture: PropTypes.shape({
            attachmentUrl: PropTypes.string,
            description: PropTypes.string,
            id: PropTypes.number.isRequired,
        }) }),
    }).isRequired,
    isNested: PropTypes.bool,
}
const defaultProps = { isNested: false }

const PictureSummary = ({
    step,
    isNested,
}) => {
    const { t } = useTranslation()
    const { attachments } = useAttachmentContext()
    const classes = useStyles()
    const [state] = useState(() => {
        if (step.userInput?.picture) {
            const pictureData = step.userInput.picture

            const search = attachments.attachments.find((value) => {
                return value.id === pictureData.id
            })

            if (search) {
                return {
                    src: search.file,
                    comment: search.description,
                }
            }
            return {
                src: pictureData.attachmentUrl,
                comment: pictureData.description,
            }
        }
        return {}
    })

    return (
        <PictureLayout
            title={t(`processDefinition:${step.stepTitle}`)}
            image={state.src}
            isRequired={step.isRequired}
            isAnswered={step.isAnswered}
            isNested={isNested}
            isSummaryView
            classes={{ actions: classes.actions }}
        >
            { state.comment ? (
                <div className={classes.main}>
                    <Typography
                        variant="h3"
                        className={classes.description}
                        style={{ whiteSpace: 'pre-wrap' }}
                    >
                        {state.comment}
                    </Typography>
                </div>
            ) : null}
        </PictureLayout>
    )
}

PictureSummary.propTypes = propTypes
PictureSummary.defaultProps = defaultProps

export default PictureSummary
