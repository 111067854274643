import {
    useState,
    useCallback,
} from 'react'

import useSupportNestedSteps from 'shared/NestedStep/useSupportNestedSteps'

import {
    getSelectedChoices,
    prepareNestedInputObject,
    prepareInput,
    inputFormat,
    createMultiChoiceInputObject,
} from './utils'

const inputConverter = (userInput) => {
    if (userInput?.selectedChoiceIdentifiers?.length > 0) {
        return createMultiChoiceInputObject(
            inputFormat(userInput?.selectedChoiceIdentifiers),
            true,
            false,
        )
    }
    return undefined
}

function useNestedMultiChoice({
    step,
    curentGroupSteps,
    inputCallback,
    isSummaryView,
    isNested,
    nestedInputFromUserInput,
}) {
    const [
        choices,
        setChoices,
    ] = useState(() => {
        if (step.userInput?.selectedChoiceIdentifiers.length > 0) {
            return Object.fromEntries(step.userInput.selectedChoiceIdentifiers
                .map((c) => {
                    return [
                        c,
                        true,
                    ]
                }))
        }
        return {}
    })

    const [
        isChanged,
        setIsChanged,
    ] = useState(false)

    const [
        nestedInput,
        setNestedInput,
    ] = useState({})

    const onChoiceChange = useCallback((value, event) => {
        const newChoices = {
            ...choices,
            [event.target.name]: value,
        }
        const selectedChoices = getSelectedChoices(newChoices)

        const {
            input,
            nested,
        } = prepareInput(selectedChoices, nestedInput, curentGroupSteps, step)

        setChoices(newChoices)
        setNestedInput(nested)
        setIsChanged(true)

        if (inputCallback) {
            inputCallback(input, step.stepName)
        }
    }, [
        inputCallback,
        choices,
        step,
        curentGroupSteps,
        nestedInput,
    ])

    const nestedInputCallback = useCallback((input, stepName) => {
        const nested = {
            ...nestedInput,
            [stepName]: input,
        }
        const selectedChoices = getSelectedChoices(choices)
        const newInput = prepareNestedInputObject(selectedChoices, nested, curentGroupSteps, step)

        setNestedInput(nested)
        if (inputCallback) {
            inputCallback(newInput, step.stepName)
        }
    }, [
        inputCallback,
        step,
        curentGroupSteps,
        choices,
        nestedInput,
    ])

    useSupportNestedSteps({
        step,
        isSummaryView,
        isNested,
        nestedInputFromUserInput,
        inputConverter,
    })

    return {
        onChoiceChange,
        nestedInputCallback,
        choices,
        setNestedInput,
        isChanged,
    }
}

export default useNestedMultiChoice
