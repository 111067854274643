import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import common from 'shared/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        root: {
            ...theme.custom.dashBoardRoot,
            height: '100%',
        },
        main: {
            ...common.fullContainer,
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'column',
        },
    }
})

export default useStyles
