import React, {
    useEffect,
    useMemo,
} from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import TwoRowErrorPage from 'shared/ErrorPages/TwoRowErrorPage'
import ROUTE_KEYS from 'shared/utils/routeKeys'
import parseErrorMessage from 'shared/utils/parseErrorMessage'
import Layout from 'App/Root/Layout'

import NoAccessView from 'App/AccessProvider/NoAccessView'

import useStyles from './ErrorFallbackWithHistory.style'

const isAccessError = (error) => {
    return !!(error.isAxiosError && error.response.status === 403)
}

const propTypes = {
    error: PropTypes.shape({
        message: PropTypes.string,
        stack: PropTypes.string,
    }).isRequired,
    resetErrorBoundary: PropTypes.func.isRequired,
}

const ErrorFallbackWithHistory = ({
    error,
    resetErrorBoundary,
}) => {
    const { t } = useTranslation()
    const history = useHistory()
    const classes = useStyles()

    useEffect(() => {
        history.push(ROUTE_KEYS.MAIN)
    }, [history])

    const context = useMemo(() => {
        if (isAccessError(error)) {
            return (
                <NoAccessView />
            )
        }
        return (
            <div className={classes.root}>
                <TwoRowErrorPage
                    message={parseErrorMessage(error)}
                    iconPath="skycenter/not_working_robot.svg"
                    iconAlt="error"
                >
                    <Button
                        data-testid="ok-button"
                        className={classes.button}
                        onClick={resetErrorBoundary}
                    >
                        {t('OK')}
                    </Button>
                </TwoRowErrorPage>
            </div>
        )
    }, [
        error,
        t,
        classes,
        resetErrorBoundary,
    ])

    return (
        <Layout onClickMainMenu={resetErrorBoundary}>
            {context}
        </Layout>
    )
}

ErrorFallbackWithHistory.propTypes = propTypes

export default ErrorFallbackWithHistory
