import {
    useEffect,
    useState,
    useCallback,
} from 'react'

import isMediaDevicesAvailable from './isMediaDevicesAvailable'

const useEnumerateDevices = () => {
    const [
        device,
        setDevice,
    ] = useState(undefined)

    const getDevices = useCallback(() => {
        navigator.mediaDevices
            .enumerateDevices()
            .then((devicesInfo) => {
                const videoDevicesAmount = devicesInfo.filter((deviceInfo) => {
                    return deviceInfo.kind === 'videoinput'
                }).length

                if (videoDevicesAmount === 0) {
                    setDevice(null)
                    return
                }
                const video = videoDevicesAmount >= 2 ? { facingMode: 'environment' } : { facingMode: 'user' }

                setDevice(video)
            })
    }, [])

    useEffect(() => {
        if (isMediaDevicesAvailable()) {
            getDevices()
        }
    }, [getDevices])

    return device
}

export default useEnumerateDevices
