import React, {
    useMemo,
    useCallback,
} from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import StepPageLayout from 'shared/StepPageLayout'
import NestedStep, { useNestedStep } from 'shared/NestedStep'
import SummaryChoicesList from 'shared/SummaryChoicesList'
import { StepSingleSelectPropTypes } from 'shared/utils/stepsUtils'

import ChoiceButton from './ChoiceButton'
import useStyles from './YesNoStep.style'

const YES_IDENTIFIER = 'YES'
const NO_IDENTIFIER = 'NO'

const propTypes = {
    step: StepSingleSelectPropTypes.isRequired,
    inputCallback: PropTypes.func,
    assetNumber: PropTypes.string.isRequired,
    curentGroupSteps: PropTypes.arrayOf(
        PropTypes.shape({ stepName: PropTypes.string.isRequired }),
    ).isRequired,
    isNested: PropTypes.bool,
    isSummaryView: PropTypes.bool,
    nestedInputFromUserInput: PropTypes.func,
}
const defaultProps = {
    isNested: false,
    inputCallback: undefined,
    isSummaryView: false,
    nestedInputFromUserInput: undefined,
}

const YesNoStep = ({
    step,
    inputCallback,
    assetNumber,
    curentGroupSteps,
    isNested,
    isSummaryView,
    nestedInputFromUserInput,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const {
        choice,
        isChanged,
        onChange,
    } = useNestedStep({
        step,
        curentGroupSteps,
        inputCallback,
        isNested,
        isSummaryView,
        nestedInputFromUserInput,
    })

    const onChoice = useCallback((event) => {
        const value = event.currentTarget.name

        onChange(value)
    }, [onChange])

    const yesChoice = useMemo(() => {
        return step.choices.find((s) => {
            return s.choiceIdentifier === YES_IDENTIFIER
        })
    }, [step.choices])
    const noChoice = useMemo(() => {
        return step.choices.find((s) => {
            return s.choiceIdentifier === NO_IDENTIFIER
        })
    }, [step.choices])

    const buttonsRender = () => {
        return (
            <>
                <div className={classes.buttons}>
                    <ChoiceButton
                        choice={yesChoice}
                        onChoice={onChoice}
                        selected={choice ? choice === yesChoice.choiceIdentifier : null}
                        testId="yes-button"
                    />
                    <ChoiceButton
                        choice={noChoice}
                        onChoice={onChoice}
                        selected={choice ? choice === noChoice.choiceIdentifier : null}
                        testId="no-button"
                    />
                </div>
                <React.Fragment key={`${choice}-child`}>
                    <NestedStep
                        step={step}
                        choice={choice}
                        assetNumber={assetNumber}
                        inputCallback={inputCallback}
                        curentGroupSteps={curentGroupSteps}
                        isChanged={isChanged}
                        nestedInputFromUserInput={nestedInputFromUserInput}
                    />
                </React.Fragment>
            </>
        )
    }

    const buttons = useMemo(() => {
        return [
            yesChoice,
            noChoice,
        ]
    }, [
        yesChoice,
        noChoice,
    ])

    return (
        <StepPageLayout
            title={t(`processDefinition:${step.stepTitle}`)}
            isRequired={step.isRequired}
            isAnswered={!!choice}
            isNested={isNested}
            isSummaryView={isSummaryView}
        >
            <div className={classes.form}>
                {isSummaryView ? (
                    <SummaryChoicesList
                        items={buttons}
                        choice={choice}
                        isAnswered={!!choice}
                        step={step}
                        assetNumber={assetNumber}
                        curentGroupSteps={curentGroupSteps}
                        isSummaryView={isSummaryView}
                    />
                ) : buttonsRender()}
            </div>
        </StepPageLayout>
    )
}

YesNoStep.propTypes = propTypes
YesNoStep.defaultProps = defaultProps

export default YesNoStep
