export const actions = {
    ACCEPT_PICTURE: 'ACCEPT_PICTURE',
    ADD_COMMENT: 'ADD_COMMENT',
    REMOVE: 'REMOVE',
}

export const initState = {
    addComment: false,
    comment: '',
    src: null,
    id: undefined,
}

const reducer = (state, action) => {
    switch (action.type) {
    case actions.ACCEPT_PICTURE:
        return {
            ...state,
            addComment: true,
            src: action.src,
        }
    case actions.ADD_COMMENT:
        return {
            ...state,
            comment: action.comment,
        }
    case actions.REMOVE:
        return {
            ...state,
            ...initState,
        }
    default:
        throw new Error(`Not handled action type for SinglePicture component = ${action.type}`)
    }
}

export default reducer
