import { useMemo } from 'react'
import useProcessAvailableContainers from 'App/Root/hooks/useProcessAvailableContainers'

import { useLocationContext } from 'App/Root/Location'
import useAssetsContacts from './useAssetsContacts'

const buildContainerWidget = (container) => {
    return {
        id: `${container.serialNumber}`,
        number: container.serialNumber,
        productType: container.productType,
        tempRange: container.tempRange,
        isInProcess: !!container.processId,
        currentOrderNumber: container.orderNumber,
    }
}

const useProcessWidgetMap = ({
    processName,
    filters,
}) => {
    const { location } = useLocationContext()
    const {
        data: newAssets = [],
        isLoading: assetsIsLoading,
        error: assetsError,
    } = useProcessAvailableContainers({
        location: location.locationId,
        processType: processName,
        temp: filters.temp,
        needRefetch: true,
    }, true)

    const {
        isLoading: isContactsLoading,
        error: contactsError,
        data: contactsEmailMap,
    } = useAssetsContacts(newAssets, [])

    const widgetMap = useMemo(() => {
        const answeredByProvider = (a) => {
            return { answeredBy: contactsEmailMap?.[a.processChangedBy] }
        }

        return newAssets?.map((a) => {
            const assetItem = buildContainerWidget(a)

            return {
                ...assetItem,
                ...answeredByProvider(a),
            }
        })
    }, [
        newAssets,
        contactsEmailMap,
    ])

    const error = useMemo(() => {
        return assetsError || contactsError
    }, [
        assetsError,
        contactsError,
    ])

    const isLoading = useMemo(() => {
        return assetsIsLoading || isContactsLoading
    }, [
        assetsIsLoading,
        isContactsLoading,
    ])

    return {
        widgetMap,
        isLoading,
        error,
    }
}

export default useProcessWidgetMap
