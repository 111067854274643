import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'

import useIsAdmin from 'App/AccessProvider/useIsAdmin'
import useStyles from './ScanOrManualButtons.style'

const propTypes = {
    onScan: PropTypes.func.isRequired,
    onManual: PropTypes.func.isRequired,
}

const ScanOrManualButtons = ({
    onScan,
    onManual,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const { isAdmin } = useIsAdmin()

    return (
        <div className={classes.actions}>
            <Button
                data-testid="start-scan-button"
                className={classes.button}
                onClick={onScan}
            >
                {t('START_SCAN')}
            </Button>
            { isAdmin && (
                <Button
                    data-testid="manual-enter-button"
                    className={classes.button}
                    onClick={onManual}
                >
                    {t('ENTER_MANUALLY')}
                </Button>
            )}
        </div>
    )
}

ScanOrManualButtons.propTypes = propTypes

export default ScanOrManualButtons
