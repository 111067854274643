import {
    useCallback,
    useMemo,
    useRef,
    useState,
    useEffect,
} from 'react'
import { round } from 'lodash'
import { Html5Qrcode } from 'html5-qrcode'

import isDesktopScreenSize from 'shared/utils/isDesktopScreenSize'

import useMediaDevices from './useMediaDevices'

export const SCAN_AREA_PADDING = 200

const QRBOX_SIZES = {
    desktop: {
        barcode: {
            width: 600,
            height: 200,
        },
        qrCode: {
            width: 400,
            height: 400,
        },
    },
    tablet: {
        barcode: {
            width: 400,
            height: 200,
        },
        qrCode: {
            width: 300,
            height: 300,
        },
    },
}

function useHtml5QrcodeScanner({
    readerId,
    onScan,
    isBarCodeScan,
    options,
}) {
    const html5QrCodeScannerRef = useRef(null)
    const [
        scanStarted,
        setScanStarted,
    ] = useState(false)
    const {
        cameraStreamReceived,
        error: mediaDevicesError,
    } = useMediaDevices()

    const initScan = useCallback(() => {
        if (cameraStreamReceived) {
            const html5QrCode = new Html5Qrcode(readerId)

            html5QrCodeScannerRef.current = html5QrCode
            const qrBoxSizes = isDesktopScreenSize()
                ? QRBOX_SIZES.desktop : QRBOX_SIZES.tablet

            const aspectRatio = options
                ? options.aspect
                : ((window.innerWidth - SCAN_AREA_PADDING) / window.innerHeight)
            const roundedAspectRatio = round(aspectRatio, 2)
            const config = {
                fps: 60,
                aspectRatio: roundedAspectRatio,
                qrbox: isBarCodeScan ? qrBoxSizes.barcode : qrBoxSizes.qrCode,
            }

            html5QrCode.start(
                { facingMode: 'environment' },
                config,
                (decodedText) => {
                    onScan(decodedText)
                },
            ).catch((err) => {
                // eslint-disable-next-line no-console
                console.log('UseHtml5QrcodeScanner: Fails to start scan result', err)
            })
                .then(() => {
                    setScanStarted(true)
                })
        }
    }, [
        readerId,
        onScan,
        isBarCodeScan,
        options,
        cameraStreamReceived,
    ])

    const stopScan = useCallback(() => {
        if (html5QrCodeScannerRef.current?.stateManagerProxy.isScanning()) {
            html5QrCodeScannerRef.current?.stop()
                ?.catch((err) => {
                    // eslint-disable-next-line no-console
                    console.log('UseHtml5QrcodeScanner: Fails to stop scan result', err)
                })
        }
    }, [])

    const started = useMemo(() => {
        return cameraStreamReceived && scanStarted
    }, [
        scanStarted,
        cameraStreamReceived,
    ])

    useEffect(() => {
        return () => {
            stopScan()
        }
    }, [stopScan])

    if (mediaDevicesError) {
        throw mediaDevicesError
    }

    return {
        initScan,
        scanStarted: started,
    }
}

export default useHtml5QrcodeScanner
