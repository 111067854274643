import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import common from 'shared/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        action: { ...common.flexJustityAlignCenter },
        button: {
            ...theme.custom.button,
            paddingLeft: 25,
            paddingRight: 25,
            width: 'auto',
            margin: '35px 25px 35px 25px',
        },
        text: theme.custom.stepText,
        notConfirmed: { padding: '20px' },
    }
})

export default useStyles
