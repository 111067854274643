import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'

import StepPageLayout from 'shared/StepPageLayout'
import useStyles from './TakePicture.style'

const propTypes = {
    step: PropTypes.shape({
        stepTitle: PropTypes.string.isRequired,
        isRequired: PropTypes.bool.isRequired,
    }).isRequired,
    onStart: PropTypes.func.isRequired,
    isNested: PropTypes.bool,
}
const defaultProps = { isNested: false }

const TakePicture = ({
    step,
    onStart,
    isNested,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()

    return (
        <StepPageLayout
            title={t(`processDefinition:${step.stepTitle}`)}
            isRequired={step.isRequired}
            isAnswered={false}
            isNested={isNested}
        >
            <div className={classes.form}>
                <div className={classes.actions}>
                    <Button
                        data-testid="take-picture-button"
                        className={classes.button}
                        onClick={onStart}
                    >
                        {t('TAKE_PICTURE')}
                    </Button>
                </div>
            </div>
        </StepPageLayout>
    )
}

TakePicture.propTypes = propTypes
TakePicture.defaultProps = defaultProps

export default TakePicture
