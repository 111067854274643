import {
    useCallback,
    useEffect,
    useState,
    useMemo,
} from 'react'

import {
    getLastAnsweredGroupIndex,
    hasJumpToSummaryChoiceSeletedInLastAnsweredGroup,
    needJumpToSummaryByInput,
} from 'shared/utils/stepsUtils'

import { ProcessEvidenceStatuses } from '../evidenceConstants'
import useProcessEvidence from './useProcessEvidence'

const getFirstActiveGroupNumber = (processGroups, processStatus) => {
    if (processStatus === ProcessEvidenceStatuses.TO_BE_CONFIRMED) {
        return -1
    }
    const activeGroup = processGroups.filter((group) => {
        return group.steps.some((step) => {
            return !step.isAnswered && !step.isNestedStep && step.isRequired
        })
    })

    if (activeGroup.length === 0) {
        return -1
    }
    return activeGroup[0].progressGroup
}

const getGroupNumberForSummaryBack = (processGroups, processStatus) => {
    if (processStatus === ProcessEvidenceStatuses.TO_BE_CONFIRMED) {
        const answeredGroups = processGroups.filter((group) => {
            return !group.steps.some((step) => {
                return (!step.isAnswered && !step.userInput) && !step.isNestedStep
            })
        })

        return answeredGroups[answeredGroups.length - 1].progressGroup
    }

    const activeGroup = processGroups.filter((group) => {
        return group.steps.some((step) => {
            return (!step.isAnswered && !step.userInput) && !step.isNestedStep && step.isRequired
        })
    })

    return activeGroup[0].progressGroup
}

const needJumpToSummaryByStepsChoice = (groups, status, currentGroup) => {
    if (status !== ProcessEvidenceStatuses.TO_BE_CONFIRMED) {
        return false
    }
    const lastAnsweredGroupIndex = getLastAnsweredGroupIndex(groups)
    const lastAnsweredGroup = groups[lastAnsweredGroupIndex]

    if (currentGroup.progressGroup !== lastAnsweredGroup.progressGroup) {
        return false
    }
    return hasJumpToSummaryChoiceSeletedInLastAnsweredGroup(lastAnsweredGroupIndex, groups)
}

export const PROCESS_ACTION_TYPES = {
    NEXT: 'NEXT',
    BACK: 'BACK',
    CANCEL: 'CANCEL',
    COMPLETE: 'COMPLETE',
    SUMMARY: 'SUMMARY',
    SUMMARY_BACK: 'SUMMARY_BACK',
}

function useProcess({
    processKey,
    assetNumber,
    directScan,
    cancelCallback,
}) {
    const {
        saveGroup,
        cancel,
        complete,
        progressGroups,
        processStatus,
        isLoading,
        error,
        changedBy,
    } = useProcessEvidence({
        processKey,
        assetNumber,
        directScan,
        cancelCallback,
    })

    const [
        state,
        setState,
    ] = useState({
        groups: [],
        currentGroupNumber: 0,
        processStatus: undefined,
    })
    const [
        userInput,
        setUserInput,
    ] = useState({})
    const [
        showSummary,
        setShowSummary,
    ] = useState(false)

    const clearInput = useCallback(() => {
        setUserInput({})
    }, [])

    useEffect(() => {
        if (progressGroups.length > 0) {
            setState({
                groups: progressGroups,
                currentGroupNumber: getFirstActiveGroupNumber(progressGroups, processStatus),
                processStatus,
            })
        }
    }, [
        progressGroups,
        processStatus,
    ])

    const onUserInputClear = useCallback((stepName) => {
        setUserInput((prevInput) => {
            return {
                ...prevInput,
                [stepName]: { needRemove: true },
            }
        })
    }, [])

    const onUserInputChange = useCallback((input, stepName) => {
        setUserInput((prevInput) => {
            return {
                ...prevInput,
                [stepName]: input,
            }
        })
    }, [])

    const currentGroup = useMemo(() => {
        if (state.currentGroupNumber === -1) {
            setShowSummary(true)
            return null
        }
        if (state.currentGroupNumber === 0) {
            return null
        }
        return state.groups[state.currentGroupNumber - 1]
    }, [
        state.currentGroupNumber,
        state.groups,
    ])

    const onMoveStep = useCallback(async (forward) => {
        if (forward) {
            const {
                progressGroups: newProgressGroups,
                processStatus: status,
            } = await saveGroup(state.groups, currentGroup, userInput)
            const isLastStep = newProgressGroups.length === state.currentGroupNumber

            if (isLastStep
                || needJumpToSummaryByInput(currentGroup, userInput)
                || needJumpToSummaryByStepsChoice(newProgressGroups, status, currentGroup)) {
                setState((prevState) => {
                    return {
                        ...prevState,
                        groups: newProgressGroups,
                        processStatus: status,
                    }
                })
                setShowSummary(true)
                return
            }
            clearInput()
            setState({
                groups: newProgressGroups,
                currentGroupNumber: state.currentGroupNumber + 1,
                processStatus: status,
            })
        } else {
            clearInput()
            setState((prevState) => {
                return {
                    ...prevState,
                    currentGroupNumber: state.currentGroupNumber - 1,
                }
            })
        }
    }, [
        currentGroup,
        saveGroup,
        state.currentGroupNumber,
        state.groups,
        userInput,
        clearInput,
    ])

    const onAction = useCallback((actionType) => {
        if (actionType === PROCESS_ACTION_TYPES.NEXT || actionType === PROCESS_ACTION_TYPES.BACK) {
            onMoveStep(actionType === PROCESS_ACTION_TYPES.NEXT)
        }

        if (actionType === PROCESS_ACTION_TYPES.CANCEL) {
            cancel()
        }
        if (actionType === PROCESS_ACTION_TYPES.COMPLETE) {
            complete()
        }
        if (actionType === PROCESS_ACTION_TYPES.SUMMARY) {
            clearInput()
            setShowSummary(true)
        }
        if (actionType === PROCESS_ACTION_TYPES.SUMMARY_BACK) {
            clearInput()
            setState((prevValue) => {
                return {
                    ...prevValue,
                    currentGroupNumber: getGroupNumberForSummaryBack(state.groups,
                        state.processStatus),
                }
            })
            setShowSummary(false)
        }
    }, [
        cancel,
        onMoveStep,
        complete,
        state.groups,
        state.processStatus,
        clearInput,
    ])

    return {
        isLoading,
        error,
        groups: state.groups,
        currentGroup,
        showSummary,
        userInput,
        onUserInputChange,
        onUserInputClear,
        onAction,
        changedBy,
    }
}

export default useProcess
