import React, {
    useCallback,
    useMemo,
    useState,
} from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import {
    Typography,
    Button,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { strToDate } from '@skycell-ag/shared-components'

import {
    getLastAnsweredGroupIndex,
    hasJumpToSummaryChoiceSeletedInLastAnsweredGroup,
} from 'shared/utils/stepsUtils'
import ConfirmDialog from 'shared/ConfirmDialog'
import useContactsByEmail from 'App/Root/hooks/useContactsByEmail'
import { useEvidenceContext } from '../EvidenceContextProvider'
import useEvidence from '../hooks/useEvidence'
import { PROCESS_ACTION_TYPES } from '../hooks/useProcess'
import ProcessGroup from '../ProcessGroup'

import useStyles from './ProcessSummary.style'

const propTypes = {
    assetNumber: PropTypes.string.isRequired,
    actionCallback: PropTypes.func.isRequired,
    changedBy: PropTypes.string.isRequired,
}

const ProcessSummary = ({
    assetNumber,
    actionCallback,
    changedBy,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { evidence } = useEvidenceContext()
    const [
        cancelConfirmOpen,
        setCancelConfirmOpen,
    ] = useState(false)

    const {
        data,
        isFetchedAfterMount,
        error,
    } = useEvidence(evidence.evidenceId, { staleTime: 0 })

    const processData = useMemo(() => {
        return data ? {
            workedOnBy: data.workedOnBy ? data.workedOnBy : undefined,
            modifiedOn: data.changedOnZoned
                ? strToDate(data.changedOnZoned).toLocaleDateString() : undefined,
        } : { workedOnBy: undefined }
    }, [data])

    const {
        data: emailsData,
        error: emailsError,
    } = useContactsByEmail(processData.workedOnBy)

    if (error || emailsError) {
        throw error || emailsError
    }

    const onConfirm = useCallback(() => {
        actionCallback(PROCESS_ACTION_TYPES.COMPLETE)
    }, [actionCallback])

    const usersNames = useMemo(() => {
        return processData.workedOnBy?.map((email) => {
            return emailsData?.[email]
        }).join(', ')
    }, [
        emailsData,
        processData.workedOnBy,
    ])

    const modifiedByMessage = useMemo(() => {
        return (usersNames && processData.modifiedOn) ? t('LAST_MODIFIED_ON_BY', {
            modifiedOn: processData.modifiedOn,
            usernames: usersNames,
        }) : ''
    }, [
        t,
        usersNames,
        processData.modifiedOn,
    ])

    const progressGroups = useMemo(() => {
        if (isFetchedAfterMount) {
            const groups = get(data, 'progressGroups', [])
            const lastAnsweredGroupIndex = getLastAnsweredGroupIndex(groups)

            if (hasJumpToSummaryChoiceSeletedInLastAnsweredGroup(lastAnsweredGroupIndex, groups)) {
                return groups.slice(0, lastAnsweredGroupIndex + 1)
            }

            return groups
        }
        return []
    }, [
        data,
        isFetchedAfterMount,
    ])
    const confirmEnabled = useMemo(() => {
        if (isFetchedAfterMount && data?.processStatus === 'TO_BE_CONFIRMED') {
            return true
        }
        return false
    }, [
        data,
        isFetchedAfterMount,
    ])
    const onBack = useCallback(() => {
        actionCallback(PROCESS_ACTION_TYPES.SUMMARY_BACK)
    }, [actionCallback])

    const onOpenCancelConfirm = useCallback(() => {
        setCancelConfirmOpen(true)
    }, [])
    const onCancelProcess = useCallback(() => {
        setCancelConfirmOpen(false)
        actionCallback(PROCESS_ACTION_TYPES.CANCEL)
    }, [actionCallback])
    const onCancelConfirmForCancel = useCallback(() => {
        setCancelConfirmOpen(false)
    }, [])

    return (
        <div className={classes.main}>
            <div className={classes.content}>
                <div className={classes.summaryHeader}>
                    <Typography
                        variant="h1"
                        className={classes.summaryText}
                    >
                        {t('SUMMARY')}
                    </Typography>
                    <Typography
                        variant="body1"
                        className={classes.modifiedText}
                    >
                        {modifiedByMessage}
                    </Typography>
                </div>
                <div className={classes.stepContainer}>
                    <div className={classes.steps}>
                        {progressGroups.map((group) => {
                            return (
                                <ProcessGroup
                                    key={group.id}
                                    steps={group.steps}
                                    assetNumber={assetNumber}
                                    isSummaryView
                                    changedBy={changedBy}
                                />
                            )
                        })}
                    </div>
                </div>
                <div className={classes.buttons}>
                    <Button
                        data-testid="back-button"
                        className={classes.button}
                        startIcon={<ArrowBackIcon />}
                        onClick={onBack}
                    >
                        {t('BACK')}
                    </Button>
                    <Button
                        data-testid="cancel-button"
                        className={classes.button}
                        onClick={onOpenCancelConfirm}
                    >
                        {t('CANCEL')}
                    </Button>
                    <Button
                        data-testid="confirm-button"
                        className={classes.button}
                        onClick={onConfirm}
                        disabled={!confirmEnabled}
                    >
                        {t('CONFIRM')}
                    </Button>
                </div>
            </div>
            <div>
                <ConfirmDialog
                    open={cancelConfirmOpen}
                    onConfirm={onCancelProcess}
                    onCancel={onCancelConfirmForCancel}
                />
            </div>
        </div>
    )
}

ProcessSummary.propTypes = propTypes

export default ProcessSummary
