import React from 'react'
import PropTypes from 'prop-types'

import NestedStep from 'shared/NestedStep'
import { StepSingleSelectPropTypes } from 'shared/utils/stepsUtils'

import SummaryChoicesList from './SummaryChoicesList'

const propTypes = {
    step: StepSingleSelectPropTypes.isRequired,
    assetNumber: PropTypes.string.isRequired,
    curentGroupSteps: PropTypes.arrayOf(
        PropTypes.shape({ stepName: PropTypes.string.isRequired }),
    ).isRequired,
    isSummaryView: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.shape({
        choiceLabel: PropTypes.string.isRequired,
        choiceIdentifier: PropTypes.string.isRequired,
    })).isRequired,
    choice: PropTypes.string.isRequired,
}
const defaultProps = { isSummaryView: false }

const SummaryChoicesListWithNested = ({
    step,
    assetNumber,
    curentGroupSteps,
    isSummaryView,
    items,
    choice,
}) => {
    return (
        <SummaryChoicesList
            items={items}
            choice={choice}
            isAnswered={!!choice}
        >
            <NestedStep
                step={step}
                choice={choice}
                assetNumber={assetNumber}
                curentGroupSteps={curentGroupSteps}
                isSummaryView={isSummaryView}
            />
        </SummaryChoicesList>
    )
}

SummaryChoicesListWithNested.propTypes = propTypes
SummaryChoicesListWithNested.defaultProps = defaultProps

export default SummaryChoicesListWithNested
