import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'

import { createInputObject } from 'shared/utils/stepsUtils'
import StepPageLayout from 'shared/StepPageLayout'
import useStyles from './AssetNumber.style'

const propTypes = {
    step: PropTypes.shape({ stepName: PropTypes.string.isRequired }).isRequired,
    assetNumber: PropTypes.string.isRequired,
    inputCallback: PropTypes.func,
    isSummaryView: PropTypes.bool,
}
const defaultProps = {
    inputCallback: undefined,
    isSummaryView: false,
}

const AssetNumber = ({
    step,
    assetNumber,
    inputCallback,
    isSummaryView,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()

    useEffect(() => {
        if (inputCallback) {
            const input = createInputObject({ assetNumber })

            inputCallback(input, step.stepName)
        }
    }, [
        inputCallback,
        step,
        assetNumber,
    ])

    return (
        <StepPageLayout
            title={t('CONTAINER_SCANNED')}
            isRequired
            isAnswered
            isSummaryView={isSummaryView}
        >
            <Typography
                variant="h3"
                className={classes.text}
            >
                {t('SELECTED_CONTAINER')}
                <span className={classes.assetNumber}>{assetNumber}</span>
            </Typography>
            { !isSummaryView && (
                <Typography
                    variant="h3"
                    className={classes.text}
                >
                    {t('SAVE_PROCESS')}
                </Typography>
            )}
        </StepPageLayout>
    )
}

AssetNumber.propTypes = propTypes
AssetNumber.defaultProps = defaultProps

export default AssetNumber
