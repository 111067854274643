import PropTypes from 'prop-types'
import findLastIndex from 'lodash/findLastIndex'
import isEmpty from 'lodash/isEmpty'

export const createInputObject = (value, valid = true, jumpToSummary = false) => {
    const result = { value }

    if (jumpToSummary) {
        result.jumpToSummary = true
    }

    if (!valid) {
        return {
            ...result,
            invalid: true,
        }
    }
    return result
}

export const createInputObjectWithChilds = (value, child, valid, jumpToSummary = false) => {
    const result = {
        value,
        nested: { ...child },
    }

    if (jumpToSummary) {
        result.jumpToSummary = true
    }

    if (!valid) {
        return {
            ...result,
            invalid: true,
        }
    }
    return result
}

export const getStepChoice = (id, step) => {
    return step.choices.find((c) => {
        return c.choiceIdentifier === id
    })
}

export const getLastAnsweredGroupIndex = (groups) => {
    return findLastIndex(groups, (group) => {
        return group.steps.some((step) => {
            return step.isAnswered
        })
    })
}

export const hasJumpToSummaryChoiceSeletedInLastAnsweredGroup = (lastIndex, groups) => {
    const lastAnsweredGroup = groups[lastIndex]

    return lastAnsweredGroup.steps.some((step) => {
        if (step.choices.length === 0 || !step.userInput) {
            return false
        }
        if (!isEmpty(step.userInput.selectedChoiceIdentifier)) {
            const choice = getStepChoice(step.userInput.selectedChoiceIdentifier, step)

            return !!choice.jumpToSummary
        }
        if (step.userInput.selectedChoiceIdentifiers.length > 0) {
            return step.userInput.selectedChoiceIdentifiers.some((choiceId) => {
                return getStepChoice(choiceId, step).jumpToSummary
            })
        }
        return false
    })
}

export const StepSingleSelectPropTypes = PropTypes.shape({
    stepName: PropTypes.string.isRequired,
    stepTitle: PropTypes.string.isRequired,
    userInput: PropTypes.shape({ selectedChoiceIdentifier: PropTypes.string }),
    choices: PropTypes.arrayOf(PropTypes.shape({
        choiceIdentifier: PropTypes.string.isRequired,
        choiceLabel: PropTypes.string.isRequired,
        goToStep: PropTypes.string,
        jumpToSummary: PropTypes.bool,
    })).isRequired,
})

export const needJumpToSummaryByInput = (currentGroup, stepsInput) => {
    return currentGroup.steps.some((step) => {
        return stepsInput[step.stepName] && stepsInput[step.stepName].jumpToSummary
    })
}

export const getNestedStep = (id, curentGroupSteps, step) => {
    return curentGroupSteps.find((s) => {
        return s.stepName === getStepChoice(id, step).goToStep
    })
}
