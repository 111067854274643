import { useMemo } from 'react'
import useServiceCenterProcesses from 'App/Root/hooks/useServiceCenterProcesses'
import { useLocationContext } from 'App/Root/Location'

import getGlobalAssetUrl from 'shared/utils/getGlobalAssetUrl'

const buildProcessWidget = (processes, count) => {
    return {
        id: `${processes[0].process}`,
        titleKey: `processDefinition:${processes[0].processLabel}`,
        iconSrc: getGlobalAssetUrl(`skycenter/processes/${processes[0].process}.svg`),
        linkTo: `/processes/${processes[0].process}`,
        count,
    }
}

const getAssetCount = (processes) => {
    return processes.reduce((sum, process) => {
        return sum + process.containerCount
    }, 0)
}

const useProcessesWidgetMap = () => {
    const { location } = useLocationContext()

    const {
        data: newProcesses = [],
        error,
        isLoading,
    } = useServiceCenterProcesses(location.locationId)

    const processesGroups = useMemo(() => {
        return Array.from(newProcesses.reduce(
            (entryMap, e) => {
                return entryMap.set(e.process, [
                    ...entryMap.get(e.process) || [],
                    e,
                ])
            },
            new Map(),
        ).values())
    }, [newProcesses])

    const widgetMap = useMemo(() => {
        return processesGroups.map((grProcesses) => {
            const applicableContanersCount = getAssetCount(grProcesses)

            return buildProcessWidget(grProcesses, applicableContanersCount)
        })
    }, [processesGroups])

    return {
        widgetMap,
        isLoading,
        error,
    }
}

export default useProcessesWidgetMap
