import React, {
    useCallback,
    useMemo,
    useEffect,
    useReducer,
} from 'react'
import noop from 'lodash/noop'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Loading } from '@skycell-ag/shared-components'

import StepPageLayout from 'shared/StepPageLayout'
import ScanOrManualButtons from 'shared/Scans'
import QRScanner from 'shared/QRScanner'
import ManualInput from 'shared/ManualInput'
import { createInputObject } from 'shared/utils/stepsUtils'
import useGetLoggerByNumber from 'shared/hooks/useGetLogger/useGetLoggerByNumber'
import useGetLoggerByQRCode from 'shared/hooks/useGetLogger/useGetLoggerByQRCode'
import QrCodeRetry from 'shared/QrCodeRetry'
import SavyInitializationSummary from 'shared/QRScanSummary'
import getErrorCode from 'shared/utils/getErrorCode'

import NFCTimeOutCheck from './NFCTimeOutCheck'
import SavyInitializationInputError from './SavyInitializationInputError'

import reducer, {
    ACTIONS,
    initState,
    VIEWTYPES,
    STEP_MODE_TITLES,
} from './SavyInitializationStep.reducer'

const initializeState = ({ step }) => {
    const result = { ...initState }

    if (step.isAnswered && step.userInput?.loggerNumber) {
        result.loggerNumber = step.userInput?.loggerNumber
        result.mode = VIEWTYPES.CONFIRM
    }

    return result
}

const propTypes = {
    step: PropTypes.shape({
        stepName: PropTypes.string.isRequired,
        stepTitle: PropTypes.string.isRequired,
        isAnswered: PropTypes.bool,
        isRequired: PropTypes.bool.isRequired,
        userInput: PropTypes.shape({ loggerNumber: PropTypes.string }),
    }).isRequired,
    isSummaryView: PropTypes.bool,
    isNested: PropTypes.bool,
    inputCallback: PropTypes.func,
}

const defaultProps = {
    isSummaryView: false,
    isNested: false,
    inputCallback: noop,
}

const SavyInitializationStep = ({
    step,
    isSummaryView,
    isNested,
    inputCallback,
}) => {
    const { t } = useTranslation()

    const [
        state,
        dispatch,
    ] = useReducer(reducer, { step }, initializeState)

    const {
        data,
        isLoading,
        error,
    } = useGetLoggerByQRCode(state.qrCode, !step.isAnswered)

    const { data: qrCodeData } = useGetLoggerByNumber(state.loggerNumber, step.isAnswered)

    useEffect(() => {
        if (state.qrCode && data) {
            dispatch({
                type: ACTIONS.SET_LOGGER_NUMBER,
                loggerNumber: data.loggerNumber,
            })
        }
    }, [
        state.qrCode,
        data,
    ])

    useEffect(() => {
        if (error) {
            if (getErrorCode(error) === 404) {
                dispatch({ type: ACTIONS.WRONG_QR_CODE })
            } else {
                throw error
            }
        }
    }, [
        error,
        dispatch,
    ])

    const retryAction = useCallback(() => {
        inputCallback(createInputObject(null, true), step.stepName)

        dispatch({ type: ACTIONS.RETRY })
    }, [
        inputCallback,
        step.stepName,
    ])

    const onStartScan = useCallback(() => {
        dispatch({ type: ACTIONS.START_SCAN })
    }, [])
    const onManual = useCallback(() => {
        inputCallback(createInputObject(null, false), step.stepName)
        dispatch({ type: ACTIONS.START_MANUAL })
    }, [
        inputCallback,
        step.stepName,
    ])

    const onScan = useCallback((value) => {
        inputCallback(createInputObject(null, false), step.stepName)
        dispatch({
            type: ACTIONS.SCANED,
            qrCode: value,
        })
    }, [
        inputCallback,
        step.stepName,
    ])

    const onCancelScan = useCallback(() => {
        retryAction()
    }, [retryAction])

    const onRetry = useCallback(() => {
        retryAction()
    }, [retryAction])

    const setUserInput = useCallback(() => {
        const input = createInputObject({ loggerNumber: state.loggerNumber }, true)

        inputCallback(input, step.stepName)
    }, [
        inputCallback,
        step.stepName,
        state.loggerNumber,
    ])

    const timeOutCallback = useCallback(() => {
        dispatch({ type: ACTIONS.CONFIRM })
    }, [])

    const notSupportCallBack = useCallback(() => {
        inputCallback(createInputObject(null, !step.isRequired), step.stepName)
    }, [
        step.stepName,
        step.isRequired,
        inputCallback,
    ])

    const qrCodeSaved = useMemo(() => {
        const str = state.qrCode || qrCodeData?.loggerQrCode

        return `${str}`
    }, [
        qrCodeData?.loggerQrCode,
        state.qrCode,
    ])

    const handleSubmitManualEntering = useCallback((result) => {
        dispatch({
            type: ACTIONS.SCANED,
            qrCode: result,
        })
    }, [])

    useEffect(() => {
        if (state.mode === VIEWTYPES.CONFIRM && state.loggerNumber) {
            setUserInput()
        }
    }, [
        setUserInput,
        state.loggerNumber,
        state.mode,
    ])

    const stepContextRender = useMemo(() => {
        if (state.mode === VIEWTYPES.MANUAL) {
            return (
                <ManualInput
                    onSubmit={handleSubmitManualEntering}
                    onCancel={onRetry}
                    showCancel
                    showSubmit
                />
            )
        }
        if (state.mode === VIEWTYPES.CONFIRM && state.loggerNumber) {
            return (
                <QrCodeRetry
                    qrCode={qrCodeSaved}
                    loggerNumber={state.loggerNumber}
                    onRetry={onRetry}
                />
            )
        }

        if (state.mode === VIEWTYPES.SAVY_INIT) {
            return (
                <div>
                    <NFCTimeOutCheck
                        callback={timeOutCallback}
                        notSupportCallBack={notSupportCallBack}
                    />
                </div>
            )
        }
        if (state.mode === VIEWTYPES.WRONG_QR) {
            return (
                <SavyInitializationInputError
                    qrCode={state.qrCode}
                    onRetry={onRetry}
                />

            )
        }

        return (
            <ScanOrManualButtons
                onScan={onStartScan}
                onManual={onManual}
            />
        )
    }, [
        state.mode,
        onStartScan,
        onManual,
        state.loggerNumber,
        onRetry,
        timeOutCallback,
        qrCodeSaved,
        notSupportCallBack,
        state.qrCode,
        handleSubmitManualEntering,
    ])

    const isAnswered = useMemo(() => {
        return step.isAnswered || Boolean(state.loggerNumber)
    }, [
        state.loggerNumber,
        step.isAnswered,
    ])

    if (state.mode === VIEWTYPES.SCAN) {
        return (
            <QRScanner
                scanMode
                onScan={onScan}
                onCancel={onCancelScan}
            />
        )
    }

    const stepTitle = t(STEP_MODE_TITLES[state.mode])

    return (
        <StepPageLayout
            title={stepTitle}
            isRequired={step.isRequired}
            isAnswered={isAnswered}
            isNested={isNested}
            isSummaryView={isSummaryView}
        >
            {isLoading && (<Loading />)}
            {!isSummaryView && stepContextRender}
            {isSummaryView && (
                <SavyInitializationSummary
                    qrCode={String(qrCodeData?.loggerQrCode)}
                    loggerNumber={state.loggerNumber}
                    isAnswered={step.isAnswered}
                />
            )}
        </StepPageLayout>
    )
}

SavyInitializationStep.propTypes = propTypes
SavyInitializationStep.defaultProps = defaultProps

export default SavyInitializationStep
