import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import common from 'shared/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        root: {
            ...common.fullContainer,
            paddingBottom: 30,
            display: 'flex',
            flexDirection: 'column',
        },
        main: {
            display: 'flex',
            flexGrow: 1,
            paddingTop: 45,
        },
        content: {
            height: '100%',
            ...common.flexJustityAlignCenter,
            justifyContent: 'space-between',
            flexDirection: 'column',
            padding: '0 60px',
        },
        page: {
            ...theme.custom.boxShadowRadius,
            ...theme.custom.border,
            width: '100%',
        },
        backdrop: {
            color: '#fff',
            zIndex: theme.zIndex.drawer + 1,
        },
    }
})

export default useStyles
