import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import TempContainerIcon from 'shared/TempContainerIcon'
import useStyles from './AssertDashboardCardItem.style'

const propTypes = {
    number: PropTypes.string.isRequired,
    isInProcess: PropTypes.bool.isRequired,
    currentOrderNumber: PropTypes.string,
    tempRange: PropTypes.string,
    productType: PropTypes.string,
    answeredBy: PropTypes.string,
    onClick: PropTypes.func.isRequired,
}
const defaultProps = {
    tempRange: undefined,
    productType: undefined,
    answeredBy: undefined,
    currentOrderNumber: undefined,
}

const AssertDashboardCardItem = ({
    number,
    isInProcess,
    tempRange,
    productType,
    currentOrderNumber,
    answeredBy,
    onClick,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const handleClickListItem = useCallback(() => {
        onClick({ number })
    }, [
        number,
        onClick,
    ])

    const modelName = productType ? ` | ${productType}` : ''

    return (
        <div
            className={classes.container}
        >
            <div
                data-testid="assert-dashboard-card"
                aria-hidden="true"
                onClick={handleClickListItem}
            >
                <div className={classes.numberLine}>
                    <div className={classes.numberTypeContainer}>
                        <Typography
                            variant="h3"
                            className={classes.numberLineLetters}
                        >
                            {`${number}${modelName}`}
                        </Typography>
                    </div>
                    <TempContainerIcon tempRange={tempRange} />
                </div>
                <div className={classes.bottomWrapper}>
                    <Typography
                        variant="h5"
                        className={classes.active}
                    >
                        {isInProcess ? t('PROCESS_RUNNING') : ''}
                    </Typography>
                    <Typography
                        variant="h5"
                        className={classes.bottomText}
                    >
                        {answeredBy ? `${t('BEING_ANSWERED_BY')} ${answeredBy}` : ''}
                    </Typography>
                    <Typography
                        variant="h5"
                        className={classes.bottomText}
                    >
                        {currentOrderNumber ? `${t('USED_IN_ORDER')} ${currentOrderNumber}` : ''}
                    </Typography>
                </div>
            </div>
        </div>
    )
}

AssertDashboardCardItem.propTypes = propTypes
AssertDashboardCardItem.defaultProps = defaultProps

export default AssertDashboardCardItem
