import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        main: {
            ...theme.custom.stepTextPadding,
            '&.nested': { paddingRight: 0 },
        },
        title: {
            ...theme.custom.stepElementPadding,
            padding: 0,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            ...theme.custom.dialogTitle,
            ...theme.custom.stepText,
            borderBottomLeftRadius: 0,
            '&.unAnsweredMandatory': theme.custom.mandatoryStepText,
            '&.optional': theme.custom.optionalStepText,
        },
        titleText: theme.custom.messageText,
        childborder: {
            ...theme.custom.stepBorder,
            borderRightWidth: 0,
            borderTopWidth: 0,
            borderRadius: 5,
            borderTopLeftRadius: 0,
            '&.unAnsweredMandatory': theme.custom.mandatoryStepBorder,
            '&.optional': theme.custom.optionalStepBorder,
        },
        stateText: { alignSelf: 'flex-end' },
    }
})

export default useStyles
