import {
    useState, useCallback,
} from 'react'

const useScannerModeSwitch = () => {
    const [
        scanMode,
        setScanMode,
    ] = useState(false)

    const startScan = useCallback(() => {
        setScanMode(true)
    }, [setScanMode])

    const cancelScan = useCallback(() => {
        setScanMode(false)
    }, [setScanMode])

    return {
        scanMode,
        startScan,
        cancelScan,
    }
}

export default useScannerModeSwitch
