/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
    Button,
    IconButton,
    Tooltip,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

import EmptyStepContent from 'shared/EmptyStepContent'
import StepPageLayout from 'shared/StepPageLayout'
import PictureWithComment from './PictureWithComment'
import useStyles from './SummaryPictures.style'

const propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        src: PropTypes.string,
        comment: PropTypes.string,
    })).isRequired,
    onTakeAnother: PropTypes.func,
    onDelete: PropTypes.func,
    step: PropTypes.shape({
        stepTitle: PropTypes.string.isRequired,
        isAnswered: PropTypes.bool.isRequired,
        isRequired: PropTypes.bool.isRequired,
    }).isRequired,
    isNested: PropTypes.bool,
    isSummaryView: PropTypes.bool,
}
const defaultProps = {
    isNested: false,
    isSummaryView: false,
    onTakeAnother: undefined,
    onDelete: undefined,
}

const MAX_ALLOWED_PICTURES = 10

const SummaryPictures = ({
    items,
    onTakeAnother,
    onDelete,
    step,
    isNested,
    isSummaryView,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const takeNotAnotherAllowed = useMemo(() => {
        return items.length >= MAX_ALLOWED_PICTURES
    }, [items.length])

    const takeAnotherButtonRender = useMemo(() => {
        const buttonElement = (
            <Button
                data-testid="take-another-button"
                className={classes.button}
                onClick={onTakeAnother}
                disabled={takeNotAnotherAllowed}
            >
                {t('TAKE_ANOTHER_PICTURE')}
            </Button>
        )

        if (takeNotAnotherAllowed) {
            return (
                <Tooltip title={t('MAX_ALLOWER_PICTURES_PER_STEP', { maxAllowedNumber: MAX_ALLOWED_PICTURES })}>
                    <span>
                        {buttonElement}
                    </span>
                </Tooltip>
            )
        }
        return buttonElement
    }, [
        onTakeAnother,
        t,
        takeNotAnotherAllowed,
        classes.button,
    ])

    return (
        <StepPageLayout
            title={t(`processDefinition:${step.stepTitle}`)}
            isRequired={step.isRequired}
            isAnswered={step.isAnswered || items.length > 0}
            isNested={isNested}
            isSummaryView={isSummaryView}
        >
            <div className={classes.pictures}>
                {
                    items.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className={classes.context}
                            >
                                <PictureWithComment
                                    key={index}
                                    image={item.src}
                                    comment={item.comment}
                                    number={index + 1}
                                />
                                { !isSummaryView && (
                                    <IconButton
                                        aria-label="delete"
                                        data-testid="delete-picture-button"
                                        size="medium"
                                        className={classes.deleteButton}
                                        onClick={() => { onDelete(index, item) }}
                                    >
                                        <DeleteIcon fontSize="large" />
                                    </IconButton>
                                )}

                            </div>
                        )
                    })
                }
            </div>
            {isSummaryView && items.length === 0 && (
                <EmptyStepContent
                    classes={{ text: classes.noPictureText }}
                    title={t('NO_PICTURE')}
                />
            )}
            {!isSummaryView && (
                <div className={classes.buttons}>
                    {takeAnotherButtonRender}
                </div>
            )}
        </StepPageLayout>
    )
}

SummaryPictures.propTypes = propTypes
SummaryPictures.defaultProps = defaultProps

export default SummaryPictures
