import React, {
    useCallback,
    useMemo,
} from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { getContainerNumberFromGRAI } from '@skycell-ag/shared-components'

import Dialog from 'shared/Dialog'
import QRScannerView from 'shared/QRScanner/QRScannerView'
import isDesktopScreenSize from 'shared/utils/isDesktopScreenSize'
import useIsAdmin from 'App/AccessProvider/useIsAdmin'
import useStyles from './AssetScan.style'

const propTypes = {
    onScan: PropTypes.func.isRequired,
    assetNumber: PropTypes.string,
    switchToManual: PropTypes.func.isRequired,
}

const OPTIONS = {
    desktop: {
        width: 900,
        aspect: 2,
    },
    tablet: {
        width: 600,
        aspect: 2.1,
    },
}

const defaultProps = { assetNumber: '' }

const AssetScan = ({
    onScan,
    assetNumber,
    switchToManual,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const { isAdmin } = useIsAdmin()

    const onCodeScan = useCallback((value) => {
        let code = value

        if (code.length > 10) {
            code = getContainerNumberFromGRAI(code)
        }
        onScan({ value: code })
    }, [onScan])

    const onCancel = useCallback(() => {
        onScan(
            {
                value: null,
                close: true,
            },
        )
    }, [onScan])

    const onManual = useCallback(() => {
        switchToManual(true)
    }, [switchToManual])
    const options = useMemo(() => {
        return (isDesktopScreenSize()) ? OPTIONS.desktop : OPTIONS.tablet
    }, [])

    return (
        <Dialog
            id="asset-scan-dialog"
            titleMessage={`${t('PLEASE_SCAN_CODE_OF_CONTAINER')} ${assetNumber}`}
            buttons={(
                <>
                    { isAdmin && (
                        <Button
                            data-testid="manual-button"
                            className={classes.button}
                            onClick={onManual}
                        >
                            {t('ENTER_MANUALLY')}
                        </Button>
                    )}
                    <Button
                        data-testid="cancel-button"
                        className={classes.button}
                        onClick={onCancel}
                    >
                        {t('CANCEL')}
                    </Button>
                </>
            )}
        >
            <div className={classes.contextContainer}>
                <QRScannerView
                    onScan={onCodeScan}
                    isBarCodeScan
                    options={options}
                />
            </div>
        </Dialog>
    )
}

AssetScan.propTypes = propTypes
AssetScan.defaultProps = defaultProps

export default AssetScan
