import { requestBusinessObjects } from '@skycell-ag/shared-components'
import { useJWTToken } from 'App/Auth'
import { useQuery } from 'react-query'

const getProcesses = ({ queryKey: [
    _key, // eslint-disable-line no-unused-vars
    {
        location,
        token,
    },
] }) => {
    return requestBusinessObjects({
        method: 'GET',
        url: `skycenter/processevidence/location/${location}/count`,
        token,
    })
        .then((data) => {
            return data.data
        })
}

function useServiceCenterProcesses(locationId) {
    const token = useJWTToken()

    return useQuery({
        queryKey: [
            'getSeviceCenterProcesses',
            {
                location: locationId,
                token,
            },
        ],
        queryFn: getProcesses,
        enabled: Boolean(locationId),
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 1000,
    })
}

export default useServiceCenterProcesses
