import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import common from 'shared/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        main: {
            display: 'flex',
            flexGrow: 1,
            paddingTop: 45,
        },
        content: {
            height: '100%',
            ...common.flexJustityAlignCenter,
            justifyContent: 'space-between',
            flexDirection: 'column',
            padding: '0 90px',
        },
        buttons: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gridGap: 12,
            paddingTop: 40,
        },
        button: theme.custom.button,
        summaryHeader: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            borderBottom: `1px solid ${theme.palette.primary.main}`,
        },
        summaryText: { color: theme.typography.h3.color },
        modifiedText: {
            color: theme.typography.h3.color,
            alignSelf: 'flex-end',
        },
        stepContainer: {
            width: '100%',
            paddingTop: 30,
        },
        steps: {
            width: '100%',
            ...theme.custom.boxShadowRadius,
            ...theme.custom.border,
        },
    }
})

export default useStyles
