import React from 'react'
import PropTypes from 'prop-types'

import useStyles from './Dashboard.style'

const propTypes = {
    widgets: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })).isRequired,
    widgetComponent: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
}

const Dashboard = ({
    widgets,
    widgetComponent: Component,
    ...rest
}) => {
    const classes = useStyles(rest)

    return (
        <div className={classes.container}>
            {
                widgets.map((widget) => {
                    return (
                        <Component
                            key={widget.id}
                            {...widget} // eslint-disable-line react/jsx-props-no-spreading
                        />
                    )
                })
            }
        </div>
    )
}

Dashboard.propTypes = propTypes

export default Dashboard
