import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import TwoColumnErrorPage from 'shared/ErrorPages/TwoColumnErrorPage'
import checkStatus from 'shared/utils/checkStatus'

import useStyles from './IATAScanResultMessage.style'

const propTypes = {
    resultStatus: PropTypes.string,
    scanResult: PropTypes.string,
    containerTempRange: PropTypes.string,
    onRetry: PropTypes.func.isRequired,
}

const defaultProps = {
    resultStatus: '',
    scanResult: '',
    containerTempRange: '',
}

const IATAScanResultMessage = ({
    resultStatus,
    scanResult,
    containerTempRange,
    onRetry,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const texts = useMemo(() => {
        return [
            {
                label: `${t('STICKER')}: `,
                value: scanResult,
            },
            {
                label: `${t('CONTAINER')}: `,
                value: containerTempRange,
            },
        ]
    }, [
        scanResult,
        containerTempRange,
        t,
    ])

    return (
        <>
            { resultStatus === checkStatus.SUCCESS && (
                <div className={classes.success}>
                    {t('CORRECT_STICKER', { tempRange: scanResult })}
                </div>
            )}

            { resultStatus === checkStatus.FAILURE && (
                <TwoColumnErrorPage
                    iconPath="skycenter/not_found.svg"
                    iconAlt="logo"
                    buttonLabel={t('RETRY')}
                    onButtonClick={onRetry}
                    buttonDataTestId="retry-button"
                    messageTop={t('WRONG_STICKER_PART_ONE')}
                    messageBottom={t('WRONG_STICKER_PART_TWO')}
                    middleTexts={texts}
                />
            )}
        </>
    )
}

IATAScanResultMessage.propTypes = propTypes
IATAScanResultMessage.defaultProps = defaultProps

export default IATAScanResultMessage
