import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Loading } from '@skycell-ag/shared-components'

import PreLoadScannerProvider from 'shared/QRScanner/PreLoadScanner'
import useUserLocations from '../hook/useUserLocations'
import NoLocation from '../NoLocation'
import { useLocationContext } from '../LocationProvider'

const propTypes = { children: PropTypes.element.isRequired }

const UserLocation = ({ children }) => {
    const {
        data: location,
        isLoading,
        error,
    } = useUserLocations()
    const { setLocation } = useLocationContext()

    useEffect(() => {
        if (location) {
            setLocation({
                locationName: location.locationName,
                locationId: location.id,
            })
        }
    }, [
        setLocation,
        location,
    ])

    if (isLoading) {
        return (<Loading />)
    }
    if (error) {
        throw error
    }

    if (location === undefined) {
        return <NoLocation />
    }

    return (
        <PreLoadScannerProvider
            enhancedSkyCenterScanningAllowed={location?.enhancedSkyCenterScanning}
        >
            {children}
        </PreLoadScannerProvider>
    )
}

UserLocation.propTypes = propTypes

export default UserLocation
