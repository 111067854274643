import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import common from 'shared/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        root: {
            ...common.fullContainer,
            display: 'flex',
            flexDirection: 'column',
        },
        buttons: {
            ...common.flexJustityAlignCenter,
            flexGrow: 0,
            paddingBottom: 20,
        },
        button: common.stepsPictureButton(theme),
        noPictureText: { padding: '20px' },
        context: { display: 'flex' },
        deleteButton: {
            padding: 0,
            paddingTop: 10,
            alignSelf: 'start',
        },
    }
})

export default useStyles
