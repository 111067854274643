import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import common from 'shared/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        result: {
            padding: 20,
            fontSize: theme.typography.h3.fontSize,
            lineHeight: theme.typography.h3.lineHeight,
            color: theme.palette.secondary[600],
        },
        actions: { ...common.flexJustityAlignCenter },
        button: {
            ...theme.custom.button,
            margin: '30px 25px 20px 25px',
        },
        error: {
            color: theme.palette.error.main,
            font: `normal normal 400 ${theme.typography.h4.fontSize}px/${theme.typography.h4.lineHeight} Roboto`,
            marginTop: 4,
        },
    }
})

export default useStyles
