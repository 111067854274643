import React, {
    useCallback,
    useMemo,
    useReducer,
    useState,
} from 'react'
import {
    useParams,
    Link,
    useHistory,
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
    Button,
    ToggleButtonGroup,
    ToggleButton,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import getGlobalAssetUrl from 'shared/utils/getGlobalAssetUrl'
import Dashboard from 'shared/Dashboard'
import ScanDialog from 'shared/ScanDialog'
import ROUTE_KEYS from 'shared/utils/routeKeys'
import TempContainerIcon from 'shared/TempContainerIcon'

import AssertDashboardCardItem from './AssertDashboardCardItem'
import useProcessWidgetMap from './hooks'

import ScanErrorDialog from './ScanErrorDialog'
import reducer, { actions } from './ProcessDashboard.reducer'

import useStyles from './ProcessDashboard.style'

const ALL_FILTER_VALUE = 'ALL'

const tempRanges = [
    'CRT',
    'C',
    'F',
    'DF',
]

const ProcessDashboard = () => {
    const { type: processName } = useParams()
    const classes = useStyles()
    const { t } = useTranslation()
    const [
        filters,
        setFilters,
    ] = useState({ temp: undefined })
    const {
        error,
        widgetMap,
    } = useProcessWidgetMap({
        processName,
        filters,
    })
    const history = useHistory()

    const [
        state,
        dispatch,
    ] = useReducer(reducer, {
        scanDialogOpen: false,
        item: null,
        errorDialogOpen: false,
    })

    const handleClickItem = useCallback((item) => {
        dispatch({
            type: actions.START_SCAN,
            item,
        })
    }, [])

    const handleClose = useCallback(({
        success, value,
    }) => {
        if (success) {
            if (state.item.number === value) {
                dispatch({ type: actions.SCAN_SECCESSFUL })
                history.push(ROUTE_KEYS.ASSET_PROCESS(processName, state.item.number))
            } else {
                dispatch({ type: actions.SCAN_FAILED })
            }
        } else {
            dispatch({ type: actions.CANCEL_SCAN })
        }
    }, [
        history,
        state,
        processName,
    ])

    const handleErrorDialogClose = useCallback(() => {
        dispatch({ type: actions.ERROR_CLOSED })
    }, [])

    const widgetMapWithClickHandler = useMemo(() => {
        return widgetMap?.map((widget) => {
            return {
                ...widget,
                onClick: handleClickItem,
            }
        })
    }, [
        widgetMap,
        handleClickItem,
    ])

    // eslint-disable-next-line no-unused-vars
    const handleTempChange = useCallback((_event, newTemp) => {
        if (newTemp) {
            setFilters({ temp: newTemp === ALL_FILTER_VALUE ? undefined : newTemp })
        }
    }, [])

    if (error) {
        throw error
    }

    return (
        <>
            <div className={classes.root}>
                <div className={classes.main}>
                    <div className={classes.filter}>
                        <div>
                            <ToggleButtonGroup
                                value={filters.temp}
                                exclusive
                                onChange={handleTempChange}
                            >
                                <ToggleButton
                                    value={ALL_FILTER_VALUE}
                                    classes={{
                                        selected: classes.selectedToggleButton,
                                        root: classes.toggleButton,
                                    }}
                                >
                                    <img
                                        alt="logo"
                                        className={classes.tempImg}
                                        src={getGlobalAssetUrl('skycenter/filter-filled-tool-symbol_24.png')}
                                    />
                                </ToggleButton>
                                {
                                    tempRanges.map((temp) => {
                                        return (
                                            <ToggleButton
                                                key={temp}
                                                value={temp}
                                                classes={{
                                                    selected: classes.selectedToggleButton,
                                                    root: classes.toggleButton,
                                                }}
                                            >
                                                <TempContainerIcon tempRange={temp} />
                                            </ToggleButton>
                                        )
                                    })
                                }
                            </ToggleButtonGroup>
                        </div>
                    </div>
                    <div className={classes.context}>
                        <Dashboard
                            widgets={widgetMapWithClickHandler}
                            widgetComponent={AssertDashboardCardItem}
                        />
                    </div>
                    <div className={classes.buttons}>
                        <Button
                            data-testid="back-button"
                            component={Link}
                            to="/processes"
                            className={classes.button}
                            startIcon={<ArrowBackIcon />}
                        >
                            {t('BACK')}
                        </Button>
                    </div>
                </div>
            </div>
            <div>
                <ScanDialog
                    open={state.scanDialogOpen}
                    onClose={handleClose}
                    item={state.item}
                />
            </div>
            <div>
                <ScanErrorDialog
                    open={state.errorDialogOpen}
                    onClose={handleErrorDialogClose}
                    item={state.item}
                />
            </div>
        </>
    )
}

export default ProcessDashboard
