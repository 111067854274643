import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import common from 'shared/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        contextContainer: {
            paddingLeft: 40,
            paddingRight: 40,
            paddingTop: 35,
        },
        actions: {
            ...common.flexJustityAlignCenter,
            padding: 0,
        },
        button: {
            ...theme.custom.button,
            width: theme.custom?.button?.width,
            margin: '40px 25px 40px 25px',
        },
    }
})

export default useStyles
