import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import common from 'shared/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        button: {
            ...theme.custom.button,
            width: 'auto',
        },
        contextContainer: {
            ...common.flexJustityAlignCenter,
            height: 600,
            width: 900,
            '@media (max-width:1000px)': {
                height: 300,
                width: 600,
            },
        },
    }
})

export default useStyles
