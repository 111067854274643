import React, { useState } from 'react'
import PropTypes from 'prop-types'
import AttachmentContext from './AttachmentContext'

const propTypes = { children: PropTypes.element.isRequired }

const AttachmentContextProvider = ({ children }) => {
    const [
        attachments,
        setAttachments,
    ] = useState(
        { attachments: [] },
    )

    return (
        <AttachmentContext.Provider
            value={{
                attachments,
                saveAttachments: setAttachments,
            }}
        >
            {children}
        </AttachmentContext.Provider>
    )
}

AttachmentContextProvider.propTypes = propTypes

export default AttachmentContextProvider
