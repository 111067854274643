import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Button } from '@mui/material'

import TwoRowErrorPage from 'shared/ErrorPages/TwoRowErrorPage'
import ROUTE_KEYS from 'shared/utils/routeKeys'

import useStyles from './AssetNotApplicableErrorPage.style'

const AssetNotApplicableErrorPage = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const classes = useStyles()

    const onOk = useCallback(() => {
        history.push(ROUTE_KEYS.MAIN)
    }, [history])

    return (
        <TwoRowErrorPage
            message={t('CANCELLED_PROCESS')}
            iconPath="skycenter/not_working_robot.svg"
            iconAlt="error"
        >
            <Button
                data-testid="ok-button"
                className={classes.button}
                onClick={onOk}
            >
                {t('OK')}
            </Button>
        </TwoRowErrorPage>
    )
}

export default AssetNotApplicableErrorPage
