import React, { Component } from 'react'
import PropTypes from 'prop-types'
import includes from 'lodash/includes'

import errorBEMessageKeys from 'shared/utils/errorBEMessageKeys'

import AssetNotApplicableErrorPage from './AssetNotApplicableErrorPage'

const PROCESS_ERRORS = {
    403: [errorBEMessageKeys.CAN_NOT_UPDATE_CANCELLED_PROCESS_EVIDENCE],
    409: [errorBEMessageKeys.ASSET_NO_LONGER_APPLICABLE_FOR_PROCESS_DEFINITION],
}

const isAssetNoApplicable = (error) => {
    const errKeys = PROCESS_ERRORS[error.response?.status]

    return errKeys && includes(errKeys, error.response?.data[0]?.message)
}

const propTypes = { children: PropTypes.element.isRequired }

class ProcessErrorBoundary extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hasError: false,
            error: null,
        }
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            error,
        }
    }

    render() {
        const {
            hasError,
            error,
        } = this.state
        const { children } = this.props

        if (hasError) {
            if (isAssetNoApplicable(error)) {
                return (
                    <AssetNotApplicableErrorPage />
                )
            }
            throw error
        }

        return (<>{children}</>)
    }
}

ProcessErrorBoundary.propTypes = propTypes

export default ProcessErrorBoundary
