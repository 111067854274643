import { requestBusinessObjects } from '@skycell-ag/shared-components'

export const createProcess = (token, data) => {
    return requestBusinessObjects({
        method: 'POST',
        url: 'skycenter/processevidence',
        token,
        data,
    })
        .then((result) => {
            return result.data
        })
}

export const updateProcess = ({
    token,
    data,
}) => {
    return requestBusinessObjects({
        method: 'PATCH',
        url: 'skycenter/processevidence/processgroup',
        token,
        data,
    })
        .then((result) => {
            return result.data
        })
}

export const cancelProcess = (token, id) => {
    return requestBusinessObjects({
        method: 'PUT',
        url: `skycenter/processevidence/${id}/cancel`,
        token,
    })
        .then((result) => {
            return result.data
        })
}

export const completeProcess = (token, id) => {
    return requestBusinessObjects({
        method: 'PUT',
        url: `skycenter/processevidence/${id}/summaryconfirmation`,
        token,
    })
        .then((result) => {
            return result.data
        })
}
