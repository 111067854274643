const VIDEO_CONSTRAINS = {
    portrait: {
        stream: {
            height: { exact: 720 },
            width: { exact: 1280 },
        },
        screenshot: {
            width: 720,
            height: 1280,
        },
    },
    landscape: {
        stream: {
            aspectRatio: 16 / 9,
            width: 1280,
            height: 720,
        },
        screenshot: {
            aspectRatio: 16 / 9,
            width: 1280,
            height: 720,
        },
    },
}

export default VIDEO_CONSTRAINS
