import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        formControlStyle: {
            marginLeft: theme.spacing(-0.625),
            cursor: 'text',
        },
        icon: {
            width: 24,
            height: 24,
            padding: 9,
            color: theme.palette.primary[400],
        },
        summaryView: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
        },
        notSelectedText: {
            textDecoration: 'line-through',
            color: theme.palette.secondary.main,
        },
        itemLabel: {
            fontSize: theme.typography.h3.fontSize,
            lineHeight: theme.typography.h3.lineHeight,
        },
    }
})

export default useStyles
