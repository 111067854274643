import { useMemo } from 'react'

import useContacts from './useContacts'

const useContactsByEmail = (emails) => {
    const {
        isLoading,
        error,
        data: contacts,
    } = useContacts(emails)

    const emailContactNameMapper = useMemo(() => {
        return contacts?.reduce((map, {
            email, contactName,
        }) => {
            return {
                ...map, [email]: contactName,
            }
        }, {})
    }, [contacts])

    return {
        isLoading,
        error,
        data: emailContactNameMapper,
    }
}

export default useContactsByEmail
