import { requestBusinessObjects } from '@skycell-ag/shared-components'
import { useJWTToken } from 'App/Auth'
import { useQuery } from 'react-query'

const getServiceProcessDefinitionById = ({ queryKey: [
    _key, // eslint-disable-line no-unused-vars
    {
        id,
        serialNumber,
        token,
    },
] }) => {
    return requestBusinessObjects({
        method: 'GET',
        url: `serviceprocessdefinition/${id}/container/${serialNumber}`,
        token,
    })
        .then((data) => {
            return data.data
        })
}

function useGetServiceProcessDefinitionById(processId, serialNumber) {
    const token = useJWTToken()

    return useQuery({
        queryKey: [
            'getProcesses',
            {
                id: processId,
                serialNumber,
                token,
            },
        ],
        queryFn: getServiceProcessDefinitionById,
        enabled: Boolean(processId),
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 1000,
    })
}

export default useGetServiceProcessDefinitionById
