import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        assetNumber: {
            ...theme.custom.stepElementPadding,
            fontWeight: 'bold',
        },
        text: theme.custom.stepText,
    }
})

export default useStyles
