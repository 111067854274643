import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import common from 'shared/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        form: {
            ...theme.custom.stepElementPadding,
            ...common.flexJustityAlignCenter,
        },
        actions: {
            ...common.flexJustityAlignCenter,
            flexShrink: 1,
            flexBasis: 0,
        },
        pictureContainer: {
            ...common.flexJustityAlignCenter,
            justifyContent: 'center',
            paddingLeft: 10,
            flexShrink: 1,
            flexBasis: 0,
        },
        pictureContainerLayout: {
            width: 640,
            '@media (max-width:1000px)': { width: 427 },
            ...common.flexJustityAlignCenter,
            justifyContent: 'center',
        },
        picture: {
            width: 640,
            height: 360,
            '@media (max-width:1000px)': {
                width: 427,
                height: 240,
            },
        },
        pictureVr: {
            width: 207,
            height: 368,
            '@media (max-width:1000px)': {
                width: 135,
                height: 240,
            },
        },

    }
})

export default useStyles
