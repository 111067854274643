import React from 'react'
import { PropTypes } from 'prop-types'

import DynamicSoftScanner from './DynamicSoftScanner'
import Html5QrcodeScanner from './Html5QrcodeScanner'
import useDynamicSoftScanner from './PreLoadScanner/useDynamicSoftScanner'

const propTypes = {
    onScan: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    isBarCodeScan: PropTypes.bool.isRequired,
    options: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
        aspect: PropTypes.number,
    }),
}

const defaultProps = {
    onCancel: undefined,
    options: undefined,
}

const QRScannerSwitch = ({
    onScan,
    onCancel,
    isBarCodeScan,
    options,
}) => {
    const { useDynamicSoft } = useDynamicSoftScanner()

    return (
        <>
            {useDynamicSoft && (
                <DynamicSoftScanner
                    onScan={onScan}
                    options={options}
                    onCancel={onCancel}
                />
            )}
            {!useDynamicSoft && (
                <Html5QrcodeScanner
                    onScan={onScan}
                    onCancel={onCancel}
                    options={options}
                    isBarCodeScan={isBarCodeScan}
                />
            )}
        </>
    )
}

QRScannerSwitch.propTypes = propTypes
QRScannerSwitch.defaultProps = defaultProps

export default QRScannerSwitch
