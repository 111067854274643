import React from 'react'
import { Loading } from '@skycell-ag/shared-components'
import Dashboard from 'shared/Dashboard'
import DashboardCardCountedItem from './DashboardCardCountedItem'
import useProcessesWidgetMap from './hooks'
import useStyles from './ProcessesDashboard.style'

const ProcessesDashboard = () => {
    const classes = useStyles()
    const {
        error,
        isLoading,
        widgetMap,
    } = useProcessesWidgetMap()

    if (error) {
        throw error
    }

    if (isLoading) {
        return (
            <Loading />
        )
    }
    if (widgetMap && widgetMap.length === 0) {
        return (
            <span>No available processes</span>
        )
    }

    return (
        <div className={classes.root}>
            <div className={classes.main}>
                <Dashboard
                    widgets={widgetMap}
                    widgetComponent={DashboardCardCountedItem}
                />
            </div>
        </div>
    )
}

export default ProcessesDashboard
