import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import common from 'shared/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        dialogContentRoot: {
            ...common.flexJustityAlignCenter,
            padding: 0,
            flexDirection: 'column',
        },
        dialogActionsRoot: common.dialogActionsRoot,
        dialogTitle: { padding: '22px 20px' },
        titleHeader: {
            padding: '19px 26px 17px 26px',
            ...theme.custom.dialogTitle,
        },
    }
})

export default useStyles
