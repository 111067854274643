import flow from 'lodash/flow'
import Axios from 'axios'

import bearerToken from './bearerToken'
import addHostUrl from './addHostUrl'

const { REACT_APP_SKYMIND_API: apiUrl } = process.env

const keycloakService = (params) => {
    const modifiedQuery = flow([
        bearerToken,
        addHostUrl(`${apiUrl}/skycore-backend`),
    ])(Axios)

    return modifiedQuery(params)
        .then(({ data }) => { return data })
}

export default keycloakService
