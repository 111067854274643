import React from 'react'
import PropTypes from 'prop-types'
import { ErrorBoundary as BaseErrorBoundary } from 'react-error-boundary'
import ErrorFallback from './ErrorFallback'

const propTypes = { children: PropTypes.element.isRequired }

const ErrorBoundary = ({ children }) => {
    return (
        <BaseErrorBoundary
            FallbackComponent={ErrorFallback}
        >
            {children}
        </BaseErrorBoundary>
    )
}

ErrorBoundary.propTypes = propTypes

export default ErrorBoundary
