import React from 'react'

import ProcessPage from 'App/Root/ProcessPage'

const DirectScanProcessPage = () => {
    return (
        <ProcessPage directScan />
    )
}

export default DirectScanProcessPage
