import React, {
    useMemo,
    useCallback,
    useState,
} from 'react'
import PropTypes from 'prop-types'

import ProcessStepPage from 'App/Root/ProcessPage/ProcessStepPage'
import { createInputObjectWithChilds } from 'shared/utils/stepsUtils'

const propTypes = {
    step: PropTypes.shape({
        stepName: PropTypes.string.isRequired,
        isAnswered: PropTypes.bool,
        choices: PropTypes.arrayOf(PropTypes.shape({
            choiceIdentifier: PropTypes.string.isRequired,
            goToStep: PropTypes.string,
        })).isRequired,
    }).isRequired,
    inputCallback: PropTypes.func,
    assetNumber: PropTypes.string.isRequired,
    curentGroupSteps: PropTypes.arrayOf(
        PropTypes.shape({ stepName: PropTypes.string.isRequired }),
    ).isRequired,
    choice: PropTypes.string,
    isSummaryView: PropTypes.bool,
    isChanged: PropTypes.bool,
}

const defaultProps = {
    choice: undefined,
    inputCallback: undefined,
    isSummaryView: false,
    isChanged: false,
}

const NestedStep = ({
    step,
    inputCallback,
    assetNumber,
    curentGroupSteps,
    choice,
    isSummaryView,
    isChanged,
}) => {
    const getCurrentChoiceObj = useCallback((choiceValue) => {
        return step.choices.find((c) => {
            return c.choiceIdentifier === choiceValue
        })
    }, [step.choices])

    const currentChoiceObj = useMemo(() => {
        return getCurrentChoiceObj(choice)
    }, [
        choice,
        getCurrentChoiceObj,
    ])

    const choiceHasNestedStep = useMemo(() => {
        return (currentChoiceObj) ? (!!currentChoiceObj.goToStep) : false
    }, [currentChoiceObj])

    const nestedInputCallback = useCallback((input, stepName) => {
        if (inputCallback) {
            const newInput = createInputObjectWithChilds({ selectedChoiceIdentifier: choice },
                { [stepName]: input },
                !input.invalid,
                input.jumpToSummary)

            inputCallback(newInput, step.stepName)
        }
    }, [
        inputCallback,
        step.stepName,
        choice,
    ])

    const [nestedStep] = useState(() => {
        if (!currentChoiceObj || !choiceHasNestedStep) {
            return null
        }
        const stepForNestedStep = curentGroupSteps.find((s) => {
            return s.stepName === currentChoiceObj.goToStep
        })

        if (stepForNestedStep && isChanged && stepForNestedStep.isAnswered) {
            return {
                ...stepForNestedStep,
                userInput: undefined,
                isAnswered: false,
            }
        }
        return stepForNestedStep
    })

    return (
        <>
            {choiceHasNestedStep && nestedStep
                    && (
                        <ProcessStepPage
                            step={nestedStep}
                            assetNumber={assetNumber}
                            inputCallback={nestedInputCallback}
                            curentGroupSteps={curentGroupSteps}
                            isNested
                            isSummaryView={isSummaryView}
                        />
                    )}
        </>
    )
}

NestedStep.propTypes = propTypes
NestedStep.defaultProps = defaultProps

export default NestedStep
