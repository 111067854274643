import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        container: (props) => {
            return {
                ...theme.custom.boxShadowRadius,
                ...theme.custom.border,
                overflow: 'hidden',
                cursor: !props || props.active ? 'pointer' : 'default',
            }
        },
        topWrapper: (props) => {
            return {
                height: 134,
                background: !props || props.disabled
                    ? theme.palette.secondary[200] : theme.palette.primary[100],
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px 0px`,
            }
        },
        bottomWrapper: (props) => {
            return {
                height: 89,
                padding: 20,
                boxSizing: 'border-box',
                opacity: !props || props.disabled ? 0.5 : 1,
            }
        },
        info: {},
        icon: {
            width: 59,
            height: 59,
            paddingLeft: 20,
            paddingTop: 39,
            paddingBottom: 36,
        },
    }
})

export default useStyles
