import React, {
    useMemo,
    Fragment,
    useCallback,
} from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Checkbox } from '@skycell-ag/shared-components'

import StepPageLayout from 'shared/StepPageLayout'
import { SummaryChoicesItem } from 'shared/SummaryChoicesList'
import {
    getStepChoice,
    getNestedStep,
} from 'shared/utils/stepsUtils'

import ProcessStepPage from 'App/Root/ProcessPage/ProcessStepPage'

import useStyles from './MultipleChoice.style'
import useNestedMultiChoice from './useNestedMultiChoice'

const getCorrectNestedStep = (choiceIdentifier, curentGroupSteps, step, isChanged) => {
    const nestedStep = getNestedStep(choiceIdentifier, curentGroupSteps, step)

    if (nestedStep && isChanged && nestedStep.isAnswered) {
        return {
            ...nestedStep,
            userInput: undefined,
            isAnswered: false,
        }
    }
    return nestedStep
}

const propTypes = {
    step: PropTypes.shape({
        stepName: PropTypes.string.isRequired,
        stepTitle: PropTypes.string.isRequired,
        isRequired: PropTypes.bool.isRequired,
        userInput: PropTypes.shape({ selectedChoiceIdentifiers:
            PropTypes.arrayOf(PropTypes.string) }),
        choices: PropTypes.arrayOf(PropTypes.shape({
            choiceIdentifier: PropTypes.string.isRequired,
            choiceLabel: PropTypes.string.isRequired,
            goToStep: PropTypes.string,
            jumpToSummary: PropTypes.bool,
        })).isRequired,
    }).isRequired,
    assetNumber: PropTypes.string.isRequired,
    inputCallback: PropTypes.func,
    curentGroupSteps: PropTypes.arrayOf(
        PropTypes.shape({ stepName: PropTypes.string.isRequired }),
    ).isRequired,
    isNested: PropTypes.bool,
    isSummaryView: PropTypes.bool,
    nestedInputFromUserInput: PropTypes.func,
}
const defaultProps = {
    isNested: false,
    inputCallback: undefined,
    isSummaryView: false,
    nestedInputFromUserInput: undefined,
}
const MultipleChoice = ({
    step,
    inputCallback,
    assetNumber,
    curentGroupSteps,
    isNested,
    isSummaryView,
    nestedInputFromUserInput,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const {
        choices,
        onChoiceChange,
        nestedInputCallback,
        setNestedInput,
        isChanged,
    } = useNestedMultiChoice({
        step,
        curentGroupSteps,
        inputCallback,
        isSummaryView,
        isNested,
        nestedInputFromUserInput,
    })

    const isAnswered = useMemo(() => {
        return Object.keys(choices).some((key) => {
            return choices[key]
        })
    }, [choices])

    const loadNestedInputFromChilds = useCallback((input, stepName) => {
        setNestedInput((previousNestedInput) => {
            return {
                ...previousNestedInput,
                [stepName]: input,
            }
        })
    }, [setNestedInput])

    const renderItem = (identifier, label) => {
        if (isSummaryView) {
            const choiceForItem = choices[identifier] ? identifier : undefined

            return (
                <SummaryChoicesItem
                    choice={choiceForItem}
                    choiceLabel={label}
                    choiceIdentifier={identifier}
                    isAnswered={isAnswered}
                />
            )
        }
        return (
            <Checkbox
                className={classes.checkbox}
                key={identifier}
                name={identifier}
                value={choices[identifier]}
                onChange={onChoiceChange}
                title={t(`processDefinition:${label}`)}
                classes={{ label: classes.checkboxLabel }}
            />
        )
    }

    return (
        <StepPageLayout
            title={t(`processDefinition:${step.stepTitle}`)}
            isRequired={step.isRequired}
            isAnswered={isAnswered}
            isNested={isNested}
            isSummaryView={isSummaryView}
        >
            <div className={classes.form}>
                {step.choices.map(({
                    choiceLabel,
                    choiceIdentifier,
                }) => {
                    const nestedStep = getCorrectNestedStep(choiceIdentifier,
                        curentGroupSteps,
                        step,
                        isChanged)

                    return (
                        <Fragment key={`container-for-${choiceIdentifier}`}>
                            { renderItem(choiceIdentifier, choiceLabel)}
                            { choices[choiceIdentifier]
                            && getStepChoice(choiceIdentifier, step).goToStep
                                 && (
                                     <ProcessStepPage
                                         step={nestedStep}
                                         assetNumber={assetNumber}
                                         inputCallback={nestedInputCallback}
                                         curentGroupSteps={curentGroupSteps}
                                         isNested
                                         isSummaryView={isSummaryView}
                                         nestedInputFromUserInput={loadNestedInputFromChilds}
                                     />
                                 )}
                        </Fragment>
                    )
                })}
            </div>
        </StepPageLayout>
    )
}

MultipleChoice.propTypes = propTypes
MultipleChoice.defaultProps = defaultProps

export default MultipleChoice
