import React, {
    useCallback,
    useReducer,
    useMemo,
} from 'react'
import PropTypes from 'prop-types'

import TakeAcceptPicture from 'shared/TakeAcceptPicture'
import { createInputObject } from 'shared/utils/stepsUtils'
import { useEvidenceContext } from 'App/Root/ProcessPage/EvidenceContextProvider'
import { StepsUserInputPropNames } from 'App/Root/ProcessPage/evidenceConstants'
import { useAttachmentContext } from 'App/Root/ProcessPage/AttachmentContextProvider'
import useSupportNestedSteps from 'shared/NestedStep/useSupportNestedSteps'

import CommentPicture from './CommentPicture'
import PictureSummary from './PictureSummary'

import reducer, {
    actions,
    initState,
} from './SinglePictur.reducer'
import {
    createPicturesInputObject,
    createAttachmentFileName,
} from '../utils'

const createSinglePictureInputObject = (src, fileName, description) => {
    return createPicturesInputObject([{
        src,
        fileName,
        description,
    }], StepsUserInputPropNames.PICTURE)
}

const initializeState = ({
    step,
    attachments,
}) => {
    const result = { ...initState }

    if (step.isAnswered && step.userInput?.picture) {
        const input = step.userInput?.picture

        result.id = input.id
        result.addComment = true
        const search = attachments.attachments.find((value) => {
            return value.id === input.id
        })

        if (search) {
            result.comment = search.description
            result.src = search.file
        } else {
            result.comment = input.description || ''
            result.src = input.attachmentUrl
        }
    }
    return result
}
const propTypes = {
    step: PropTypes.shape({
        stepName: PropTypes.string.isRequired,
        isCommentable: PropTypes.bool.isRequired,
        isAnswered: PropTypes.bool,
        isRequired: PropTypes.bool,
        userInput: PropTypes.shape({ picture: PropTypes.shape({
            attachmentUrl: PropTypes.string.isRequired,
            description: PropTypes.string,
            id: PropTypes.number.isRequired,
            filename: PropTypes.string,
        }) }),
    }).isRequired,
    inputCallback: PropTypes.func,
    isNested: PropTypes.bool,
    isSummaryView: PropTypes.bool,
    nestedInputFromUserInput: PropTypes.func,
}
const defaultProps = {
    isNested: false,
    inputCallback: undefined,
    isSummaryView: false,
    nestedInputFromUserInput: undefined,
}

const inputConverter = (userInput) => {
    if (userInput?.picture) {
        const input = createSinglePictureInputObject(
            userInput.picture.attachmentUrl,
            userInput.picture.filename,
        )

        input.attachments[0].id = userInput.picture.id
        return input
    }
    return undefined
}

const SinglePicture = ({
    step,
    inputCallback,
    isNested,
    isSummaryView,
    nestedInputFromUserInput,
}) => {
    const { evidence } = useEvidenceContext()
    const { attachments } = useAttachmentContext()
    const [
        state,
        dispatch,
    ] = useReducer(reducer, {
        step,
        attachments,
    }, initializeState)
    const fileName = useMemo(() => {
        return createAttachmentFileName(evidence.evidenceId, step.stepName)
    }, [
        step.stepName,
        evidence.evidenceId,
    ])

    const onAcceptPicture = useCallback(({ src }) => {
        if (inputCallback) {
            inputCallback(createSinglePictureInputObject(src, fileName), step.stepName)
        }
        dispatch({
            type: actions.ACCEPT_PICTURE,
            src,
        })
    }, [
        inputCallback,
        step.stepName,
        fileName,
    ])

    const onCommentChange = useCallback((comment) => {
        if (inputCallback) {
            const inputValue = createSinglePictureInputObject(state.src, fileName, comment)

            inputCallback(inputValue, step.stepName)
        }
        dispatch({
            type: actions.ADD_COMMENT,
            comment,
        })
    }, [
        inputCallback,
        step.stepName,
        state.src,
        fileName,
    ])

    const onDelete = useCallback(() => {
        inputCallback(createInputObject(null, !step.isRequired), step.stepName)

        dispatch({ type: actions.REMOVE })
    }, [
        inputCallback,
        step.isRequired,
        step.stepName,
    ])

    useSupportNestedSteps({
        step,
        isSummaryView,
        isNested,
        nestedInputFromUserInput,
        inputConverter,
    })

    return (
        <>
            {!isSummaryView && (
                <>
                    { !state.addComment && (
                        <TakeAcceptPicture
                            step={step}
                            onAccept={onAcceptPicture}
                            showTakeButton
                            isNested={isNested}
                        />
                    )}
                    { state.addComment && (
                        <CommentPicture
                            image={state.src}
                            step={step}
                            comment={state.comment}
                            onChange={onCommentChange}
                            onDelete={onDelete}
                            isNested={isNested}
                            isSummaryView={isSummaryView}
                            id={state.id}
                        />
                    )}
                </>
            )}
            {isSummaryView && (
                <PictureSummary
                    step={step}
                    isNested={isNested}
                />
            )}
        </>
    )
}

SinglePicture.propTypes = propTypes
SinglePicture.defaultProps = defaultProps

export default SinglePicture
