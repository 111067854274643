import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
    Typography,
    TextField,
} from '@mui/material'

import StepPageLayout from 'shared/StepPageLayout'
import useStyles, {
    useTextFieldHelperTextStyles,
    useTextFieldStyles,
} from './TextStepField.style'

const propTypes = {
    step: PropTypes.shape({
        stepTitle: PropTypes.string.isRequired,
        helpText: PropTypes.string,
        isRequired: PropTypes.bool.isRequired,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    error: PropTypes.bool,
    helpText: PropTypes.string,
    isNested: PropTypes.bool,
    isSummaryView: PropTypes.bool,
}
const defaultProps = {
    error: false,
    helpText: null,
    isNested: false,
    isSummaryView: false,
}

const TextStepField = ({
    step,
    onChange,
    value,
    error,
    helpText,
    isNested,
    isSummaryView,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const helperTextStyles = useTextFieldHelperTextStyles()
    const textFieldStyles = useTextFieldStyles()

    const defaultHelpText = step.helpText ? t(`${step.helpText}`) : ''

    return (
        <StepPageLayout
            title={t(`processDefinition:${step.stepTitle}`)}
            isRequired={step.isRequired}
            isAnswered={!!value}
            isNested={isNested}
            isSummaryView={isSummaryView}
        >
            <div className={classes.form}>
                {!isSummaryView && (
                    <TextField
                        id="outlined-helperText"
                        value={value}
                        error={Boolean(error)}
                        fullWidth
                        multiline
                        size="small"
                        helperText={helpText || defaultHelpText}
                        FormHelperTextProps={{ classes: helperTextStyles }}
                        InputProps={{ classes: textFieldStyles }}
                        variant="outlined"
                        onChange={onChange}
                        disabled={isSummaryView}
                    />
                )}
                {isSummaryView && (
                    <Typography
                        variant="h5"
                        className={classes.summaryText}
                        paragraph={!value}
                    >
                        {value}
                    </Typography>
                )}
            </div>
        </StepPageLayout>
    )
}

TextStepField.propTypes = propTypes
TextStepField.defaultProps = defaultProps

export default TextStepField
