import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import ProcessStepPage from '../ProcessStepPage'

const propTypes = {
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            stepType: PropTypes.string.isRequired,
            stepName: PropTypes.string.isRequired,
        }),
    ).isRequired,
    assetNumber: PropTypes.string.isRequired,
    inputCallback: PropTypes.func,
    isSummaryView: PropTypes.bool,
    className: PropTypes.string,
    changedBy: PropTypes.string,
}
const defaultProps = {
    className: '',
    isSummaryView: false,
    inputCallback: undefined,
    changedBy: undefined,
}

const ProcessGroup = ({
    steps,
    assetNumber,
    inputCallback,
    className,
    isSummaryView,
    changedBy,
}) => {
    const mainLevelSteps = useMemo(() => {
        return steps.filter(({ isNestedStep }) => {
            return !isNestedStep
        })
    }, [steps])

    return (
        <div className={className}>
            {mainLevelSteps.map((step) => {
                return (
                    <ProcessStepPage
                        key={step.stepName}
                        step={step}
                        curentGroupSteps={steps}
                        assetNumber={assetNumber}
                        inputCallback={inputCallback}
                        isSummaryView={isSummaryView}
                        changedBy={changedBy}
                    />
                )
            })}
        </div>
    )
}

ProcessGroup.propTypes = propTypes
ProcessGroup.defaultProps = defaultProps

export default ProcessGroup
