import React, {
    useCallback,
    useState,
    useEffect,
    useMemo,
} from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Loading } from '@skycell-ag/shared-components'
import {
    Typography,
    Button,
} from '@mui/material'

import useStyles from './NFCTimeOutCheck.style'

const TIMEOUT_IN_SEC = 10
const propTypes = {
    callback: PropTypes.func.isRequired,
    notSupportCallBack: PropTypes.func.isRequired,
}

const NFCTimeOutCheck = ({
    callback,
    notSupportCallBack,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [nfcSupported] = useState(() => {
        return ('NDEFReader' in window)
    })
    const [
        nfcAllowed,
        setNfcAllowed,
    ] = useState(false)

    const [
        timeLeft,
        setTimeLeft,
    ] = useState(TIMEOUT_IN_SEC)

    useEffect(() => {
        if (nfcSupported) {
            const ndef = new window.NDEFReader()

            ndef.scan()
                .then(() => {
                    setNfcAllowed(true)
                })
                .catch(() => {
                    setNfcAllowed(false)
                })
        } else {
            notSupportCallBack()
        }
    }, [
        nfcSupported,
        notSupportCallBack,
    ])

    const onNFCheck = useCallback(async () => {
        const ndef = new window.NDEFReader()

        let isNFCTurned = true

        await ndef.scan().catch(() => {
            isNFCTurned = false
        })
        if (isNFCTurned) {
            setNfcAllowed(true)
        } else { setNfcAllowed(false) }
    }, [])

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (nfcSupported && nfcAllowed && timeLeft > 0) {
            const timer = setTimeout(() => {
                setTimeLeft(timeLeft - 1)
            }, 1000)

            return () => {
                return clearTimeout(timer)
            }
        }
        if (nfcSupported && nfcAllowed && timeLeft === 0) {
            callback()
        }
    }, [
        nfcSupported,
        nfcAllowed,
        timeLeft,
        callback,
    ])

    const timeLeftText = useMemo(() => {
        return t('NFC_SCAN_TIME_LEFT', { timeLeft })
    }, [
        timeLeft,
        t,
    ])

    if (!nfcSupported) {
        return (
            <Typography
                variant="h5"
                className={classes.stateText}
            >
                {t('NFC_NOT_SUPPORTED')}
            </Typography>
        )
    }

    return (
        <div>
            { nfcSupported && !nfcAllowed && (
                <div className={classes.loaderDiv}>
                    <Typography
                        variant="h5"
                        className={classes.centeredText}
                    >
                        {t('NFC_ENABLED_REQUEST')}
                    </Typography>
                    <div className={classes.actions}>
                        <Button
                            data-testid="enable-nfc-button"
                            className={classes.button}
                            onClick={onNFCheck}
                        >
                            {t('ENABLE_NFC')}
                        </Button>
                    </div>
                </div>
            )}
            { nfcSupported && nfcAllowed && timeLeft > 0 && (
                <div className={classes.loaderDiv}>
                    <Loading className={classes.timeOutLoader} />
                    <Typography
                        variant="h5"
                        className={classes.centeredText}
                    >
                        {timeLeftText}
                    </Typography>
                </div>
            )}
        </div>
    )
}

NFCTimeOutCheck.propTypes = propTypes

export default NFCTimeOutCheck
