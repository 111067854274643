import React, {
    useCallback,
    useMemo,
    useState,
} from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import isEmpty from 'lodash/isEmpty'

import ConfirmDialog from 'shared/ConfirmDialog'
import { useEvidenceContext } from 'App/Root/ProcessPage/EvidenceContextProvider'
import { PROCESS_ACTION_TYPES } from '../hooks/useProcess'

import useStyles from './ProcessActions.style'

const isNextStepAllowedForStep = (step, stepsInput) => {
    if (stepsInput[step.stepName] && stepsInput[step.stepName].invalid) {
        return false
    }
    if (step.isRequired && isEmpty(stepsInput[step.stepName])) {
        if (step.isAnswered) {
            return true
        }
        return false
    }
    return true
}

const propTypes = {
    actionCallback: PropTypes.func.isRequired,
    userInput: PropTypes.shape({
        invalid: PropTypes.bool,
        value: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    }).isRequired,
    currentGroup: PropTypes.shape({
        progressGroup: PropTypes.number,
        steps: PropTypes.arrayOf(PropTypes.shape({
            stepName: PropTypes.string.isRequired,
            isRequired: PropTypes.bool.isRequired,
        })),
    }).isRequired,
}

const ProcessActions = ({
    userInput,
    actionCallback,
    currentGroup,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { evidence } = useEvidenceContext()
    const [
        cancelConfirmOpen,
        setCancelConfirmOpen,
    ] = useState(false)

    const isNextStepAllowed = useMemo(() => {
        const mainSteps = currentGroup.steps.filter(({ isNestedStep }) => {
            return !isNestedStep
        })

        return mainSteps.reduce((allowed, step) => {
            return allowed && isNextStepAllowedForStep(step, userInput)
        }, true)
    }, [
        userInput,
        currentGroup.steps,
    ])
    const isCancelAllowed = useMemo(() => {
        return Boolean(evidence.evidenceId)
    }, [evidence.evidenceId])

    const isBackAllowed = useMemo(() => {
        return isCancelAllowed && currentGroup.progressGroup !== 1
    }, [
        isCancelAllowed,
        currentGroup.progressGroup,
    ])

    const onNextStep = useCallback(() => {
        actionCallback(PROCESS_ACTION_TYPES.NEXT)
    }, [actionCallback])

    const onOpenCancelConfirm = useCallback(() => {
        setCancelConfirmOpen(true)
    }, [])

    const onCancelConfirmForCancel = useCallback(() => {
        setCancelConfirmOpen(false)
    }, [])
    const onCancelProcess = useCallback(() => {
        setCancelConfirmOpen(false)
        actionCallback(PROCESS_ACTION_TYPES.CANCEL)
    }, [actionCallback])

    const onToSummary = useCallback(() => {
        actionCallback(PROCESS_ACTION_TYPES.SUMMARY)
    }, [actionCallback])

    const onBack = useCallback(() => {
        actionCallback(PROCESS_ACTION_TYPES.BACK)
    }, [actionCallback])

    return (
        <div className={classes.buttons}>
            <Button
                data-testid="back-button"
                className={classes.button}
                startIcon={<ArrowBackIcon />}
                disabled={Boolean(!isBackAllowed)}
                onClick={onBack}
            >
                {t('BACK')}
            </Button>
            <Button
                data-testid="cancel-button"
                className={classes.button}
                disabled={Boolean(!isCancelAllowed)}
                onClick={onOpenCancelConfirm}
            >
                {t('CANCEL')}
            </Button>
            <Button
                data-testid="summary-button"
                className={classes.button}
                disabled={Boolean(!isCancelAllowed)}
                onClick={onToSummary}
            >
                {t('SUMMARY')}
            </Button>
            <Button
                data-testid="next-button"
                className={classes.button}
                endIcon={<ArrowForwardIcon />}
                onClick={onNextStep}
                disabled={Boolean(!isNextStepAllowed)}
            >
                {t('SAVE')}
            </Button>
            <div>
                <ConfirmDialog
                    open={cancelConfirmOpen}
                    onConfirm={onCancelProcess}
                    onCancel={onCancelConfirmForCancel}
                />
            </div>
        </div>
    )
}

ProcessActions.propTypes = propTypes

export default ProcessActions
