export const DEFAULT_VIEWTYPES = {
    INIT: 'INIT',
    MANUAL: 'MANUAL',
    SCAN: 'SCAN',
    CONFIRMED: 'CONFIRMED',
    CONFIRMED_AND_SAVED: 'CONFIRMED_AND_SAVED',
    WRONG_QR: 'WRONG_QR',
    WRONG_LOGGER: 'WRONG_LOGGER',
    SET_LOGGER_NUMBER: 'SET_LOGGER_NUMBER',
}

export const ACTIONS = {
    START_SCAN: 'START_SCAN',
    START_MANUAL: 'START_MANUAL',
    SCANED: 'SCANED',
    RETRY: 'RETRY',
    SET_LOGGER_NUMBER: 'SET_LOGGER_NUMBER',
    WRONG_QR_CODE: 'WRONG_QR_CODE',
}

export const initState = {
    mode: DEFAULT_VIEWTYPES.INIT,
    qrCode: null,
    loggerNumber: null,
}

export const initializeState = ({ step }) => {
    const result = { ...initState }

    if (step.isAnswered && step.userInput?.loggerNumber) {
        result.loggerNumber = step.userInput?.loggerNumber
        result.mode = DEFAULT_VIEWTYPES.CONFIRMED_AND_SAVED
    }

    return result
}
