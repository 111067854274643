import { requestBusinessObjects } from '@skycell-ag/shared-components'
import { useJWTToken } from 'App/Auth'
import { useQuery } from 'react-query'

const getLogger = ({ queryKey: [
    _key, // eslint-disable-line no-unused-vars
    {
        qrCode,
        token,
    },
] }) => {
    const escapedQrCode = encodeURIComponent(qrCode)

    return requestBusinessObjects({
        method: 'GET',
        url: `skycenter/logger/loggerqrcode/${escapedQrCode}`,
        token,
    })
        .then((data) => {
            return data.data
        })
}

function useGetLoggerByQRCode(qrCode, enabled) {
    const token = useJWTToken()

    return useQuery({
        queryKey: [
            'getLoggerByQRCode',
            {
                qrCode,
                token,
            },
        ],
        queryFn: getLogger,
        enabled: Boolean(qrCode) && enabled,
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 1000,
    })
}

export default useGetLoggerByQRCode
