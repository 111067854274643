export const actions = {
    ACCEPT_PICTURE: 'ACCEPT_PICTURE',
    ADD_COMMENT: 'ADD_COMMENT',
    REPEAT: 'REPEAT',
    UPDATED_INPUT_CALLBACK: 'UPDATED_INPUT_CALLBACK',
    REMOVE: 'REMOVE',
}

export const initState = {
    takePicture: true,
    addComment: false,
    showSummary: false,
    nextPictureNumber: 1,
    needUpdateInputCallback: false,
    items: [],
}

const reducer = (state, action) => {
    switch (action.type) {
    case actions.ACCEPT_PICTURE:
    {
        return {
            ...state,
            takePicture: false,
            needUpdateInputCallback: true,
            showSummary: !action.isCommentable,
            nextPictureNumber: state.nextPictureNumber + 1,
            addComment: action.isCommentable,
            items: [
                ...state.items,
                {
                    number: state.nextPictureNumber,
                    src: action.src,
                },
            ],
        }
    }
    case actions.ADD_COMMENT: {
        const [last] = state.items.slice(-1)
        const other = state.items.slice(0, state.items.length - 1)

        return {
            ...state,
            addComment: false,
            takePicture: false,
            needUpdateInputCallback: true,
            showSummary: true,
            items: [
                ...other,
                {
                    ...last,
                    comment: action.comment,
                },
            ],
        }
    }
    case actions.REPEAT: {
        return {
            ...state,
            addComment: false,
            showSummary: false,
            takePicture: true,
        }
    }
    case actions.UPDATED_INPUT_CALLBACK: {
        return {
            ...state,
            needUpdateInputCallback: false,
        }
    }
    case actions.REMOVE: {
        const newItems = state.items.filter((item, index) => {
            return index !== action.index
        })

        const newState = {
            ...state,
            needUpdateInputCallback: true,
            items: newItems,
        }

        if (newItems.length === 0) {
            newState.takePicture = true
            newState.showSummary = false
        }
        return newState
    }
    default:
        throw new Error(`Not handled action type for MultiplePicture component = ${action.type}`)
    }
}

export default reducer
