import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import common from 'shared/styles'

const groupHeight = 10

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        root: {
            ...theme.custom.border,
            ...theme.custom.boxShadow,
            padding: '15px 30px',
        },
        main: common.flexJustityAlignCenter,
        group: {
            flexShrink: 1,
            background: theme.palette.secondary[200],
            minWidth: 50,
            height: groupHeight,
            borderRadius: 10,
            marginRight: 5,
            '&:last-child': { marginRight: 0 },
        },
        current: { height: groupHeight * 2 },
        fullyAnswered: { background: theme.palette.primary[50] },
        unAnsweredMandatory: { background: '#F0DEBF' },
    }
})

export default useStyles
