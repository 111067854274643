import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        form: {
            ...theme.custom.stepElementPadding,
            paddingRight: 0,
        },
        radioItemLabel: {
            fontSize: theme.typography.h3.fontSize,
            lineHeight: theme.typography.h3.lineHeight,
        },
    }
})

export default useStyles
