import isEmpty from 'lodash/isEmpty'
import {
    getStepChoice,
    getNestedStep,
} from 'shared/utils/stepsUtils'

export const getSelectedChoices = (newChoices) => {
    return Object.keys(newChoices).filter((c) => { return newChoices[c] })
}

export const inputFormat = (selectedChoices) => {
    if (selectedChoices.length === 0) {
        return undefined
    }
    return { selectedChoiceIdentifiers: selectedChoices }
}

export const createMultiChoiceInputObject = (value, valid, jumpToSummary) => {
    const result = isEmpty(value) ? {} : { value }

    if (jumpToSummary) {
        result.jumpToSummary = true
    }

    if (!valid) {
        return {
            ...result,
            invalid: true,
        }
    }
    return result
}

const createMultiChoiceInputObjectWithChilds = (value, child, valid, jumpToSummary) => {
    let result = isEmpty(value) ? {} : { value }

    if (!isEmpty(child)) {
        result = {
            ...result,
            nested: { ...child },
        }
    }

    if (jumpToSummary) {
        result.jumpToSummary = true
    }

    if (!valid) {
        return {
            ...result,
            invalid: true,
        }
    }
    return result
}

const hasAnyInvalidState = (nested) => {
    if (isEmpty(nested)) return false
    return Object.keys(nested).some((nestedStepName) => {
        return (!!nested[nestedStepName].invalid)
    })
}

const hasAnyJumpToSummary = (nested) => {
    if (isEmpty(nested)) return false
    return Object.keys(nested).some((nestedStepName) => {
        return (!!nested[nestedStepName].jumpToSummary)
    })
}

export const prepareNestedInputObject = (selectedChoices, nested, curentGroupSteps, step) => {
    const hasNestedInvalidInput = hasAnyInvalidState(nested)
    const hasChoiceWithRequiredNestedStepWithoutInput = selectedChoices.some((choiceId) => {
        const nestedStep = getNestedStep(choiceId, curentGroupSteps, step)

        if (!nestedStep || !nestedStep.isRequired) {
            return false
        }

        return (!nested || !nested[nestedStep.stepName])
    })

    const validbyRequired = (!step.isRequired || selectedChoices.length > 0)
    const validInput = validbyRequired
     && !hasNestedInvalidInput
     && !hasChoiceWithRequiredNestedStepWithoutInput

    const hasNestedJumpToSummary = hasAnyJumpToSummary(nested)
    const hasOwnJumpToSummary = selectedChoices.some((choiceId) => {
        return getStepChoice(choiceId, step).jumpToSummary
    })

    return createMultiChoiceInputObjectWithChilds(inputFormat(selectedChoices),
        nested,
        validInput,
        hasNestedJumpToSummary || hasOwnJumpToSummary)
}

const prepareInputObject = (selectedChoices, curentGroupSteps, step) => {
    const hasChoiceWithRequiredNestedStep = selectedChoices.some((choiceId) => {
        const nestedStep = getNestedStep(choiceId, curentGroupSteps, step)

        return nestedStep ? nestedStep.isRequired : false
    })
    const validbyRequired = (!step.isRequired || selectedChoices.length > 0)
    const validInput = validbyRequired && !hasChoiceWithRequiredNestedStep

    const needJumpToSummary = selectedChoices.some((choiceId) => {
        return getStepChoice(choiceId, step).jumpToSummary
    })

    return createMultiChoiceInputObject(inputFormat(selectedChoices), validInput, needJumpToSummary)
}

const updateNestedInput = (nestedInput, selectedChoices, curentGroupSteps, step) => {
    return selectedChoices.reduce((newInput, choiceId) => {
        const nestedStep = getNestedStep(choiceId, curentGroupSteps, step)

        if (!nestedStep || !nestedInput[nestedStep.stepName]) return newInput
        return {
            ...newInput,
            [nestedStep.stepName]: nestedInput[nestedStep.stepName],
        }
    }, {})
}

export const prepareInput = (selectedChoices, nestedInput, curentGroupSteps, step) => {
    let input

    let nested = nestedInput

    if (isEmpty(nested)) {
        input = prepareInputObject(selectedChoices, curentGroupSteps, step)
    } else {
        nested = updateNestedInput(nestedInput, selectedChoices, curentGroupSteps, step)
        input = prepareNestedInputObject(selectedChoices,
            nested,
            curentGroupSteps,
            step)
    }
    return {
        input,
        nested,
    }
}
