import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

import TwoRowErrorPage from 'shared/ErrorPages/TwoRowErrorPage'
import parseErrorMessage from 'shared/utils/parseErrorMessage'

import useStyles from './StyledErrorFallback.style'

const propTypes = {
    error: PropTypes.shape({ message: PropTypes.string }).isRequired,
    resetErrorBoundary: PropTypes.func.isRequired,
}

const StyledErrorFallback = ({
    error,
    resetErrorBoundary,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()

    return (
        <TwoRowErrorPage
            message={parseErrorMessage(error)}
            iconPath="skycenter/not_working_robot.svg"
            iconAlt="error"
        >
            <Button
                data-testid="ok-button"
                className={classes.button}
                onClick={resetErrorBoundary}
            >
                {t('OK')}
            </Button>
        </TwoRowErrorPage>
    )
}

StyledErrorFallback.propTypes = propTypes

export default StyledErrorFallback
