import React, {
    useEffect, useState,
} from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'

import StepPageLayout from 'shared/StepPageLayout'
import useSupportNestedSteps from 'shared/NestedStep/useSupportNestedSteps'
import { createInputObject } from 'shared/utils/stepsUtils'

import useStyles from './NoDamage.style'

const propTypes = {
    step: PropTypes.shape({
        stepName: PropTypes.string.isRequired,
        stepTitle: PropTypes.string.isRequired,
        isRequired: PropTypes.bool.isRequired,
        userInput: PropTypes.shape({ selectedChoiceIdentifiers:
                PropTypes.arrayOf(PropTypes.string) }),
        choices: PropTypes.arrayOf(PropTypes.shape({
            choiceIdentifier: PropTypes.string.isRequired,
            choiceLabel: PropTypes.string.isRequired,
            goToStep: PropTypes.string,
            jumpToSummary: PropTypes.bool,
        })).isRequired,
    }).isRequired,
    inputCallback: PropTypes.func,
    isNested: PropTypes.bool,
    isSummaryView: PropTypes.bool,
    nestedInputFromUserInput: PropTypes.func,
}
const defaultProps = {
    isNested: false,
    inputCallback: undefined,
    isSummaryView: false,
    nestedInputFromUserInput: undefined,
}

const createUserInput = () => {
    return createInputObject({ selectedChoiceIdentifiers: [] }, true)
}

const inputConverter = (userInput) => {
    if (userInput?.selectedChoiceIdentifiers?.length === 0) {
        return createUserInput()
    }
    return undefined
}

const NoDamage = ({
    step,
    inputCallback,
    isNested,
    isSummaryView,
    nestedInputFromUserInput,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const [
        needUpdateInputCallback,
        setNeedUpdateInputCallback,
    ] = useState(true)

    useSupportNestedSteps({
        step,
        isSummaryView,
        isNested,
        nestedInputFromUserInput,
        inputConverter,
    })

    useEffect(() => {
        if (needUpdateInputCallback
            && !isSummaryView
            && step.choices.length === 0 && inputCallback) {
            setNeedUpdateInputCallback(false)
            const input = createUserInput()

            inputCallback(input, step.stepName)
        }
    }, [
        step.choices.length,
        isSummaryView,
        step.stepName,
        inputCallback,
        needUpdateInputCallback,
    ])

    return (
        <StepPageLayout
            title={t(`processDefinition:${step.stepTitle}`)}
            isRequired={step.isRequired}
            isAnswered
            isNested={isNested}
            isSummaryView={isSummaryView}
        >
            <Typography
                variant="h3"
                className={classes.text}
            >
                {t('NO_DAMAGE_MESSAGE')}
            </Typography>
        </StepPageLayout>
    )
}

NoDamage.propTypes = propTypes
NoDamage.defaultProps = defaultProps

export default NoDamage
