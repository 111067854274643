import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Loading } from '@skycell-ag/shared-components'

import {
    useAllowed,
    useUserRoles,
} from 'App/Auth'
import authRoles from 'App/authRoles'
import NoAccessView from './NoAccessView'
import AccessContext from './AccessContext'

const propTypes = {
    children: PropTypes.element.isRequired,
    allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
}
const AccessProvider = ({
    children,
    allowedRoles,
}) => {
    const roles = useUserRoles()
    const allowed = useAllowed(...allowedRoles)
    const isAdmin = useMemo(() => {
        return (roles.indexOf(authRoles.ADMIN) > -1)
    }, [roles])
    const roleIsInitialized = useMemo(() => {
        return roles.length > 0
    }, [roles])

    if (!roleIsInitialized) {
        return (<Loading />)
    }

    return (
        <AccessContext.Provider value={{ isAdmin }}>
            {allowed ? children
                : (
                    <NoAccessView />
                )}
        </AccessContext.Provider>
    )
}

AccessProvider.propTypes = propTypes

export default AccessProvider
