import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import common from 'shared/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        root: {
            ...common.fullContainer,
            display: 'flex',
        },
        main: {
            ...theme.custom.dashBoardRoot,
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'column',
        },
        button: theme.custom.button,
        context: {
            width: '100%',
            paddingTop: 60,
            paddingBottom: 60,
            flexBasis: '100%',
        },
        buttons: {
            flexBasis: 60,
            flexGrow: 0,
            flexShrink: 0,
            alignItems: 'flex-end',
        },
        filter: {
            display: 'flex',
            width: '100%',
            flexBasis: 60,
            flexGrow: 0,
            flexShrink: 0,
        },
        selectedToggleButton: {
            '&&': {
                backgroundColor: 'transparent',
                borderBottom: `solid 2px ${theme.palette.primary.main}`,
                borderTop: `solid 2px ${theme.palette.primary.main}`,
            },
            '&&:hover': { backgroundColor: 'transparent' },
        },
        toggleButton: {
            '&&:hover': { backgroundColor: 'transparent' },
            '&&:disabled': {
                backgroundColor: theme.palette.secondary[200],
                opacity: 0.5,
            },
        },
    }
})

export default useStyles
