import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import common from 'shared/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        dialogRoot: theme.custom.boxShadowRadius,
        dialogContentRoot: {
            ...common.flexJustityAlignCenter,
            padding: '0px 30px',
        },
        dialogActionsRoot: common.dialogActionsRoot,
        button: theme.custom.button,
        icon: {
            width: 70,
            height: 80,
            paddingRight: 10,
        },
    }
})

export default useStyles
