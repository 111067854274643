import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        form: theme.custom.stepElementPadding,
        summaryText: { whiteSpace: 'pre-line' },
    }
})

export const useTextFieldHelperTextStyles = makeStyles(() => {
    const theme = useTheme()

    return { root: {
        color: theme.palette.primary[200],
        font: `normal normal 400 ${theme.typography.h4.fontSize}px/${theme.typography.h4.lineHeight} Roboto`,
    } }
})

export const useTextFieldStyles = makeStyles(() => {
    const theme = useTheme()

    return { root: {
        color: theme.palette.secondary[600],
        font: `normal normal 400 ${theme.typography.h3.fontSize}px/${theme.typography.h3.lineHeight} Roboto`,
    } }
})

export default useStyles
