import React, {
    useCallback,
    useReducer,
    useEffect,
    useMemo,
} from 'react'
import PropTypes from 'prop-types'
import isUndefined from 'lodash/isUndefined'

import { useEvidenceContext } from 'App/Root/ProcessPage/EvidenceContextProvider'
import { useAttachmentContext } from 'App/Root/ProcessPage/AttachmentContextProvider'
import { StepsUserInputPropNames } from 'App/Root/ProcessPage/evidenceConstants'
import TakeAcceptPicture from 'shared/TakeAcceptPicture'
import { createInputObject } from 'shared/utils/stepsUtils'

import useSupportNestedSteps from 'shared/NestedStep/useSupportNestedSteps'

import SkippableCommentPicture from './SkippableCommentPicture'
import SummaryPictures from './SummaryPictures'

import reducer, {
    initState,
    actions,
} from './MultiplePicture.reducer'
import {
    createPicturesInputObject,
    createAttachmentFileName,
} from '../utils'

const getFileNumber = (filename) => {
    const inputRegexp = /-(\d+).png/
    const found = filename.match(inputRegexp)

    return parseInt(found[1], 10)
}

const createMultiPicturesInputObject = (items, evidenceId, stepName, isRequired) => {
    if (items.length === 0) {
        return createInputObject(null, !isRequired)
    }
    return createPicturesInputObject(items.map((item) => {
        return {
            description: item.comment,
            src: item.src,
            id: item.id,
            fileName: isUndefined(item.filename) ? createAttachmentFileName(evidenceId,
                stepName,
                item.number) : item.filename,
        }
    }), StepsUserInputPropNames.PICTURE_MULTIPLE)
}

const propTypes = {
    step: PropTypes.shape({
        stepName: PropTypes.string.isRequired,
        isCommentable: PropTypes.bool.isRequired,
        isRequired: PropTypes.bool.isRequired,
        isAnswered: PropTypes.bool,
        // eslint-disable-next-line max-len
        userInput: PropTypes.shape({ picturesWithComments: PropTypes.arrayOf(PropTypes.shape({})) }),
    }).isRequired,
    inputCallback: PropTypes.func,
    isNested: PropTypes.bool,
    isSummaryView: PropTypes.bool,
    nestedInputFromUserInput: PropTypes.func,
}
const defaultProps = {
    isNested: false,
    inputCallback: undefined,
    isSummaryView: false,
    nestedInputFromUserInput: undefined,
}

const initializeState = ({
    step,
    attachments,
}) => {
    const result = {
        ...initState,
        items: [],
    }

    if (step.isAnswered) {
        result.showSummary = true
        result.takePicture = false

        if (step.userInput?.picturesWithComments) {
            result.items = step.userInput?.picturesWithComments.map((input) => {
                const search = attachments.attachments.find((value) => {
                    return value.id === input.id
                })

                if (search) {
                    return {
                        src: search.file,
                        comment: search.description,
                        filename: search.fileName,
                        id: input.id,
                    }
                }
                return {
                    src: input.attachmentUrl,
                    comment: input.description,
                    filename: input.filename,
                    id: input.id,
                }
            })
            const allFileNames = step.userInput?.picturesWithComments.map((file) => {
                return getFileNumber(file.filename)
            })

            result.nextPictureNumber = Math.max(...allFileNames) + 1
        }
    }
    return result
}

const inputConverter = (userInput) => {
    if (userInput?.picturesWithComments) {
        return {
            value: {},
            attachments: userInput.picturesWithComments.map((attachment) => {
                return {
                    description: attachment.description,
                    src: attachment.attachmentUrl,
                    id: attachment.id,
                    fileName: attachment.filename,
                }
            }),
            isPicture: true,
            inputPropName: 'picturesWithComments',
        }
    }
    return undefined
}

const MultiplePicture = ({
    step,
    inputCallback,
    isNested,
    isSummaryView,
    nestedInputFromUserInput,
}) => {
    const { evidence } = useEvidenceContext()
    const { attachments } = useAttachmentContext()
    const [
        state,
        dispatch,
    ] = useReducer(reducer, {
        step,
        attachments,
    }, initializeState)

    const currentItem = useMemo(() => {
        return state.items.length > 0 ? state.items[state.items.length - 1] : null
    }, [state.items])

    const onTakeAnotherPicture = useCallback(() => {
        if (inputCallback) {
            inputCallback(createInputObject(null, false), step.stepName)
        }
        dispatch({ type: actions.REPEAT })
    }, [
        inputCallback,
        step.stepName,
    ])

    const onAcceptPicture = useCallback(({ src }) => {
        dispatch({
            type: actions.ACCEPT_PICTURE,
            src,
            isCommentable: step.isCommentable,
        })
    }, [step.isCommentable])

    const onAddComment = useCallback((comment) => {
        dispatch({
            type: actions.ADD_COMMENT,
            comment,
        })
    }, [])

    const onSkipComment = useCallback(() => {
        dispatch({
            type: actions.ADD_COMMENT,
            comment: '',
        })
    }, [])

    const onDelete = useCallback((index) => {
        dispatch({
            type: actions.REMOVE,
            index,
        })
    }, [])

    useEffect(() => {
        if (state.needUpdateInputCallback) {
            dispatch({ type: actions.UPDATED_INPUT_CALLBACK })
            if (inputCallback) {
                const input = createMultiPicturesInputObject(
                    state.items,
                    evidence.evidenceId,
                    step.stepName,
                    step.isRequired,
                )

                inputCallback(input, step.stepName)
            }
        }
    }, [
        state.showSummary,
        state.items,
        inputCallback,
        step.stepName,
        step.isRequired,
        evidence.evidenceId,
        state.needUpdateInputCallback,
    ])
    useSupportNestedSteps({
        step,
        isSummaryView,
        isNested,
        nestedInputFromUserInput,
        inputConverter,
    })

    return (
        <>
            {!isSummaryView && (
                <>
                    { state.takePicture && (
                        <TakeAcceptPicture
                            step={step}
                            onAccept={onAcceptPicture}
                            showTakeButton={state.items.length === 0}
                            isNested={isNested}
                        />
                    )}
                    { state.addComment && (
                        <SkippableCommentPicture
                            image={currentItem.src}
                            onAdd={onAddComment}
                            onSkip={onSkipComment}
                            isRequired={step.isRequired}
                            isNested={isNested}
                        />
                    )}
                    { state.showSummary && (
                        <SummaryPictures
                            step={step}
                            items={state.items}
                            onTakeAnother={onTakeAnotherPicture}
                            onDelete={onDelete}
                            isNested={isNested}
                        />
                    )}
                </>
            )}
            {isSummaryView && (
                <SummaryPictures
                    step={step}
                    items={state.items}
                    isNested={isNested}
                    isSummaryView={isSummaryView}
                />
            )}
        </>
    )
}

MultiplePicture.propTypes = propTypes
MultiplePicture.defaultProps = defaultProps

export default MultiplePicture
