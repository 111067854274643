import React from 'react'
import { useTranslation } from 'react-i18next'
import ErrorViewWithLogout from 'shared/ErrorPages/TwoRowErrorPage/ErrorViewWithLogout'

const NoAccessView = () => {
    const { t } = useTranslation()

    return (
        <ErrorViewWithLogout message={t('ERROR_NO_ACCESS_RIGHTS')} />
    )
}

export default NoAccessView
