import { makeStyles } from '@mui/styles'
import common from 'shared/styles'

const useStyles = makeStyles(() => {
    return {
        container: {
            ...common.fullContainer,
            display: 'flex',
            flexDirection: 'column',
        },
        content: {
            ...common.flexJustityAlignCenter,
            flexDirection: 'column',
        },
    }
})

export default useStyles
