import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import common from 'shared/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        code: {
            ...theme.custom.stepElementPadding,
            fontWeight: 'bold',
        },
        text: theme.custom.stepText,
        actions: {
            ...common.flexJustityAlignCenter,
            padding: 0,
        },
        button: {
            ...theme.custom.button,
            width: 'auto',
            margin: '20px 25px',
            padding: '10px 20px',
        },
    }
})

export default useStyles
