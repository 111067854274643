import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import isEmpty from 'lodash/isEmpty'

import useStyles from './ProcessBar.style'

const propTypes = {
    groups: PropTypes.arrayOf(PropTypes.shape({
        progressGroup: PropTypes.number.isRequired,
        steps: PropTypes.arrayOf(PropTypes.shape({ isRequired: PropTypes.bool.isRequired }))
            .isRequired,
    })).isRequired,
    current: PropTypes.shape({ progressGroup: PropTypes.number }),
}

const defaultProps = { current: undefined }

const ProcessBar = ({
    groups,
    current,
}) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div className={classes.main}>
                {groups.map(({
                    progressGroup,
                    steps,
                }) => {
                    const mainSteps = steps.filter(({ isNestedStep }) => {
                        return !isNestedStep
                    })
                    const className = clsx(
                        classes.group,
                        {
                            [classes.current]: progressGroup === current.progressGroup,
                            [classes.fullyAnswered]: !mainSteps
                                .filter(({ isNestedStep }) => {
                                    return !isNestedStep
                                }).some((step) => {
                                    return isEmpty(step.userInput)
                                }),
                            [classes.unAnsweredMandatory]: mainSteps.some((step) => {
                                return isEmpty(step.userInput) && step.isRequired
                            }),
                        },
                    )

                    return (
                        <div
                            key={progressGroup}
                            className={className}
                        />
                    )
                })}
            </div>
        </div>
    )
}

ProcessBar.propTypes = propTypes
ProcessBar.defaultProps = defaultProps

export default ProcessBar
