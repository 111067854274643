import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import common from 'shared/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        root: {
            padding: 20,
            fontSize: theme.typography.h3.fontSize,
            lineHeight: theme.typography.h3.lineHeight,
            color: theme.palette.secondary[600],
        },
        button: {
            ...theme.custom.button,
            width: 'auto',
            marginTop: 20,
            padding: '0 30px',
        },
        actions: {
            ...common.flexJustityAlignCenter,
            padding: 0,
        },
    }
})

export default useStyles
