import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'

import useStyles from './StepPageLayout.styles'

const getAnsweredClassName = (mainClassName, isRequired, isAnswered) => {
    return clsx(
        mainClassName,
        {
            optional: !isAnswered && !isRequired,
            unAnsweredMandatory: !isAnswered && isRequired,
        },
    )
}

const propTypes = {
    title: PropTypes.string.isRequired,
    isRequired: PropTypes.bool.isRequired,
    isAnswered: PropTypes.bool.isRequired,
    isNested: PropTypes.bool,
    isSummaryView: PropTypes.bool,
    children: PropTypes.node.isRequired,
}
const defaultProps = {
    isNested: false,
    isSummaryView: false,
}

const StepPageLayout = ({
    title,
    isRequired,
    isAnswered,
    isNested,
    isSummaryView,
    children,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const titleClassName = useMemo(() => {
        return getAnsweredClassName(classes.title, isRequired, isAnswered)
    }, [
        classes.title,
        isRequired,
        isAnswered,
    ])

    const childBorderClassName = useMemo(() => {
        return getAnsweredClassName(classes.childborder, isRequired, isAnswered)
    }, [
        classes.childborder,
        isRequired,
        isAnswered,
    ])
    const mainClassName = useMemo(() => {
        return clsx(
            classes.main,
            { nested: isNested },
        )
    }, [
        isNested,
        classes.main,
    ])

    const stateMessage = useMemo(() => {
        if (!isAnswered) {
            if (isRequired) { return 'TO_BE_ANSWERED' }
            return 'NOT_ANSWERED'
        }
        return 'COMPLETED'
    }, [
        isAnswered,
        isRequired,
    ])

    return (
        <div className={mainClassName}>
            <div className={titleClassName}>
                <Typography
                    variant="h3"
                    className={classes.titleText}
                >
                    {title}
                </Typography>
                {isSummaryView && (
                    <Typography
                        variant="h5"
                        className={classes.stateText}
                    >
                        {t(stateMessage)}
                    </Typography>
                )}
            </div>
            <div className={childBorderClassName}>
                {children}
            </div>
        </div>
    )
}

StepPageLayout.propTypes = propTypes
StepPageLayout.defaultProps = defaultProps

export default StepPageLayout
