import React, {
    useState,
    useCallback,
    useEffect,
} from 'react'
import PropTypes from 'prop-types'
import { Loading } from '@skycell-ag/shared-components'
import { Dialog } from '@mui/material'

import { useAsset } from 'App/Root/hooks'
import AssetScan from './AssetScan'
import NoAsset from './NoAsset'

import useStyles from './ScanDialog.style'
import ManualAssetNumber from './ManualAssetNumber'

const propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    item: PropTypes.shape({ number: PropTypes.string }),
}

const defaultProps = { item: null }

const ScanDialog = ({
    onClose,
    open,
    item,
}) => {
    const classes = useStyles()

    const [
        assetNumber,
        setAssetNumber,
    ] = useState(null)
    const [
        manualInput,
        setManualInput,
    ] = useState(false)

    const {
        data: assetData,
        isLoading: isAssetLoading,
        error: assetError,
    } = useAsset(assetNumber)

    useEffect(() => {
        if (assetNumber && assetData) {
            onClose({
                success: true,
                value: assetNumber,
            })
            setAssetNumber(null)
        }
    }, [
        assetData,
        assetNumber,
        onClose,
    ])

    const onRetry = useCallback(() => {
        setAssetNumber(null)
    }, [])

    const onScan = useCallback(({
        value,
        close,
    }) => {
        setAssetNumber(value)
        if (close) {
            setManualInput(false)
            onClose({ success: false })
        }
    }, [onClose])

    return (
        <Dialog
            id="barcode-scan-dialog"
            keepMounted
            fullWidth
            maxWidth="lg"
            aria-labelledby="barcode-scan-dialog"
            open={open}
            classes={{ root: classes.dialogRoot }}
        >
            {open && (
                <>
                    {!manualInput && (
                        <>
                            {!assetError && (
                                <AssetScan
                                    onScan={onScan}
                                    assetNumber={item?.number}
                                    switchToManual={setManualInput}
                                />
                            )}
                            {assetError && (<NoAsset onRetry={onRetry} />)}
                        </>
                    )}
                    {manualInput && (
                        <ManualAssetNumber
                            onChange={onScan}
                        />
                    )}
                    {isAssetLoading && (<Loading />)}
                </>
            )}
        </Dialog>
    )
}

ScanDialog.propTypes = propTypes
ScanDialog.defaultProps = defaultProps

export default ScanDialog
