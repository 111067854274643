import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        tempContainer: { display: 'inline-flex' },
        numberLineLetters: { lineHeight: theme.typography.h2.lineHeight },
        icon: {
            height: theme.typography.h2.lineHeight,
            padding: '0px 7px',
        },
        tempImg: {
            width: 20,
            height: 36,
        },
    }
})

export default useStyles
