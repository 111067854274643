import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        noPicture: { },
        text: {
            ...theme.custom.stepText,
            padding: '10px 0px',
        },
    }
})

export default useStyles
