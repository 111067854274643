/* eslint-disable complexity */
import React, {
    useCallback,
    useMemo,
    useRef,
    useState,
} from 'react'
import PropTypes from 'prop-types'
import PhotoCamera from '@mui/icons-material/PhotoCamera'
import FlashOnIcon from '@mui/icons-material/FlashOn'
import FlashOffIcon from '@mui/icons-material/FlashOff'
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid'
import {
    Dialog,
    IconButton,
} from '@mui/material'
import Webcam from 'react-webcam'
import {
    Loading,
    useEnumerateDevices,
} from '@skycell-ag/shared-components'

import timeout from 'shared/utils/timeout'
import useBeforeUnload from 'shared/hooks/useBeforeUnload/useBeforeUnload'

import useCameraResolutionContrains from './useCameraResolutionContrains'
import useStyles from './CameraDialog.style'

const CAMERA_FLASH_ADJUSTMENT_TIMEOUT = 500

const propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
}

const CameraDialog = ({
    onClose,
    open,
}) => {
    const classes = useStyles()
    const device = useEnumerateDevices()
    const input = useRef(null)
    const trackRef = useRef(null)

    const cleanUpHandler = useCallback(() => {
        const video = document.querySelector('video')

        if (video) {
            const mediaStream = video.srcObject

            if (mediaStream) {
                mediaStream.getTracks().forEach((track) => { return track.stop() })
            }
        }
    }, [])

    useBeforeUnload(cleanUpHandler)

    const { videoResolutionConstrains } = useCameraResolutionContrains()

    const [
        loaded,
        setLoaded,
    ] = useState(false)

    const [
        torchAvailable,
        setTorchAvailable,
    ] = useState(false)

    const [
        hasFlash,
        setHasFlash,
    ] = useState(false)

    const [
        useBackCamera,
        setUseBackCamera,
    ] = useState(true)

    const [
        error,
        setError,
    ] = useState(null)

    const videoConstraints = useMemo(() => {
        const constraints = {
            audio: false,
            frameRate: { max: 30 },
            ...videoResolutionConstrains.stream,
        }

        if (device && device.facingMode) {
            constraints.facingMode = device.facingMode
        }
        if (!useBackCamera) {
            constraints.facingMode = { exact: 'user' }
        }

        return constraints
    }, [
        device,
        useBackCamera,
        videoResolutionConstrains.stream,
    ])

    const changeCameraFlashSetting = useCallback((flashOn) => {
        try {
            trackRef.current?.applyConstraints({ advanced: [{ torch: flashOn }] })
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log('Error happens during switching video track applyConstraints =', err)
            setError(err)
        }
    }, [])

    const onCaptureImage = useCallback(async () => {
        if (hasFlash) {
            changeCameraFlashSetting(hasFlash)
            await timeout(CAMERA_FLASH_ADJUSTMENT_TIMEOUT)
        }
        const imageSrc = input.current.getScreenshot(videoResolutionConstrains.screenshot)

        if (hasFlash) {
            changeCameraFlashSetting(false)
        }
        setLoaded(false)
        onClose(imageSrc)
    }, [
        onClose,
        hasFlash,
        changeCameraFlashSetting,
        videoResolutionConstrains.screenshot,
    ])

    const onUserMedia = useCallback((stream) => {
        const [track] = stream.getVideoTracks()

        setTorchAvailable(track.getCapabilities().torch)
        trackRef.current = track
        setLoaded(true)
    }, [])

    const onUserMediaError = useCallback((userMediaError) => {
        // eslint-disable-next-line no-console
        console.log('Error happens during switching video track =', userMediaError)
        setError(userMediaError)
    }, [])

    const onSwithFlash = useCallback(() => {
        setHasFlash(!hasFlash)
    }, [hasFlash])

    const onFlipCamera = useCallback(() => {
        setUseBackCamera(!useBackCamera)
    }, [useBackCamera])

    const hasMultipleCamera = useMemo(() => {
        return !!device.facingMode
    }, [device])

    if (error) {
        throw error
    }

    return (
        <Dialog
            fullScreen
            open={open}
        >
            {open && (
                <div className={classes.root}>
                    { !loaded && (<Loading />) }
                    <div className={classes.video}>
                        <Webcam
                            audio={false}
                            ref={input}
                            screenshotFormat="image/png"
                            videoConstraints={videoConstraints}
                            screenshotQuality={1}
                            onUserMedia={onUserMedia}
                            onUserMediaError={onUserMediaError}
                            className={classes.camera}
                        />
                    </div>
                    <div className={classes.actions}>
                        {torchAvailable && (
                            <IconButton
                                disabled={!loaded}
                                data-testid="turn-torch-button"
                                color="primary"
                                size="medium"
                                aria-label="upload picture"
                                className={classes.flash}
                                onClick={onSwithFlash}
                            >
                                {hasFlash
                                    ? <FlashOnIcon fontSize="medium" /> : <FlashOffIcon fontSize="medium" />}
                            </IconButton>
                        )}
                        { hasMultipleCamera && (
                            <IconButton
                                disabled={!loaded}
                                data-testid="capture-flip-button"
                                color="primary"
                                size="medium"
                                aria-label="upload picture"
                                onClick={onFlipCamera}
                                className={classes.flash}
                            >
                                <FlipCameraAndroidIcon fontSize="medium" />
                            </IconButton>
                        )}
                        <IconButton
                            disabled={!loaded}
                            data-testid="capture-image-button"
                            color="primary"
                            size="medium"
                            aria-label="upload picture"
                            onClick={onCaptureImage}
                            className={classes.cameraButton}
                        >
                            <PhotoCamera fontSize="large" />
                        </IconButton>
                    </div>
                </div>
            )}
        </Dialog>
    )
}

CameraDialog.propTypes = propTypes

export default CameraDialog
