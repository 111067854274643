const styles = {
    flexJustityAlignCenter: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    dashBoardRoot: { padding: '30px 90px' },
    fullContainer: {
        width: '100%',
        height: '100%',
    },
    dialogActionsRoot: {
        justifyContent: 'center',
        paddingTop: 20,
        paddingBottom: 20,
    },
    stepsPictureButton: (theme) => {
        return {
            ...theme.custom.button,
            margin: '0 30px',
            width: 'auto',
            padding: '0 40px',
        }
    },
}

export default styles
