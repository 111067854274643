import React, {
    useReducer,
    useCallback,
    useEffect,
} from 'react'
import PropTypes from 'prop-types'

import CameraDialog from './CameraDialog'
import TakePicture from './TakePicture'
import AcceptPicture from './AcceptPicture'
import reducer, {
    actions,
    initState,
} from './reducer'

const propTypes = {
    step: PropTypes.shape({
        stepTitle: PropTypes.string.isRequired,
        isRequired: PropTypes.bool.isRequired,
    }).isRequired,
    onAccept: PropTypes.func.isRequired,
    showTakeButton: PropTypes.bool.isRequired,
    isNested: PropTypes.bool,
}
const defaultProps = { isNested: false }

const TakeAcceptPicture = ({
    step,
    onAccept,
    showTakeButton,
    isNested,
}) => {
    const [
        state,
        dispatch,
    ] = useReducer(reducer, initState)

    const onStart = useCallback(() => {
        dispatch({ type: actions.OPEN_CAMERA })
    }, [])

    const onCameraClose = useCallback((src) => {
        dispatch({
            type: actions.CAPTURE_PICTURE,
            src,
        })
    }, [])

    const onAcceptPicture = useCallback(() => {
        dispatch({ type: actions.ACCEPT_PICTURE })
        onAccept({ src: state.src })
    }, [
        onAccept,
        state.src,
    ])

    useEffect(() => {
        if (!showTakeButton) {
            dispatch({ type: actions.OPEN_CAMERA })
        }
    }, [showTakeButton])

    return (
        <>
            { state.init && showTakeButton && (
                <TakePicture
                    step={step}
                    onStart={onStart}
                    isNested={isNested}
                />
            )}
            { state.acceptPicture && (
                <AcceptPicture
                    image={state.src}
                    isRequired={step.isRequired}
                    onAccept={onAcceptPicture}
                    onReject={onStart}
                    isNested={isNested}
                />
            )}
            <div>
                <CameraDialog
                    open={state.openCamera}
                    onClose={onCameraClose}
                />
            </div>
        </>
    )
}

TakeAcceptPicture.propTypes = propTypes
TakeAcceptPicture.defaultProps = defaultProps

export default TakeAcceptPicture
