import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
    Typography,
    Button,
    DialogContent,
} from '@mui/material'

import getGlobalAssetUrl from 'shared/utils/getGlobalAssetUrl'
import ROUTE_KEYS from 'shared/utils/routeKeys'
import useStyles from './NoAsset.style'

const propTypes = { onRetry: PropTypes.func.isRequired }

const NoAsset = ({ onRetry }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const history = useHistory()

    const onCancel = useCallback(() => {
        history.push(ROUTE_KEYS.MAIN)
    }, [history])

    return (
        <>
            <DialogContent classes={{ root: classes.dialogContentRoot }}>
                <div>
                    <img
                        className={classes.icon}
                        alt="logo"
                        src={getGlobalAssetUrl('skycenter/error_sorry.svg')}
                    />
                </div>
                <div className={classes.content}>
                    <div className={classes.text}>
                        <Typography
                            variant="h3"
                            className={classes.message}
                        >
                            {t('NO_SKYCELL_ASSET')}
                        </Typography>
                    </div>
                    <div
                        classes={classes.actions}
                    >
                        <Button
                            data-testid="cancel-button"
                            className={classes.button}
                            onClick={onCancel}
                        >
                            {t('CANCEL')}
                        </Button>
                        <Button
                            data-testid="retry-button"
                            className={classes.button}
                            onClick={onRetry}
                        >
                            {t('RETRY')}
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </>
    )
}

NoAsset.propTypes = propTypes

export default NoAsset
