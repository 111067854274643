import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import ExtendedRadio from 'shared/ExtendedRadio'
import StepPageLayout from 'shared/StepPageLayout'
import NestedStep, { useNestedStep } from 'shared/NestedStep'
import SummaryChoicesList from 'shared/SummaryChoicesList'
import { StepSingleSelectPropTypes } from 'shared/utils/stepsUtils'

import useStyles from './SingleChoice.style'

const propTypes = {
    step: StepSingleSelectPropTypes.isRequired,
    inputCallback: PropTypes.func,
    assetNumber: PropTypes.string.isRequired,
    curentGroupSteps: PropTypes.arrayOf(
        PropTypes.shape({ stepName: PropTypes.string.isRequired }),
    ).isRequired,
    isNested: PropTypes.bool,
    isSummaryView: PropTypes.bool,
    nestedInputFromUserInput: PropTypes.func,
}

const defaultProps = {
    isNested: false,
    inputCallback: undefined,
    isSummaryView: false,
    nestedInputFromUserInput: undefined,
}

const SingleChoice = ({
    step,
    inputCallback,
    assetNumber,
    curentGroupSteps,
    isNested,
    isSummaryView,
    nestedInputFromUserInput,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const {
        choice,
        isChanged,
        onChange,
    } = useNestedStep({
        step,
        curentGroupSteps,
        inputCallback,
        isNested,
        isSummaryView,
        nestedInputFromUserInput,
    })

    const radioOptions = useMemo(() => {
        return step.choices.map(({
            choiceIdentifier,
            choiceLabel,
        }) => {
            return {
                value: choiceIdentifier,
                label: t(`processDefinition:${choiceLabel}`),
            }
        })
    }, [
        step.choices,
        t,
    ])

    const radioRender = () => {
        return (
            <ExtendedRadio
                onChange={onChange}
                value={choice}
                options={radioOptions}
                showInColumns
                classes={{ radioItemLabel: classes.radioItemLabel }}
            >
                <NestedStep
                    step={step}
                    choice={choice}
                    assetNumber={assetNumber}
                    inputCallback={inputCallback}
                    curentGroupSteps={curentGroupSteps}
                    isChanged={isChanged}
                    nestedInputFromUserInput={nestedInputFromUserInput}
                />
            </ExtendedRadio>
        )
    }

    return (
        <StepPageLayout
            title={t(`processDefinition:${step.stepTitle}`)}
            isRequired={step.isRequired}
            isAnswered={!!choice}
            isNested={isNested}
            isSummaryView={isSummaryView}
        >
            <div className={classes.form}>
                {isSummaryView ? (
                    <SummaryChoicesList
                        items={step.choices}
                        choice={choice}
                        isAnswered={!!choice}
                        step={step}
                        assetNumber={assetNumber}
                        curentGroupSteps={curentGroupSteps}
                        isSummaryView={isSummaryView}
                    />
                ) : radioRender() }
            </div>
        </StepPageLayout>
    )
}

SingleChoice.propTypes = propTypes
SingleChoice.defaultProps = defaultProps

export default SingleChoice
