import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
    FormControlLabel,
    RadioGroup,
    Radio as MaterialRadio,
    Typography,
} from '@mui/material'

import useStyles from './ExtendedRadio.style'

const propTypes = {
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
    })),
    name: PropTypes.string,
    value: PropTypes.string,
    compact: PropTypes.bool,
    small: PropTypes.bool,
    className: PropTypes.string,
    showInColumns: PropTypes.bool,
    children: PropTypes.node.isRequired,
}

const defaultProps = {
    disabled: false,
    title: '',
    options: [],
    value: '',
    name: 'labelType',
    compact: false,
    small: false,
    className: '',
    showInColumns: false,
}

const ExtendedRadio = ({
    onChange,
    disabled,
    title,
    options,
    name,
    value,
    compact,
    small,
    className,
    showInColumns,
    children,
    ...rest
}) => {
    const classes = useStyles(rest)

    const handleOnChange = useCallback((event) => {
        onChange(event.target.value, event)
    }, [onChange])

    return (
        <>
            {title && (
                <Typography
                    className={classes.label}
                    variant="caption"
                >
                    {title}
                </Typography>
            )}
            <RadioGroup
                aria-label="labelType"
                name={name}
                value={value}
                className={clsx({
                    [classes.radio]: true,
                    [className]: true,
                    [classes.showInColumns]: showInColumns,
                })}
                onChange={handleOnChange}
                disabled={disabled}
                data-testid={`radio-${name}`}
            >
                {options.map((radio) => {
                    return (
                        <div key={`div-radio-${radio.value}`}>
                            <FormControlLabel
                                key={`radio-${radio.value}`}
                                value={radio.value}
                                className={clsx({
                                    [classes.formControlStyle]: true,
                                    [classes.radioSmall]: small,
                                })}
                                classes={{ label: classes.radioItemLabel }}
                                control={(
                                    <MaterialRadio
                                        color="primary"
                                        classes={{ root: compact && classes.radioRoot }}
                                    />
                                )}
                                data-value={radio.value}
                                label={radio.label}
                                disabled={disabled}
                            />
                            {radio.value === value && children}
                        </div>
                    )
                })}
            </RadioGroup>
        </>
    )
}

ExtendedRadio.propTypes = propTypes
ExtendedRadio.defaultProps = defaultProps

export default ExtendedRadio
