import { useMemo } from 'react'
import compact from 'lodash/compact'

import useContactsByEmail from 'App/Root/hooks/useContactsByEmail'

const useAssetsContacts = (containers) => {
    const userEmailsUnique = useMemo(() => {
        const emails = compact(containers.map((asset) => {
            return asset.processChangedBy
        }))

        return [...new Set(emails)]
    }, [containers])

    const {
        isLoading,
        data,
        error,
    } = useContactsByEmail(userEmailsUnique)

    return {
        isLoading,
        error,
        data,
    }
}

export default useAssetsContacts
