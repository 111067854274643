import React, {
    useCallback,
    useMemo,
    useState,
} from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
    Button,
    Typography,
} from '@mui/material'
import {
    dateToStr,
    strToDate,
} from '@skycell-ag/shared-components'

import EmptyStepContent from 'shared/EmptyStepContent'
import { createInputObject } from 'shared/utils/stepsUtils'
import useSupportNestedSteps from 'shared/NestedStep/useSupportNestedSteps'
import StepPageLayout from 'shared/StepPageLayout'
import useContactsByEmail from 'App/Root/hooks/useContactsByEmail'

import useStyles from './ConfirmStep.style'

const propTypes = {
    step: PropTypes.shape({
        stepName: PropTypes.string.isRequired,
        stepTitle: PropTypes.string.isRequired,
        userInput: PropTypes.shape({
            confirmed: PropTypes.bool,
            confirmationOnZoned: PropTypes.string,
        }),
        isRequired: PropTypes.bool,
        isAnswered: PropTypes.bool,
    }).isRequired,
    inputCallback: PropTypes.func,
    isNested: PropTypes.bool,
    isSummaryView: PropTypes.bool,
    nestedInputFromUserInput: PropTypes.func,
    changedBy: PropTypes.string,
}
const defaultProps = {
    isNested: false,
    isSummaryView: false,
    inputCallback: undefined,
    nestedInputFromUserInput: undefined,
    changedBy: undefined,
}

const createUserInput = (confirmationOnZoned) => {
    return createInputObject({
        confirmed: true,
        confirmationOnZoned,
    }, true)
}
const inputConverter = (userInput) => {
    if (userInput?.confirmationOnZoned) {
        return createUserInput(userInput.confirmationOnZoned)
    }
    return undefined
}

const ConfirmStep = ({
    step,
    inputCallback,
    isNested,
    isSummaryView,
    nestedInputFromUserInput,
    changedBy,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [
        confirmationDate,
        setConfirmationDate,
    ] = useState(step.userInput?.confirmationOnZoned)

    useSupportNestedSteps({
        step,
        isSummaryView,
        isNested,
        nestedInputFromUserInput,
        inputConverter,
    })

    const {
        data: emailsData,
        error: emailsError,
    } = useContactsByEmail([changedBy])

    if (emailsError) {
        throw emailsError
    }
    const changeByName = useMemo(() => {
        return emailsData?.[changedBy]
    }, [
        changedBy,
        emailsData,
    ])

    const answeredText = useMemo(() => {
        if (confirmationDate) {
            const date = dateToStr(strToDate(confirmationDate), 'DD.MM.YYYY')

            return t('ANSWERED_BY_ON', {
                username: `${changeByName}`,
                date,
            })
        }
        return null
    }, [
        t,
        confirmationDate,
        changeByName,
    ])

    const onConfirm = useCallback(() => {
        const confirmationOnZoned = dateToStr(new Date())

        inputCallback(createUserInput(confirmationOnZoned), step.stepName)
        setConfirmationDate(confirmationOnZoned)
    }, [
        step.stepName,
        inputCallback,
    ])

    const isAnswered = useMemo(() => {
        return step.isAnswered || Boolean(confirmationDate)
    }, [
        step.isAnswered,
        confirmationDate,
    ])

    return (
        <StepPageLayout
            title={t(`processDefinition:${step.stepTitle}`)}
            isRequired={step.isRequired}
            isAnswered={isAnswered}
            isNested={isNested}
            isSummaryView={isSummaryView}
        >
            { !isSummaryView && (
                <div className={classes.action}>
                    <Button
                        data-testid="confirm-button"
                        className={classes.button}
                        onClick={onConfirm}
                        disabled={Boolean(isAnswered)}
                    >
                        {isAnswered ? t('CONFIRMED') : t('CONFIRM')}
                    </Button>
                </div>
            )}
            { isAnswered && (
                <div>
                    <Typography
                        variant="h3"
                        className={classes.text}
                    >
                        {answeredText}
                    </Typography>
                </div>
            )}
            {isSummaryView && !isAnswered && (
                <EmptyStepContent
                    classes={{ text: classes.notConfirmed }}
                    title={t('NOT_CONFIRMED_STEP')}
                />
            )}
        </StepPageLayout>
    )
}

ConfirmStep.propTypes = propTypes
ConfirmStep.defaultProps = defaultProps

export default ConfirmStep
