import React, {
    useCallback,
    useState,
} from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { noop } from 'lodash'
import {
    Button,
    TextField,
} from '@mui/material'

import { useTextFieldStyles } from 'shared/TextStepField/TextStepField.style'
import useStyles from './ManualInput.style'

const propTypes = {
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    showCancel: PropTypes.bool,
    showSubmit: PropTypes.bool,
    validationRegEx: PropTypes.string,
    validationErrorMessage: PropTypes.string,
}

const defaultProps = {
    onChange: noop,
    showCancel: true,
    showSubmit: false,
    onSubmit: undefined,
    onCancel: undefined,
    validationRegEx: undefined,
    validationErrorMessage: undefined,
}

const ManualInput = ({
    onChange,
    onSubmit,
    onCancel,
    showCancel,
    showSubmit,
    validationRegEx,
    validationErrorMessage,
    ...rest
}) => {
    const classes = useStyles(rest)
    const textFieldStyles = useTextFieldStyles()
    const { t } = useTranslation()

    const [
        value,
        setValue,
    ] = useState('')

    const [
        error,
        setError,
    ] = useState(false)

    const validate = useCallback((v) => {
        const inputRegexp = new RegExp(validationRegEx)

        if (v.match(inputRegexp)) {
            return true
        }
        return false
    }, [validationRegEx])

    const onInputChange = useCallback((event) => {
        const newInputValue = event.target.value

        if (validationRegEx && !validate(newInputValue)) {
            setError(true)
            onChange({ value: null })
        } else {
            setError(false)
            onChange({ value: newInputValue })
        }
        setValue(newInputValue)
    }, [
        onChange,
        validate,
        validationRegEx,
    ])

    const handleCancel = useCallback(() => {
        onChange(
            {
                value: null,
                close: true,
            },
        )
        if (onCancel) {
            onCancel()
        }
    }, [
        onChange,
        onCancel,
    ])

    const handleSubmit = useCallback(() => {
        onSubmit(value)
    }, [
        value,
        onSubmit,
    ])

    const errorText = error ? validationErrorMessage : null

    return (
        <>
            <div className={classes.contextContainer}>
                <TextField
                    id="outlined-helperText"
                    value={value}
                    fullWidth
                    size="small"
                    InputProps={{ classes: textFieldStyles }}
                    variant="outlined"
                    onChange={onInputChange}
                    error={Boolean(error)}
                    helperText={errorText}
                />
            </div>
            <div className={classes.actions}>

                {
                    showSubmit && (
                        <Button
                            className={classes.button}
                            data-testid="ok-button"
                            onClick={handleSubmit}
                            disabled={value === '' || error}
                        >
                            {t('OK')}
                        </Button>
                    )
                }
                { showCancel && (
                    <Button
                        data-testid="cancel-button"
                        className={classes.button}
                        onClick={handleCancel}
                    >
                        {t('CANCEL')}
                    </Button>
                )}
            </div>

        </>
    )
}

ManualInput.propTypes = propTypes
ManualInput.defaultProps = defaultProps

export default ManualInput
