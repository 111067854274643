import { makeStyles } from '@mui/styles'
import common from 'shared/styles'

const useStyles = makeStyles(() => {
    return { root: {
        ...common.flexJustityAlignCenter,
        padding: 0,
        height: '100%',
        width: '100%',
    } }
})

export default useStyles
