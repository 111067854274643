import { makeStyles } from '@mui/styles'
import common from 'shared/styles'

const useStyles = makeStyles(() => {
    return {
        root: {
            ...common.flexJustityAlignCenter,
            padding: 0,
            height: '100%',
            overflow: 'hidden',
        },
        video: {
            padding: 0,
            flexGrow: 1,
            height: '100%',
        },
        camera: {
            width: '100%',
            height: '100%',
            objectFit: 'fill',
        },
        actions: {
            ...common.flexJustityAlignCenter,
            flexDirection: 'column',
            padding: 0,
            flexGrow: 0,
            width: '10%',
        },
        flash: {
            marginBottom: 60,
            padding: 20,
        },
        cameraButton: { padding: 20 },
    }
})

export default useStyles
