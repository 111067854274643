import React, {
    useState,
    useMemo,
    useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { useEvidenceContext } from 'App/Root/ProcessPage/EvidenceContextProvider'
import useEvidence from 'App/Root/ProcessPage/hooks/useEvidence'

import { createInputObject } from 'shared/utils/stepsUtils'
import StepPageLayout from 'shared/StepPageLayout'

import IATAScanSummary from './IATAScanSummary'

import IATAScanInput from './IATAScanInput'

const propTypes = {
    step: PropTypes.shape({
        stepName: PropTypes.string.isRequired,
        stepTitle: PropTypes.string.isRequired,
        isRequired: PropTypes.bool.isRequired,
        isAnswered: PropTypes.bool,
        userInput: PropTypes.shape({ tempRange: PropTypes.string }),
    }).isRequired,
    inputCallback: PropTypes.func,
    isSummaryView: PropTypes.bool,
    isNested: PropTypes.bool,
}

const defaultProps = {
    isSummaryView: false,
    isNested: false,
    inputCallback: undefined,
}

const IATAScan = ({
    step,
    inputCallback,
    isSummaryView,
    isNested,
}) => {
    const { t } = useTranslation()
    const { evidence } = useEvidenceContext()

    const { data: evidenceData } = useEvidence(evidence?.evidenceId)

    const containerTempRange = useMemo(() => {
        return evidenceData?.container?.tempRange
    }, [evidenceData])

    const [
        manualInput,
        setManualInput,
    ] = useState(false)

    const [
        scanResult,
        setScanResult,
    ] = useState('')

    const [
        canRetry,
        setCanRetry,
    ] = useState(step.isAnswered && !isSummaryView)

    const title = useMemo(() => {
        if (scanResult) {
            return 'QR_CODE_SCANNED'
        }

        return manualInput ? 'PLEASE_ENTER_IATA_CODE' : 'SCAN_IATA_STICKER'
    }, [
        scanResult,
        manualInput,
    ])

    const onRetry = useCallback(() => {
        inputCallback(createInputObject(null, !step.isRequired), step.stepName)

        setCanRetry(false)
    }, [
        inputCallback,
        step.stepName,
        step.isRequired,
    ])

    const isAnswered = useMemo(() => {
        return step?.isAnswered || Boolean(scanResult)
    }, [
        step.isAnswered,
        scanResult,
    ])

    return (
        <StepPageLayout
            title={t(title)}
            isRequired={step.isRequired}
            isAnswered={isAnswered}
            isNested={isNested}
            isSummaryView={isSummaryView}
        >
            {
                (isSummaryView || canRetry) && (
                    <IATAScanSummary
                        value={step?.userInput?.tempRange}
                        isAnswered={step?.isAnswered}
                        onRetry={canRetry ? onRetry : undefined}
                    />
                )
            }
            {
                !isSummaryView && !canRetry && (
                    <IATAScanInput
                        manualInput={manualInput}
                        scanResult={scanResult}
                        setManualInput={setManualInput}
                        setScanResult={setScanResult}
                        containerTempRange={containerTempRange}
                        inputCallback={inputCallback}
                        stepName={step?.stepName}
                    />
                )
            }
        </StepPageLayout>
    )
}

IATAScan.propTypes = propTypes
IATAScan.defaultProps = defaultProps

export default IATAScan
