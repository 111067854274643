import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return { container: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridGap: 28,
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr 1fr',
            padding: `${theme.spacing(4)}px ${theme.spacing(0)}px`,
        },
        [theme.breakpoints.down('sm')]: { gridTemplateColumns: '1fr' },
    } }
})

export default useStyles
