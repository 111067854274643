import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import QrCodeRetry from '../QrCodeRetry'
import useStyles from './QRScanSummary.style'

const propTypes = {
    loggerNumber: PropTypes.string,
    qrCode: PropTypes.string,
    isAnswered: PropTypes.bool,
}

const defaultProps = {
    isAnswered: false,
    qrCode: undefined,
    loggerNumber: undefined,
}

const QRScanSummary = ({
    isAnswered,
    qrCode,
    loggerNumber,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()

    return (
        <>
            { !isAnswered && (
                <div className={classes.root}>
                    {t('NO_SCAN_PERFORMED') }
                </div>
            )}

            { isAnswered && (
                <QrCodeRetry
                    qrCode={qrCode}
                    loggerNumber={loggerNumber}
                />
            ) }

        </>
    )
}

QRScanSummary.propTypes = propTypes
QRScanSummary.defaultProps = defaultProps

export default QRScanSummary
