import {
    useEffect,
    useCallback,
} from 'react'

const useBeforeUnload = (cleanUpHanlder) => {
    const onUnload = useCallback((event) => {
        cleanUpHanlder(event)
    }, [cleanUpHanlder])

    useEffect(() => {
        window.addEventListener('beforeunload', onUnload, { capture: true })
        return () => {
            window.removeEventListener('beforeunload', onUnload, { capture: true })
        }
    }, [onUnload])
}

export default useBeforeUnload
