import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import common from 'shared/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        button: {
            ...theme.custom.button,
            padding: 0,
        },
        root: {
            ...common.fullContainer,
            display: 'flex',
            flexDirection: 'column',
        },
    }
})

export default useStyles
