import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import ScanOrManualButtons from 'shared/Scans'
import ManualInput from 'shared/ManualInput'
import useScannerModeSwitch from 'shared/hooks/useScannerModeSwitch'
import QRScanner from 'shared/QRScanner'

import CartaScanResult from '../CartaScanResult'

const propTypes = {
    scanResult: PropTypes.string,
    onScanResult: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    manualInput: PropTypes.bool.isRequired,
    setManualInput: PropTypes.func.isRequired,
}

const defaultProps = { scanResult: '' }

const CartaScanInput = ({
    scanResult,
    onScanResult,
    onConfirm,
    manualInput,
    setManualInput,
}) => {
    const { t } = useTranslation()

    const {
        scanMode,
        startScan,
        cancelScan,
    } = useScannerModeSwitch()

    const startManual = useCallback(() => {
        setManualInput(true)
    }, [setManualInput])

    const onManualCancel = useCallback(() => {
        setManualInput(false)
    }, [setManualInput])

    const onScan = useCallback((value) => {
        onScanResult(value)
        cancelScan(false)
    }, [
        onScanResult,
        cancelScan,
    ])

    const onManualSubmit = useCallback((value) => {
        onScan(value)
        onManualCancel(false)
    }, [
        onScan,
        onManualCancel,
    ])

    const onRetry = useCallback(() => {
        onScan('')
    }, [onScan])

    return (
        <>

            {
                manualInput && (
                    <ManualInput
                        onSubmit={onManualSubmit}
                        onCancel={onManualCancel}
                        showCancel
                        showSubmit
                        validationRegEx="\b\d{6}\b|\b\d{10}\b"
                        validationErrorMessage={t('CARTASENSE_BARCODE_MANUAL_VALIDATION_MESSAGE')}
                    />
                )
            }
            {
                !scanResult && !manualInput && (
                    <ScanOrManualButtons
                        onScan={startScan}
                        onManual={startManual}
                    />
                )
            }

            {
                scanResult && (
                    <CartaScanResult
                        scanResult={scanResult}
                        onConfirm={onConfirm}
                        onRetry={onRetry}
                    />
                )
            }
            {
                scanMode && (
                    <QRScanner
                        scanMode
                        onScan={onScan}
                        onCancel={cancelScan}
                        isBarCodeScan
                    />
                )
            }
        </>
    )
}

CartaScanInput.propTypes = propTypes
CartaScanInput.defaultProps = defaultProps

export default CartaScanInput
