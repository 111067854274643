import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { FormControlLabel } from '@mui/material'
import Icon from '@mui/icons-material/Check'
import { useTranslation } from 'react-i18next'

import EmptyIcon from './EmptyIcon'

import useStyles from './SummaryChoicesList.style'

const propTypes = {
    choiceLabel: PropTypes.string.isRequired,
    choiceIdentifier: PropTypes.string.isRequired,
    isAnswered: PropTypes.bool,
    choice: PropTypes.string,
}

const defaultProps = {
    choice: undefined,
    isAnswered: false,
}

const SummaryChoicesItem = ({
    choice,
    choiceLabel,
    choiceIdentifier,
    isAnswered,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <FormControlLabel
            className={classes.formControlStyle}
            classes={{ label: clsx({
                [classes.itemLabel]: true,
                [classes.notSelectedText]: isAnswered && choiceIdentifier !== choice,
            }) }}
            label={t(`processDefinition:${choiceLabel}`)}
            control={
                choice === choiceIdentifier
                    ? (
                        <Icon
                            className={classes.icon}
                        />
                    ) : (<EmptyIcon />)
            }
        />

    )
}

SummaryChoicesItem.propTypes = propTypes
SummaryChoicesItem.defaultProps = defaultProps

export default SummaryChoicesItem
