export const actions = {
    START_SCAN: 'START_SCAN',
    CANCEL_SCAN: 'CANCEL_SCAN',
    SCAN_FAILED: 'SCAN_FAILED',
    SCAN_SECCESSFUL: 'SCAN_SECCESSFUL',
    ERROR_CLOSED: 'ERROR_CLOSED',
}

const reducer = (state, action) => {
    switch (action.type) {
    case actions.START_SCAN:
        return {
            ...state,
            item: action.item,
            scanDialogOpen: true,
        }
    case actions.CANCEL_SCAN:
        return {
            ...state,
            item: null,
            scanDialogOpen: false,
        }
    case actions.SCAN_FAILED:
        return {
            ...state,
            scanDialogOpen: false,
            errorDialogOpen: true,
        }
    case actions.SCAN_SECCESSFUL:
        return {
            ...state,
            scanDialogOpen: false,
            errorDialogOpen: false,
        }
    case actions.ERROR_CLOSED:
        return {
            ...state,
            item: null,
            errorDialogOpen: false,
        }
    default:
        throw new Error(`Not handled action type for ProcessDashBoard component = ${action.type}`)
    }
}

export default reducer
