/* eslint-disable complexity */
export const ACTIONS = {
    START_SCAN: 'START_SCAN',
    START_MANUAL: 'START_MANUAL',
    SCANED: 'SCANED',
    RETRY: 'RETRY',
    CONFIRM: 'CONFIRM',
    SET_LOGGER_NUMBER: 'SET_LOGGER_NUMBER',
    WRONG_QR_CODE: 'WRONG_QR_CODE',
}

export const VIEWTYPES = {
    INIT: 'INIT',
    MANUAL: 'MANUAL',
    SCAN: 'SCAN',
    SAVY_INIT: 'SAVY_INIT',
    CONFIRM: 'CONFIRM',
    CONFIRMED: 'CONFIRMED',
    WRONG_QR: 'WRONG_QR',
}

export const STEP_MODE_TITLES = {
    INIT: 'SCAN_SAVY_QR_CODE',
    MANUAL: 'PLEASE_ENTER_SAVY_QR_CODE',
    CONFIRM: 'QR_CODE_SCANNED',
    SAVY_INIT: 'HOLD_NFC_TAG_AGAINST_LOGGER',
    SCAN: '',
    WRONG_QR: 'PLEASE_ENTER_SAVY_QR_CODE',
}

export const initState = {
    mode: VIEWTYPES.INIT,
    qrCode: null,
    loggerNumber: null,
}

const reducer = (state, action) => {
    switch (action.type) {
    case ACTIONS.START_SCAN:
        return {
            ...state,
            mode: VIEWTYPES.SCAN,
        }
    case ACTIONS.START_MANUAL:
        return {
            ...state,
            mode: VIEWTYPES.MANUAL,
        }
    case ACTIONS.SCANED:
        return {
            ...state,
            qrCode: action.qrCode,
        }
    case ACTIONS.SET_LOGGER_NUMBER:
        return {
            ...state,
            mode: VIEWTYPES.SAVY_INIT,
            loggerNumber: action.loggerNumber,
        }
    case ACTIONS.RETRY:
        return {
            ...state,
            ...initState,
        }
    case ACTIONS.CONFIRM:
        return {
            ...state,
            mode: VIEWTYPES.CONFIRM,
        }
    case ACTIONS.WRONG_QR_CODE:
        return {
            ...state,
            mode: VIEWTYPES.WRONG_QR,
        }
    default:
        throw new Error(`Not handled action type for DirectScan component = ${action.type}`)
    }
}

export default reducer
