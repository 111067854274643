import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import common from 'shared/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        form: {
            ...theme.custom.stepElementPadding,
            paddingRight: 0,
        },
        buttons: common.flexJustityAlignCenter,
        button: {
            ...theme.custom.button,
            margin: '0 50px',
            width: 'auto',
            padding: '0 50px',
        },
        inactive: { backgroundColor: theme.palette.primary[50] },
        summaryView: {
            display: 'flex',
            flexDirection: 'column',
        },
    }
})

export default useStyles
