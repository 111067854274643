import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        form: {
            ...theme.custom.stepElementPadding,
            paddingRight: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',

        },
        checkbox: { paddingLeft: 5 },
        checkboxLabel: {
            fontSize: theme.typography.h3.fontSize,
            lineHeight: theme.typography.h3.lineHeight,
        },
    }
})

export default useStyles
