import {
    useMutation,
    useQueryClient,
} from 'react-query'
import { useJWTToken } from 'App/Auth'

import QUERY_KEYS from 'shared/utils/queryKeys'
import { cancelProcess } from '../services/process'

function useCancelProcess() {
    const token = useJWTToken()
    const queryClient = useQueryClient()

    return useMutation(({ processId }) => {
        return cancelProcess(token, processId)
    }, { onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.Asset)
    } })
}

export default useCancelProcess
