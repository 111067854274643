import React from 'react'
import PropTypes from 'prop-types'
import { ErrorBoundary } from 'react-error-boundary'
import StyledErrorFallback from './StyledErrorFallback'

const propTypes = { children: PropTypes.element.isRequired }

const StyledErrorBoundary = ({ children }) => {
    return (
        <ErrorBoundary
            FallbackComponent={StyledErrorFallback}
        >
            {children}
        </ErrorBoundary>
    )
}

StyledErrorBoundary.propTypes = propTypes

export default StyledErrorBoundary
