import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'

import PictureLayout from 'shared/PictureLayout/PictureLayout'
import useStyles from './PictureWithComment.style'

const propTypes = {
    image: PropTypes.string,
    comment: PropTypes.string,
    number: PropTypes.number.isRequired,
}

const defaultProps = {
    comment: undefined,
    image: undefined,
}

const PictureWithComment = ({
    image,
    comment,
    number,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()

    return (
        <PictureLayout
            title={t('PICTURES')}
            image={image}
            classes={{ actions: classes.actions }}
            noStepUI
        >
            <div className={classes.main}>
                <div>
                    <Typography
                        variant="h3"
                        className={classes.title}
                    >
                        {`${t('PICTURE')} ${number}`}
                    </Typography>
                </div>
                <div>
                    <Typography
                        variant="h3"
                        className={classes.description}
                        style={{ whiteSpace: 'pre-wrap' }}
                    >
                        {comment}
                    </Typography>
                </div>
            </div>
        </PictureLayout>
    )
}

PictureWithComment.propTypes = propTypes
PictureWithComment.defaultProps = defaultProps

export default PictureWithComment
