export const TIME_IN_MS = {
    second: 1000,
    minute: 60 * 1000,
    hour: 60 * 60 * 1000,
    day: 24 * 60 * 60 * 1000,
    week: 7 * 24 * 60 * 60 * 1000,
}

export const INITIAL_USER_INFO = {
    assignedRoles: [],
    id: null,
    firstName: '',
    lastName: '',
    username: '',
    email: '',
}

export const INITIAL_EXTENDED_PROFILE = {
    assignedRoles: [],
    attributes: { companyId: [] },
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    emailVerified: null,
}
