import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return { dialogRoot: theme.custom.boxShadowRadius }
})

export default useStyles
