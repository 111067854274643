import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import EvidenceContextProvider from './EvidenceContextProvider'
import AttachmentContextProvider from './AttachmentContextProvider'
import ProcessPage from './ProcessPage'
import ProcessErrorBoundary from './ProcessErrorBoundary'

const propTypes = { directScan: PropTypes.bool }

const defaultProps = { directScan: false }

const ProcessEvidencePage = ({ directScan }) => {
    const {
        number: assetNumber,
        type: processKey,
    } = useParams()

    return (
        <EvidenceContextProvider>
            <AttachmentContextProvider>
                <ProcessErrorBoundary>
                    <ProcessPage
                        assetNumber={assetNumber}
                        processKey={processKey}
                        directScan={directScan}
                    />
                </ProcessErrorBoundary>
            </AttachmentContextProvider>
        </EvidenceContextProvider>
    )
}

ProcessEvidencePage.propTypes = propTypes
ProcessEvidencePage.defaultProps = defaultProps

export default ProcessEvidencePage
