import React, {
    useCallback,
    useState,
} from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import StepPageLayout from 'shared/StepPageLayout'
import EmptyStepContent from 'shared/EmptyStepContent'
import useStyles from './PictureLayout.style'

const propTypes = {
    image: PropTypes.string,
    children: PropTypes.element,
    title: PropTypes.string.isRequired,
    noStepUI: PropTypes.bool,
    isRequired: PropTypes.bool,
    isAnswered: PropTypes.bool,
    isNested: PropTypes.bool,
    isSummaryView: PropTypes.bool,
}

const defaultProps = {
    image: undefined,
    children: null,
    noStepUI: false,
    isRequired: false,
    isAnswered: false,
    isNested: false,
    isSummaryView: false,
}

const PictureLayout = ({
    image,
    title,
    children,
    noStepUI,
    isRequired,
    isAnswered,
    isNested,
    isSummaryView,
    ...rest
}) => {
    const [
        isLanscapePicture,
        setIsLanscapePicture,
    ] = useState(true)
    const classes = useStyles(rest)
    const { t } = useTranslation()
    const imageLoaded = useCallback((args) => {
        if (args.target.naturalHeight > args.target.naturalWidth) {
            setIsLanscapePicture(false)
        }
    }, [])

    const imageLayout = (
        <div className={classes.form}>
            <div className={classes.pictureContainer}>
                <div className={classes.pictureContainerLayout}>
                    { image && (
                        <img
                            className={isLanscapePicture ? classes.picture : classes.pictureVr}
                            src={image}
                            alt="test"
                            onLoad={imageLoaded}
                        />
                    )}
                    { !image && (<EmptyStepContent title={t('NO_PICTURE')} />)}
                </div>
            </div>
            <div className={classes.actions}>
                { children}
            </div>
        </div>
    )

    if (noStepUI) {
        return imageLayout
    }

    return (
        <StepPageLayout
            title={title}
            isRequired={isRequired}
            isAnswered={isAnswered}
            isNested={isNested}
            isSummaryView={isSummaryView}
        >
            {imageLayout}
        </StepPageLayout>
    )
}

PictureLayout.propTypes = propTypes
PictureLayout.defaultProps = defaultProps

export default PictureLayout
