import React, {
    useCallback,
    useMemo,
    useState,
} from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
    Typography,
    Button,
} from '@mui/material'
import { Loading } from '@skycell-ag/shared-components'
import { useQueryClient } from 'react-query'

import TempContainerIcon from 'shared/TempContainerIcon'
import ModalDialog from 'shared/ModalDialog'
import QUERY_KEYS from 'shared/utils/queryKeys'
import errorKeys from 'shared/utils/errorBEMessageKeys'
import dateToStrTimezone from 'shared/utils/dateToStrTimezone'

import useConfirmShipment from '../hooks/useConfirmShipment'
import useStyles from './Shipment.style'

const ORDER_STATUSSES = [
    'READY_TO_SHIP',
    'BOOKED',
    'RESERVED',
    'TO_BE_PROVISIONED',
]

const CONTAINER_STATUSES = [
    'BOOKEDTOORDER',
    'TOBEPROVISIONED',
    'READYTOSHIP',
    'RELEASEDFROMORDER',
]

const propTypes = {
    orderNumber: PropTypes.string.isRequired,
    orderStatus: PropTypes.oneOf(ORDER_STATUSSES).isRequired,
    plannedPickup: PropTypes.string.isRequired,
    containers: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        serialNumber: PropTypes.string.isRequired,
        tempRange: PropTypes.string.isRequired,
        productType: PropTypes.string,
        containerStatus: PropTypes.oneOf(CONTAINER_STATUSES).isRequired,
    })).isRequired,
}

const Shipment = ({
    orderNumber,
    orderStatus,
    plannedPickup,
    containers,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const queryClient = useQueryClient()

    const onShippedComplite = useCallback(() => {
        queryClient.invalidateQueries(QUERY_KEYS.Shipments)
    }, [queryClient])
    const confirmShipment = useConfirmShipment()
    const [
        saving,
        setSaving,
    ] = useState(false)
    const [
        shippedMessageOpen,
        setShippedMessageOpen,
    ] = useState(false)
    const [
        error,
        setError,
    ] = useState(null)

    const onConfirm = useCallback(() => {
        setSaving(true)
        confirmShipment.mutate({ orderNumber },
            {
                onSuccess: () => {
                    setShippedMessageOpen(true)
                },
                onError: (err) => {
                    if (err.response?.status === 400
                        && err.response?.data[0].message === errorKeys.SHIPMENT_ALREADY_CONFIRMED) {
                        setShippedMessageOpen(true)
                    } else {
                        setError(err)
                    }
                },
            })
    }, [
        confirmShipment,
        orderNumber,
    ])

    const [
        messageText,
        icon,
    ] = useMemo(() => {
        if (confirmShipment.error) {
            return [
                t('SHIPMENT_CONFIRMED_FAILURE', { number: `${orderNumber}` }),
                'icons/warning.svg',
            ]
        }
        return [
            t('SHIPMENT_CONFIRMED_SUCCESSFULLY', { number: `${orderNumber}` }),
            'icons/checked_green.svg',
        ]
    }, [
        t,
        orderNumber,
        confirmShipment.error,
    ])

    const onMessageOk = useCallback(() => {
        onShippedComplite()
        setShippedMessageOpen(false)
        setSaving(false)
    }, [onShippedComplite])

    const canConfirm = useMemo(() => {
        return orderStatus === 'READY_TO_SHIP'
    }, [orderStatus])

    if (error) {
        throw error
    }

    return (
        <div className={classes.root}>
            <div className={classes.main}>
                { saving && (<Loading />)}
                <div className={classes.orderDiv}>
                    <div className={classes.orderNumberDateDiv}>
                        <div className={classes.orderNumber}>
                            <Typography variant="h3">
                                {orderNumber}
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="h5">
                                {dateToStrTimezone(plannedPickup)}
                            </Typography>

                        </div>
                    </div>
                    <div>
                        <Typography variant="h5">
                            {t(orderStatus)}
                        </Typography>
                    </div>
                </div>
                <div className={classes.containers}>
                    <div className={classes.containerDiv}>
                        { containers && (
                            containers.map((item) => {
                                const modelName = item.productType ? ` | ${item.productType}` : ''

                                return (
                                    <div
                                        key={item.id}
                                        className={classes.container}
                                    >
                                        <div className={classes.containerFirtsRow}>
                                            <div className={classes.numberTypeContainer}>
                                                <Typography
                                                    variant="h3"
                                                >
                                                    {`${item.serialNumber}${modelName}`}
                                                </Typography>
                                            </div>
                                            <TempContainerIcon tempRange={item.tempRange} />
                                        </div>
                                        <div className={classes.containerSecondRow}>
                                            <Typography
                                                variant="h5"
                                            >
                                                {t(item.containerStatus)}
                                            </Typography>
                                        </div>
                                    </div>
                                )
                            })
                        )}
                    </div>
                    <div className={classes.confirmDiv}>
                        { canConfirm && (
                            <Button
                                data-testid="confirm-button"
                                className={classes.button}
                                onClick={onConfirm}
                                disabled={saving}
                            >
                                {t('CONFIRM SHIPMENT')}
                            </Button>
                        )}
                    </div>
                </div>
                <div>
                    <ModalDialog
                        open={shippedMessageOpen}
                        message={messageText}
                        onOk={onMessageOk}
                        icon={icon}
                    />
                </div>
            </div>
        </div>
    )
}

Shipment.propTypes = propTypes

export default Shipment
