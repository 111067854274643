import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Dialog from 'shared/Dialog'
import ManualInput from 'shared/ManualInput'
import useStyles from './ManualAssetNumber.style'

const propTypes = { onChange: PropTypes.func.isRequired }

const ManualAssetNumber = ({ onChange }) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const titleMessage = t('PLEASE_ENTER_CODE_OF_CONTAINER_IN_FRONT_OF_YOU')

    return (
        <Dialog
            titleMessage={titleMessage}
            classes={{ dialogContentRoot: classes.dialogContentRoot }}
        >
            <ManualInput
                classes={{
                    contextContainer: classes.contextContainer,
                    button: classes.contextButton,
                }}
                onChange={onChange}
            />
        </Dialog>
    )
}

ManualAssetNumber.propTypes = propTypes

export default ManualAssetNumber
