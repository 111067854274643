/* eslint-disable no-void */
import React, {
    useEffect,
    useMemo,
    useState,
} from 'react'
import PropTypes from 'prop-types'
import Keycloak from 'keycloak-js'

import AuthContext from './AuthContext'
import {
    INITIAL_USER_INFO,
    TIME_IN_MS,
    INITIAL_EXTENDED_PROFILE,
} from './const'
import getUserInfo from './getUserInfo'
import getRoles from '../utils/getRoles'

const {
    REACT_APP_REALM: realm,
    REACT_APP_RESOURCE: clientId,
    REACT_APP_AUTH_SERVER_URL: authUrl,
} = process.env

const propTypes = { children: PropTypes.element.isRequired }

function AuthProvider({ children }) {
    // TODO: refactor to useReducer when moving to common package
    const [
        token,
        setToken,
    ] = useState(undefined)
    const [
        timeOutId,
        setTimeOutId,
    ] = useState(null)
    const [
        keycloak,
        setKeycloak,
    ] = useState(null)
    const [
        status,
        setStatus,
    ] = useState('INIT')
    const [
        extendedProfile,
        setExtendedProfile,
    ] = useState(INITIAL_EXTENDED_PROFILE)

    useEffect(() => {
        const initKeycloak = async () => {
            try {
                setStatus('PENDING')

                const lKeycloack = new Keycloak({
                    url: authUrl ?? '',
                    realm: realm ?? '',
                    clientId: clientId ?? '',
                })

                if (lKeycloack) {
                    await lKeycloack.init({ onLoad: 'login-required' })

                    setKeycloak(lKeycloack)
                    const { token: lToken } = lKeycloack

                    setToken(lToken)
                    setStatus('SUCCESS')
                } else {
                    setStatus('FAILURE')
                }
            } catch (error) {
                global.console.log('error on create Keycloak', error)
                setStatus('FAILURE')
            }
        }

        void initKeycloak()
    }, [])

    useEffect(() => {
        if (keycloak) {
            const { tokenParsed } = keycloak
            const { exp } = tokenParsed
            const timeout = exp * TIME_IN_MS.second - Date.now()

            if (timeOutId) {
                clearTimeout(timeOutId)
            }

            const newTimeOutId = setTimeout(() => {
                keycloak
                    .updateToken(TIME_IN_MS.minute * 5)
                    .then((refreshed) => {
                        if (refreshed) {
                            const { token: lToken } = keycloak

                            setToken(lToken)
                        }
                    })
                    .catch((err) => {
                        global.console.log('error on update token', err)
                        keycloak.logout()
                    })
            }, timeout)

            setTimeOutId(newTimeOutId)
        }
        // Warning!!! timeOutId - don't put into deps - infinite refresh
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        keycloak,
        token,
    ])

    useEffect(() => {
        if (keycloak && !extendedProfile.email) {
            const loadProfile = async () => {
                try {
                    const profile = await keycloak.loadUserProfile()
                    const { tokenParsed } = keycloak
                    const { groups: assignedRoles = [] } = tokenParsed

                    // Need to handle reading skycore roles differently
                    // because Auth inside I18nLocaleProvider
                    if (token) {
                        const skyCoreRoles = await getRoles(token)

                        setExtendedProfile({
                            ...profile,
                            assignedRoles,
                            roles: skyCoreRoles,
                        })
                    }
                } catch (error) {
                    global.console.log('error loading user profile', error)
                    setExtendedProfile(INITIAL_EXTENDED_PROFILE)
                }
            }

            void loadProfile()
        }
    }, [
        extendedProfile.email,
        keycloak,
        token,
    ])

    const userInfo = useMemo(() => {
        if (!extendedProfile.email) {
            return INITIAL_USER_INFO
        }

        return getUserInfo(extendedProfile)
    }, [extendedProfile])

    // eslint-disable-next-line no-nested-ternary
    return status === 'SUCCESS' ? (
        <AuthContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                userInfo,
                token,
                logout: keycloak
                    ? keycloak.logout
                    : () => {
                        return null
                    },
            }}
        >
            {children}
        </AuthContext.Provider>
    ) : status === 'FAILURE' ? (
        <span>NO ACCESS</span>
    ) : null
}

AuthProvider.propTypes = propTypes

export default AuthProvider
