import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { Typography } from '@mui/material'
import { useAuthContext } from 'App/Auth'

import ROUTE_KEYS from 'shared/utils/routeKeys'
import getGlobalAssetUrl from 'shared/utils/getGlobalAssetUrl'
import Breadcrumbs from './Breadcrumbs'
import useStyles from './Header.style'

const propTypes = { onClickMainMenu: PropTypes.func }

const defaultProps = { onClickMainMenu: undefined }

const Header = ({ onClickMainMenu }) => {
    const classes = useStyles()
    const history = useHistory()
    const { logout } = useAuthContext()

    const handleLogoutClick = useCallback(() => {
        logout()
    }, [logout])

    const navigateToMainDashboard = useCallback(() => {
        if (onClickMainMenu) { onClickMainMenu() }
        history.push(ROUTE_KEYS.MAIN)
    }, [
        history,
        onClickMainMenu,
    ])

    return (
        <div className={classes.wrapper}>
            <button
                type="button"
                className={classes.logout}
                onClick={handleLogoutClick}
            >
                <img
                    src={getGlobalAssetUrl('icons/logout.svg')}
                    alt="logout"
                />
            </button>
            <div className={clsx(classes.breadCrumbs, classes.titleHeader)}>
                <Breadcrumbs />
            </div>
            <div
                className={classes.logoContainer}
                onClick={navigateToMainDashboard}
                aria-hidden="true"
            >
                <Typography
                    variant="h1"
                    className={classes.titleHeader}
                >
                    SkyCenter
                </Typography>
                <div
                    className={classes.logo}
                >
                    <img
                        data-testid="logo"
                        alt="logo"
                        src={getGlobalAssetUrl('skycenter/logo.png')}
                    />
                </div>
            </div>
        </div>
    )
}

Header.propTypes = propTypes
Header.defaultProps = defaultProps

export default Header
