import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        wrapper: {
            height: 70,
            background: theme.palette.primary[400],
            display: 'flex',
            justifyContent: 'space-between',
            boxSizing: 'border-box',
            alignItems: 'center',
        },
        menuContainer: { padding: '18px 26px' },
        breadCrumbs: { flexGrow: 1 },
        breadCrumbsText: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        logoContainer: {
            display: 'flex',
            cursor: 'pointer',
        },
        menu: {
            backgroundImage: 'linear-gradient(0deg, #61c6e9 26.92%, #ffffff 26.92%, #ffffff 50%, #61c6e9 50%, #61c6e9 76.92%, #ffffff 76.92%, #ffffff 100%)',
            backgroundSize: '26px 26px',
            width: 38,
            height: 34,
        },
        titleHeader: {
            padding: '10px 0 12px 0',
            textAlign: 'left',
        },
        logo: {
            padding: '4px 16px 9px 18px',
            height: 57,
            width: 56,
        },
        logout: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: 'none',
            background: 'transparent',
            padding: '18px 26px',
            cursor: 'pointer',
        },
    }
})

export default useStyles
