import React, {
    useState,
    useCallback,
} from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'

import TextStepField from 'shared/TextStepField'
import { createInputObject } from 'shared/utils/stepsUtils'
import useSupportNestedSteps from 'shared/NestedStep/useSupportNestedSteps'

const createUserInput = (value, valid = true) => {
    return createInputObject({ answerText: value }, valid)
}

const propTypes = {
    step: PropTypes.shape({
        stepName: PropTypes.string.isRequired,
        userInput: PropTypes.shape({ answerText: PropTypes.string }),
        validationRegEx: PropTypes.string.isRequired,
        validationErrorMessage: PropTypes.string.isRequired,
        isRequired: PropTypes.bool.isRequired,
    }).isRequired,
    inputCallback: PropTypes.func,
    isNested: PropTypes.bool,
    isSummaryView: PropTypes.bool,
    nestedInputFromUserInput: PropTypes.func,
}

const defaultProps = {
    isNested: false,
    isSummaryView: false,
    inputCallback: undefined,
    nestedInputFromUserInput: undefined,
}

const inputConverter = (userInput) => {
    if (userInput?.answerText) {
        return createUserInput(userInput.answerText)
    }
    return undefined
}

const TextValidatedStep = ({
    step,
    inputCallback,
    isNested,
    isSummaryView,
    nestedInputFromUserInput,
}) => {
    const { t } = useTranslation()
    const initValue = step.userInput ? step.userInput.answerText : ''
    const [
        value,
        setValue,
    ] = useState(initValue)
    const [
        error,
        setError,
    ] = useState(false)

    const validate = useCallback((v) => {
        const inputRegexp = new RegExp(step.validationRegEx)

        if (v.match(inputRegexp)) {
            return true
        }
        return false
    }, [step.validationRegEx])

    const inputCallBackWrapper = useCallback((input) => {
        if (inputCallback) {
            inputCallback(input, step.stepName)
        }
    }, [
        inputCallback,
        step.stepName,
    ])

    const onChange = useCallback((event) => {
        const newInputValue = event.target.value

        if (!step.isRequired && isEmpty(newInputValue)) {
            setError(false)
            inputCallBackWrapper(createInputObject(null))
        } else if (!validate(newInputValue)) {
            setError(true)
            inputCallBackWrapper(createUserInput(newInputValue, false))
        } else {
            setError(false)
            inputCallBackWrapper(createUserInput(newInputValue))
        }
        setValue(newInputValue)
    }, [
        validate,
        step.isRequired,
        inputCallBackWrapper,
    ])

    useSupportNestedSteps({
        step,
        isSummaryView,
        isNested,
        nestedInputFromUserInput,
        inputConverter,
    })

    const errorText = error ? (t(step.validationErrorMessage)) : null

    return (
        <TextStepField
            step={step}
            value={value}
            onChange={onChange}
            error={error}
            helpText={errorText}
            isNested={isNested}
            isSummaryView={isSummaryView}
        />
    )
}

TextValidatedStep.propTypes = propTypes
TextValidatedStep.defaultProps = defaultProps

export default TextValidatedStep
