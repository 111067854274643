import React, { useState } from 'react'
import PropTypes from 'prop-types'
import EvidenceContext from './EvidenceContext'

const propTypes = { children: PropTypes.element.isRequired }

const EvidenceContextProvider = ({ children }) => {
    const [
        currentEvidence,
        setCurrentEvidence,
    ] = useState(
        { evidenceId: null },
    )

    return (
        <EvidenceContext.Provider
            value={{
                evidence: currentEvidence,
                saveEvidence: setCurrentEvidence,
            }}
        >
            {children}
        </EvidenceContext.Provider>
    )
}

EvidenceContextProvider.propTypes = propTypes

export default EvidenceContextProvider
