/* eslint-disable complexity */

import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react'
import { useTranslation } from 'react-i18next'

import { createInputObject } from 'shared/utils/stepsUtils'
import TwoColumnErrorPage from 'shared/ErrorPages/TwoColumnErrorPage'
import ScanOrManualButtons from 'shared/Scans'
import ManualInput from 'shared/ManualInput'
import useGetLoggerByNumber from 'shared/hooks/useGetLogger/useGetLoggerByNumber'
import QrCodeRetry from 'shared/QrCodeRetry'

const useRenderStepContext = ({
    step,
    state,
    ViewTypes,
    Actions,
    inputCallback,
    dispatch,
}) => {
    const { t } = useTranslation()
    const { data: qrCodeData } = useGetLoggerByNumber(state.loggerNumber, step.isAnswered)
    const setUserInput = useCallback(() => {
        const input = createInputObject({ loggerNumber: state.loggerNumber }, true)

        inputCallback(input, step.stepName)
    }, [
        inputCallback,
        step.stepName,
        state.loggerNumber,
    ])

    const onRetry = useCallback(() => {
        inputCallback(createInputObject(null, true), step.stepName)

        dispatch({ type: Actions.RETRY })
    }, [
        inputCallback,
        step.stepName,
        dispatch,
        Actions,
    ])

    const onManual = useCallback(() => {
        inputCallback(createInputObject(null, false), step.stepName)
        dispatch({ type: Actions.START_MANUAL })
    }, [
        inputCallback,
        step.stepName,
        dispatch,
        Actions,
    ])

    const onStartScan = useCallback(() => {
        dispatch({ type: Actions.START_SCAN })
    }, [
        Actions,
        dispatch,
    ])

    const qrCodeSaved = useMemo(() => {
        const str = state.qrCode || qrCodeData?.loggerQrCode

        return `${str}`
    }, [
        qrCodeData?.loggerQrCode,
        state.qrCode,
    ])

    const handleSubmitManualEntering = useCallback((result) => {
        dispatch({
            type: Actions.SCANED,
            qrCode: result,
        })
    }, [
        Actions,
        dispatch,
    ])

    useEffect(() => {
        if (state.mode === ViewTypes.CONFIRMED && state.loggerNumber) {
            setUserInput()
        }
    }, [
        ViewTypes,
        setUserInput,
        state.loggerNumber,
        state.mode,
    ])

    const stepContextRender = useMemo(() => {
        if (state.mode === ViewTypes.MANUAL) {
            const validation = step.stepType === 'LOGGER_INSTALLATION' || step.stepType === 'LOGGER_REMOVAL'
                ? {}
                : {
                    validationRegEx: '\\b\\w{6}\\b',
                    validationErrorMessage: t('TIVE_BARCODE_MANUAL_VALIDATION_MESSAGE'),
                }

            return (
                <ManualInput
                    onSubmit={handleSubmitManualEntering}
                    onCancel={onRetry}
                    showCancel
                    showSubmit
                    {...validation} // eslint-disable-line react/jsx-props-no-spreading
                />
            )
        }
        if ((state.mode === ViewTypes.CONFIRMED || state.mode === ViewTypes.CONFIRMED_AND_SAVED)
        && state.loggerNumber) {
            return (
                <QrCodeRetry
                    qrCode={qrCodeSaved}
                    loggerNumber={state.loggerNumber}
                    onRetry={onRetry}
                />
            )
        }

        if (state.mode === ViewTypes.WRONG_QR || state.mode === ViewTypes.WRONG_LOGGER) {
            const message = state.mode === ViewTypes.WRONG_QR
                ? {
                    top: 'NO_SYSTEM',
                    middle: [{
                        label: `${t('CODE_SCANNED')}: `,
                        value: state.qrCode,
                    }],
                }
                : {
                    top: 'GENERIC_LOGGER_WRONG_LOGGER_TYPE',
                    middle: [{
                        label: `${t('LOGGER')}: `,
                        value: state.qrCode,
                    }],
                }

            return (
                <TwoColumnErrorPage
                    iconPath="skycenter/error_sorry.svg"
                    iconAlt="error"
                    buttonLabel={t('RETRY')}
                    onButtonClick={onRetry}
                    buttonDataTestId="retry-button"
                    messageTop={t(message.top)}
                    messageBottom={t('CONTACT_SKYCELL')}
                    middleTexts={message.middle}
                />
            )
        }

        return (
            <ScanOrManualButtons
                onScan={onStartScan}
                onManual={onManual}
            />
        )
    }, [
        step.stepType,
        state.mode,
        state.qrCode,
        onStartScan,
        onManual,
        state.loggerNumber,
        onRetry,
        qrCodeSaved,
        handleSubmitManualEntering,
        t,
        ViewTypes,
    ])

    return stepContextRender
}

export default useRenderStepContext
