import IATAScan from './IATAScan'
import CartaScan from './CartaScan'
import AssetNumber from './AssetNumber'
import TextStep from './TextStep'
import TextValidatedStep from './TextValidatedStep'
import SingleChoice from './SingleChoice'
import MultipleChoice from './MultipleChoice'
import YesNoStep from './YesNoStep'
import SinglePicture from './SinglePicture'
import MultiplePicture from './MultiplePicture'
import SavyInitialization from './SavyInitialization'
import ConfirmStep from './ConfirmStep'
import TiveInstallation from './TiveInstallation'
import TiveRemoval from './TiveRemoval'
import LoggerInstallation from './LoggerInstallation'
import LoggerRemoval from './LoggerRemoval'
import MultipleChoiceDamage from './MultipleChoiceDamage'

const stepsMapper = {
    ASSET_NUMBER: AssetNumber,
    TEXT: TextStep,
    TEXT_VALIDATED: TextValidatedStep,
    CHOICE_SINGLE: SingleChoice,
    CHOICE_MULTIPLE: MultipleChoice,
    YES_NO: YesNoStep,
    PICTURE: SinglePicture,
    PICTURE_MULTIPLE: MultiplePicture,
    IATA_QR_CODE_SCAN: IATAScan,
    SAVY_INITIALIZATION: SavyInitialization,
    CARTA_BARCODE_SCAN: CartaScan,
    CONFIRMATION: ConfirmStep,
    TIVE_INSTALLATION: TiveInstallation,
    TIVE_REMOVAL: TiveRemoval,
    LOGGER_INSTALLATION: LoggerInstallation,
    LOGGER_REMOVAL: LoggerRemoval,
    CHOICE_MULTIPLE_DAMAGES: MultipleChoiceDamage,
}

export default stepsMapper
