import { useMemo } from 'react'
import get from 'lodash/get'

import { useLocationContext } from 'App/Root/Location'
import {
    useAsset,
    useAssetProcesses,
} from 'App/Root/hooks'
import useAvailableContainers from 'App/Root/hooks/useProcessAvailableContainers'
import useGetServiceProcessDefinitionById from './useGetServiceProcessDefinitionById'
import useEvidence from './useEvidence'

function useProcessData({
    processKey,
    assetNumber,
    directScan,
}) {
    const { location } = useLocationContext()

    const {
        data: asset,
        isLoading: isAssetLoading,
        error: assetError,
    } = useAsset(assetNumber)

    const {
        locationIdByNewProcessRequest,
        evidenceId,
    } = useMemo(() => {
        let requestForlocationId

        let evId

        if (asset && !asset.id) {
            requestForlocationId = location.locationId
            evId = null
        } else {
            evId = asset?.id
            requestForlocationId = null
        }
        return {
            locationIdByNewProcessRequest: requestForlocationId,
            evidenceId: evId,
        }
    }, [
        asset,
        location.locationId,
    ])

    const {
        isLoading: assetsIsLoading,
        error: assetsError,
        data: assets = [],
    } = useAvailableContainers({
        location: locationIdByNewProcessRequest,
        processType: processKey,
    }, locationIdByNewProcessRequest !== null && !directScan)

    const {
        data: directScanProcessesData = [],
        isLoading: isDirectScanProcessesLoading,
        error: directScanProcessesError,
    } = useAssetProcesses({
        location: locationIdByNewProcessRequest,
        assetNumber,
    }, locationIdByNewProcessRequest !== null && directScan)

    const {
        data: evidenceData,
        isLoading: isEvidenceLoading,
        error: evidenceError,
    } = useEvidence(evidenceId)

    const serviceProcessDefinitionId = useMemo(() => {
        if (directScan && !isDirectScanProcessesLoading && directScanProcessesData.length > 0) {
            return directScanProcessesData?.[0].id
        }
        const currentAsset = assets?.find((a) => {
            return a.serialNumber === assetNumber
        })

        return currentAsset?.serviceProcessDefinitionId
    }, [
        assets,
        assetNumber,
        directScan,
        directScanProcessesData,
        isDirectScanProcessesLoading,
    ])

    const {
        data: allProcessesData,
        isLoading: isAllProcessLoading,
        error: allProcessError,
    } = useGetServiceProcessDefinitionById(serviceProcessDefinitionId, assetNumber)

    const processData = useMemo(() => {
        if (evidenceData) {
            return evidenceData
        }
        return allProcessesData
    }, [
        allProcessesData,
        evidenceData,
    ])

    const [
        processTemplate,
        progressGroups,
        processStatus,
        changedBy,
    ] = useMemo(() => {
        return [
            processData ? {
                process: processData.process,
                processLabel: processData.processLabel,
                processName: processData.processName,
                sourceProcessDefinitionId: processData.id,
            } : undefined,
            get(processData, 'progressGroups', []),
            processData ? processData.processStatus : undefined,
            get(processData, 'changedBy', undefined),
        ]
    }, [processData])

    const error = useMemo(() => {
        return assetError
        || allProcessError
        || evidenceError
        || assetsError
        || directScanProcessesError
    }, [
        assetError,
        allProcessError,
        evidenceError,
        assetsError,
        directScanProcessesError,
    ])

    const isLoading = useMemo(() => {
        return isAssetLoading
        || isAllProcessLoading
        || isEvidenceLoading
        || assetsIsLoading
        || isDirectScanProcessesLoading
    }, [
        isAssetLoading,
        isAllProcessLoading,
        isEvidenceLoading,
        assetsIsLoading,
        isDirectScanProcessesLoading,
    ])

    return {
        data: {
            processTemplate,
            progressGroups,
            processStatus,
            evidenceId,
            changedBy,
        },
        isLoading,
        error,
    }
}

export default useProcessData
