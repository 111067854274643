import React from 'react'
import { PropTypes } from 'prop-types'
import { Loading } from '@skycell-ag/shared-components'

import useDynamicSoftScanner from './PreLoadScanner/useDynamicSoftScanner'
import QRScannerSwitch from './QRScannerSwitch'

const propTypes = {
    onScan: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    isBarCodeScan: PropTypes.bool.isRequired,
    options: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
        aspect: PropTypes.number,
    }),
}

const defaultProps = {
    onCancel: undefined,
    options: undefined,
}

const QRScannerView = ({
    onScan,
    onCancel,
    isBarCodeScan,
    options,
}) => {
    const { loaded } = useDynamicSoftScanner()

    return (
        <>
            { !loaded && (<Loading />)}
            {loaded && (
                <QRScannerSwitch
                    onScan={onScan}
                    onCancel={onCancel}
                    options={options}
                    isBarCodeScan={isBarCodeScan}
                />
            )}
        </>
    )
}

QRScannerView.propTypes = propTypes
QRScannerView.defaultProps = defaultProps

export default QRScannerView
