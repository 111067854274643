import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'

import useStyles from './EmptyStepContent.style'

const propTypes = { title: PropTypes.string.isRequired }

const EmptyStepContent = ({
    title,
    ...rest
}) => {
    const classes = useStyles(rest)

    return (
        <div className={classes.noPicture}>
            <Typography
                variant="h3"
                className={classes.text}
            >
                {title}
            </Typography>
        </div>
    )
}

EmptyStepContent.propTypes = propTypes

export default EmptyStepContent
