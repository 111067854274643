const getTimeZone = (datetime) => {
    const timeZone = datetime.split(' ')
    const timeLength = timeZone.length
    const lengthWithTimezone = 3

    if (timeLength < lengthWithTimezone) {
        return '+0000'
    }
    return timeZone[timeLength - 1]
}

export default getTimeZone
