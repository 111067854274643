const BreadCrumbsStaticNameMap = {
    '/scan': 'SCAN',
    '/processes': 'PROCESSES',
    '/processes_directscan': 'PROCESSES',
    '/orders': 'ORDERS',
}

const processNameTemplatePart = 'processDefinition:PROCESS_NAME_'

const resolveProcessPath = (path) => {
    const result = path.match(/\/processes\/([^/]*)|\/processes_directscan\/([^/]*)/)
    const processName = result[1] || result[2]

    return `${processNameTemplatePart}${processName}`
}

const resolvePathByOrder = {
    0: (value, path) => {
        return BreadCrumbsStaticNameMap[path]
    },
    1: (value, path) => {
        return resolveProcessPath(path)
    },
    2: (value) => {
        return value
    },
}

const resolvePath = (value, index, to) => {
    return resolvePathByOrder[index](value, to)
}

export default resolvePath
