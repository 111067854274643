import { makeStyles } from '@mui/styles'
import common from 'shared/styles'

const useStyles = makeStyles(() => {
    return {
        stack: { whiteSpace: 'pre-line' },
        root: {
            ...common.fullContainer,
            display: 'flex',
            flexDirection: 'column',
        },
        accordion: {
            width: '80%',
            alignSelf: 'center',
            marginBottom: 50,
        },
    }
})

export default useStyles
