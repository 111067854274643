import moment from 'moment'
import {
    dateToStr, dateTimeZonedMask,
} from '@skycell-ag/shared-components'
import getTimeZone from './getTimeZone'

const dateToStrTimezone = (date, zone, mask = dateTimeZonedMask) => {
    if (!date) {
        return null
    }

    const timeZone = zone || getTimeZone(date)

    return dateToStr(moment(date, mask).utc().utcOffset(timeZone))
}

export default dateToStrTimezone
