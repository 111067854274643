import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        root: theme.custom.dashBoardRoot,
        main: { width: '100%' },
        userLocationTitle: { paddingBottom: 30 },
        userLocationText: {
            borderBottom: `1px solid ${theme.palette.primary.main}`,
            color: theme.typography.h3.color,
        },
        dashboard: { gridTemplateColumns: '1fr 1fr' },
    }
})

export default useStyles
