import {
    useState,
    useCallback,
} from 'react'

import { createInputObject } from 'shared/utils/stepsUtils'
import useSupportNestedSteps from './useSupportNestedSteps'

const inputConverter = (userInput) => {
    if (userInput?.selectedChoiceIdentifier) {
        const value = userInput.selectedChoiceIdentifier

        return createInputObject({ selectedChoiceIdentifier: value })
    }
    return undefined
}

function useNestedStep({
    step,
    curentGroupSteps,
    inputCallback,
    isNested,
    isSummaryView,
    nestedInputFromUserInput,
}) {
    const [
        choice,
        setChoice,
    ] = useState(step.userInput?.selectedChoiceIdentifier || '')

    const [
        isChanged,
        setIsChanged,
    ] = useState(false)

    const getCurrentChoiceObj = useCallback((choiceValue) => {
        return step.choices.find((c) => {
            return c.choiceIdentifier === choiceValue
        })
    }, [step.choices])

    const getStepForNestedStep = useCallback((goToStep) => {
        return curentGroupSteps.find((s) => {
            return s.stepName === goToStep
        })
    }, [curentGroupSteps])

    const onChange = useCallback((value) => {
        let input

        const choiceObj = getCurrentChoiceObj(value)

        if (choiceObj.goToStep) {
            const nestedStepDef = getStepForNestedStep(getCurrentChoiceObj(value).goToStep)
            const nestedStepIsRequired = nestedStepDef.isRequired

            input = createInputObject({ selectedChoiceIdentifier: value },
                !nestedStepIsRequired)
        } else {
            input = createInputObject({ selectedChoiceIdentifier: value },
                true,
                choiceObj.jumpToSummary)
        }

        inputCallback(input, step.stepName)
        setChoice(value)
        setIsChanged(true)
    }, [
        inputCallback,
        step.stepName,
        getCurrentChoiceObj,
        getStepForNestedStep,
    ])

    useSupportNestedSteps({
        step,
        isSummaryView,
        isNested,
        nestedInputFromUserInput,
        inputConverter,
    })

    return {
        choice,
        isChanged,
        onChange,
    }
}

export default useNestedStep
