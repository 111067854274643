import React, {
    useEffect,
    useState,
    useMemo,
    useRef,
} from 'react'
import PropTypes from 'prop-types'

import 'dbr'
import { BarcodeScanner } from 'dynamsoft-javascript-barcode'
import BarcodeScannerContext from './BarcodeScannerContext'

const propTypes = {
    children: PropTypes.element.isRequired,
    enhancedSkyCenterScanningAllowed: PropTypes.bool.isRequired,
}

const PreLoadScannerProvider = ({
    children,
    enhancedSkyCenterScanningAllowed,
}) => {
    const pScanner = useRef()
    const [
        loaded,
        setLoaded,
    ] = useState(!enhancedSkyCenterScanningAllowed)

    const [
        useDynamicSoft,
        setUseDynamicSoft,
    ] = useState(false)

    useEffect(() => {
        const prepareLibrary = async () => {
            await BarcodeScanner.loadWasm()
            const scanner = await BarcodeScanner.createInstance()
            const scanSettings = await scanner.getScanSettings()

            scanSettings.captureAndDecodeInParallel = false
            scanSettings.intervalTime = 1000
            scanner.ifSkipCameraInspection = true
            scanner.ifSaveLastUsedCamera = true
            await scanner.updateScanSettings(scanSettings)
            pScanner.current = scanner
            setUseDynamicSoft(true)
            setLoaded(true)
        }

        if (enhancedSkyCenterScanningAllowed) {
            prepareLibrary().catch((error) => {
                // eslint-disable-next-line no-console
                console.log('Error on PreLoadScannerProvider.prepareLibrary', error)
                setLoaded(true)
            })
        }
        return () => {
            if (pScanner.current) {
                const destroScanner = async () => {
                    await pScanner.current.destroyContext()
                }

                destroScanner().catch((error) => {
                    // eslint-disable-next-line no-console
                    console.log('Error on PreLoadScannerProvider Unmount', error)
                })
            }
        }
    }, [enhancedSkyCenterScanningAllowed])

    const contextValue = useMemo(() => {
        return {
            loaded,
            useDynamicSoft,
            pScanner,
        }
    }, [
        loaded,
        useDynamicSoft,
    ])

    return (
        <BarcodeScannerContext.Provider value={contextValue}>
            {children}
        </BarcodeScannerContext.Provider>
    )
}

PreLoadScannerProvider.propTypes = propTypes

export default PreLoadScannerProvider
