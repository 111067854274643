import React from 'react'
import PropTypes from 'prop-types'
import useIsAdmin from 'App/AccessProvider/useIsAdmin'
import CentersDashboard from '../CentersDashboard'
import UserLocation from '../UserLocation'

const propTypes = { children: PropTypes.element.isRequired }

const LocationView = ({ children }) => {
    const { isAdmin } = useIsAdmin()

    if (isAdmin) {
        return (<CentersDashboard>{children}</CentersDashboard>)
    }
    return (<UserLocation>{children}</UserLocation>)
}

LocationView.propTypes = propTypes

export default LocationView
