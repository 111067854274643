import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import common from 'shared/styles'

const useStyles = makeStyles(() => {
    const theme = useTheme()

    return {
        main: {
            ...common.fullContainer,
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: 30,
        },
        actions: {
            alignSelf: 'flex-start',
            flexGrow: 2,
        },
        title: {
            fontWeight: 600,
            paddingBottom: 15,
        },
        description: theme.custom.messageText,
    }
})

export default useStyles
